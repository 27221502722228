import {
    PaginationState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
  } from "@tanstack/react-table";
  import Card from "components/card";
  import DivLoader from "components/divloader/DivLoader";
  import React, { useEffect, useState } from "react";
  import { ClientRowObj } from "..";
  import Searchbox from "components/fields/Searchbox";
  import Pagination from "components/pagination";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import moment from "moment";
import { MdAdd, MdPlusOne, MdRemove } from "react-icons/md";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { Column } from 'react-table';

  
  function CustomerTable(props: {
    tableData: any;
    fetchClients: () => void;
    isLoading: boolean;
    roleData?: any;
    page: number;
    setPage: any;
    totalpage: number;
    totalItems: number;
    currentPage: number;
    pageSize: number;
    setPageSize: any;
    allGateways: any;
    onValueChange: (value: string) => void;
  }) {
    const {
      tableData,
      fetchClients,
      roleData,
      page,
      setPage,
      currentPage,
      totalpage,
      totalItems,
      pageSize,
      setPageSize,
      allGateways,
      onValueChange,
      isLoading
    } = props;
    let defaultData = tableData;
  
    const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
      pageIndex: 0,
      pageSize,
    });

    const [expandedRow, setExpandedRow] = useState<string | null>(null);
  
    const pagination = React.useMemo(() => ({
      pageIndex,
      pageSize,
    }), [pageIndex, pageSize],
    );

    const handleExpand = (rowId : any) => {
      setExpandedRow(expandedRow === rowId ? null : rowId);
    };
  
    const columns = [
      columnHelper.accessor("created_at", {
        id: "created_at",
        header: () => (
            <p className="text-sm font-bold text-gray-900 dark:text-white">Created Date</p>
        ),
        cell: (info : any) => {
            const rawDate = info.getValue();
            const formattedDate = moment(rawDate).format("YYYY-MM-DD HH:mm:ss");
    
            return (
              <p
                className="text-sm font-bold text-navy-700 dark:text-white flex items-center gap-1 cursor-pointer"
                onClick={() =>
                  handleExpand(info?.row?.original?.customer_id)
                }
              >
                
                  {expandedRow === info?.row?.original?.customer_id ? (
                    <AiFillMinusCircle className="w-5 h-5 text-navy-700 dark:text-white" />
                  ) : (
                    <AiFillPlusCircle className="w-5 h-5 text-navy-700 dark:text-white" />
                  )}
              
                {formattedDate}
              </p>
            );
        },  
        size: 200, 
        minSize: 200,
        maxSize: 300, 
      }),
      columnHelper.accessor("customer_id", {
        id: "customer_id",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">CUSTOMER ID</p>
        ),
        cell: (info: any) => {
            return <>
              <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
            </>
          },
      }),
      columnHelper.accessor("first_name", {
        id: "first_name",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">NAME</p>
        ),
        cell: (info: any) => {
            return (
                <>
                    <div className="flex items-center">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {info?.row?.original?.first_name} {info?.row?.original?.last_name}
                        </p>
                    </div>   
                </> 
            )
        },
      }),
      columnHelper.accessor("email", {
        id: "email",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">Email</p>
        ),
        cell: (info) => (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              <a href={`mailto:${info.getValue()}`}> {info.getValue()}</a>
            </p>
        ),
      }),
      columnHelper.accessor("phone", {
        id: "phone",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">PHONE</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            <a href={`tel:${info.getValue()}`}> {info.getValue()}</a>
            {/* {info.getValue()} */}
          </p>
        ),
      }),
      columnHelper.accessor("source_client", {
        id: "source_client",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">SOURCE CLIENT</p>
        ),
        cell: (info: any) => {
            return <>
                <div className="flex items-center">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {info?.getValue()}
                    </p>
                </div>
            </>
        },
      }),
      columnHelper.accessor("customer_id", {
        id: "customer_id",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">Number of Transaction</p>
        ),
        cell: (info: any) => {
            return <>
                <div className="flex items-center">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {info?.row?.original?.total_transactions}
                    </p>
                </div>
            </>
        },
      }),
    ]; 
  
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [data, setData] = React.useState(() => [...defaultData]);
    const [searchVal, setSearchVal] = useState<any>("");
    useEffect(() => {
      setData(tableData)
    }, [tableData]);
  
    const table = useReactTable({
      data,
      columns: columns,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    });
  
    const handleValueChange = (e: any) => {
  
      onValueChange(e);
      setSearchVal(e);
    };
  
    return (
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        {/* <header className="relative flex items-center justify-between pt-5"> */}
        <header className="relative flex items-center justify-between pt-5 flex-wrap">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Customers
          </div>
          {/* <div className=" flex items-start justify-center">
            <select className="mb-3  flex items-center justify-center text-sm font-bold text-gray-900 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
              <option value="organization">Organization</option>
            </select>
          </div> */}
          <Searchbox onSearch={handleValueChange} />
        </header>
  
        <div className="mt-4  overflow-x-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 relative overflow-x-auto shadow-md sm:rounded-lg">
          {props.isLoading ? (
            <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
          ) : (
            <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className=""
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2"
                        >
                          <div className="items-center justify-between text-xs text-gray-200 ">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody >
                {(table.getRowModel().rows?.length > 0) ? table.getRowModel().rows.map((row) => {
                  return (
                    <React.Fragment key={row.id}>
                      <tr key={row.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      {expandedRow === row.original.customer_id && (
                        <tr className="bg-gray-100">
                          <td colSpan={columns.length} className="p-4 border">
                            sdfsdfsdfsdfsdf
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                }) :
  
                  <tr>
                    <td colSpan={6} >
                      <p className="text-center p-4" >No records found.</p>
                    </td>
  
                  </tr>
  
                }
  
  
              </tbody>
            </table>
          )}
        </div>
  
        <Pagination
          setPage={setPage}
          page={page}
          totalpage={totalpage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          arraySize={[50, 100, 200]}
        />
      </Card>
    );
  }
  
  export default CustomerTable;
  const columnHelper = createColumnHelper<ClientRowObj>();
  