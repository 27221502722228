// import authRequest from "utils/authRequest";
// import request from "../utils/request";
// import axios from "axios";
// import service from "../utils/request";
// import { APIPath } from "baserURL";

import authGraphQLRequest from "utils/authGraphQLRequest";

// export interface CreditsInfo {
//   id: string;
//   one_time_credits?: number;
//   reward_credits?: number;
//   recurring_credits?: number;
// }

// export interface UserInfo {
//   id: string;
//   name?: string;
//   email: string;
//   avatar?: string;
//   age?: string;
//   providers?: string;
//   credits?: CreditsInfo;
// }


// export const userLogin = (loginData: {
//   email: string;
//   password: string;
// }): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     service({
//       url: "/api/v1/login",
//       method: "post",
//       data: loginData,
//     })
//       .then(({ data }: any) => {
//         resolve(data);
//       })
//       .catch((err: any) => {
//         reject(err);
//       });
//   });
// };

// export const userSignup = (loginData: {
//   firstname: string;
//   lastname: string;
//   email: string;
//   password: string;
//   role: string;
// }): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     service({
//       url: "/api/v1/user",
//       method: "post",
//       data: loginData,
//     })
//       .then(({ data }: any) => {
//         resolve(data);
//       })
//       .catch((err: any) => {
//         reject(err);
//       });
//   });
// };
 
export const getProfile = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
  user{
    id
    email
    first_name
    last_name
    role_id
    role
    created_at
    updated_at
    last_sign_in_at
    organizations
    auth_2fa

  }
}
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_user'
    )
      .then((responseData) => {
        console.log('promise api call graphql response user by id ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all by id ::', { err });
        reject(err);
      });
  });
};

// export const get2FAData = (): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/auth_details",
//       method: "get",
//     })
//       .then(({ data }) => {
//         // console.log('data70', data)
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };
// export const get2FTAuth = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     // axios.put(`https://backend.bm2pay.com/api/v1/user/manage_2FA`, data)
//     axios.put(`${APIPath}/api/v1/user/manage_2FA`, data)
//       .then(({ data }) => {
//         // console.log('data70', data)
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };
// export const getRecover = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     axios.post(`${APIPath}/api/v1/user/verify-recover-key`, data)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };
// export const get2FTAuthVerify = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     // axios.post(`https://backend.bm2pay.com/api/v1/user/verify-otp`, data)
//     axios.post(`${APIPath}/api/v1/user/verify-otp`, data)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const updateProfile = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/profile",
//       method: "put",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const updatePassword = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/password",
//       method: "put",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const deleteProfile = (data: UserInfo): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/profile",
//       method: "delete",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const createUser = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user",
//       method: "post",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const updateUser = (data: any, id: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/" + id,
//       method: "put",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const deleteUser = (id: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/" + id,
//       method: "delete",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const getUser = (data: { id: string }): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/user/" + data.id,
//       method: "get",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getAllUsers = ({ page, per_page, searchTexts }: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
    allUsers(page: 1, per_page: 50${searchTexts ? `, searchTexts: "${searchTexts}"` : ''}){
      users{
        id
        email
        first_name
        last_name
        role_id
        role
        created_at
        updated_at
        last_sign_in_at
        organizations
        auth_2fa
        }
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_user'
    )
      .then((responseData) => {
        console.log('promise api call graphql response user all ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all ::', { err });
        reject(err);
      });
  });
};

export const getAllUsersData = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
    allUsers{
      users{
        id
        email
        first_name
        last_name
        role_id
        role
        created_at
        updated_at
        last_sign_in_at
        organizations
        auth_2fa
        }
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_user'
    )
      .then((responseData) => {
        console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const createUser = (data: any): Promise<any> => {
  let {email,first_name,last_name,role_id,organizations,password} = data;

  const formattedOrganizations : any = organizations.length > 0
    ? `[${organizations.map((org: string) => `"${org}"`).join(", ")}]`
    : "[]";

  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      createUser (
         email: "${email}",
         first_name: "${first_name}",
         last_name : "${last_name}",
         role_id : "${role_id}",
         organizations : ${formattedOrganizations},
         password : "${password}",
      ) {
         user {
          email
          first_name
          last_name
          role_id
          created_at
          organizations
        }
        status
        message
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_create_user'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const updateUser = (data: any,id:any): Promise<any> => {
  let {email,first_name,last_name,role_id,organizations,password} = data;

  const formattedOrganizations : any = organizations.length > 0
    ? `[${organizations.map((org: string) => `"${org}"`).join(", ")}]`
    : "[]";

  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      updateUser (
        user_id : "${id}",
         email: "${email}",
         first_name: "${first_name}",
         last_name : "${last_name}",
         role_id : "${role_id}",
         organizations : ${formattedOrganizations},
         ${password ? `, password: "${password}"` : ""}
      ) {
         user {
          email
          first_name
          last_name
          updated_at
          organizations
        }
        status
        message
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_update_user'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const deleteUser = (id:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      deleteUser(id: "${id}") {
        status
        message
      }
    }
      `;
        authGraphQLRequest<any>(
          queryString,
          '/api/v1/graphql_delete_user'
        )
          .then((responseData) => {
            resolve(responseData);
          })
          .catch((err) => {
            reject(err);
          });
  }); 
}

export const updateProfile = (data: any): Promise<any> => {
  let {email,first_name,last_name} = data;
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      updateProfile (
         email: "${email}",
         first_name: "${first_name}",
         last_name : "${last_name}",
      ) {
         user {
          email
          first_name
          last_name
        }
        status
        message
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_update_profile'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const updatePassword = (data: any): Promise<any> => {
  let {old_password,new_password} = data;
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      updateUserPassword (
         old_password: "${old_password}",
         new_password: "${new_password}"
      ) {
         user {
          email
          first_name
          last_name
        }
        status
        message
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_update_passward'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const deleteProfile = (data: any): Promise<any> => {
  let {password} = data;
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      deleteUserProfile (
         password: "${password}"
      ) {
        status
        message
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_delete_profile'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const get2FAData = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
    {
      authDetails {
        status
        base64_image
        secret_key
        recover_key
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_auth_details'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const get2FTAuth = (data:any): Promise<any> => {
  let {enable_2FA , user_id , secret_key , recover_key , inside_flag} = data;
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      manage2fa(input: {
        user_id: "${user_id}",
        enable_2FA: ${enable_2FA}
        ${secret_key ? `, secret_key: "${secret_key}"` : ""}
        ${recover_key ? `, recover_key: "${recover_key}"` : ""}
        ${inside_flag ? `, inside_flag: "${inside_flag}"` : ""}
      }) {
        status
        message
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_modify_auth'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response user all no param ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all no param ::', { err });
        reject(err);
      });
  });
};

export const get2FTAuthVerify = (data: any): Promise<any> => {
  const { otp, user_id, secret_key, recover_key, inside_flag } = data;

  const variables: any = {
    userId: user_id,
    otp,
  };
  if (inside_flag !== undefined) variables.insideFlag = inside_flag;
  if (secret_key) variables.secretKey = secret_key;
  if (recover_key) variables.recoverKey = recover_key;

  return new Promise((resolve, reject) => {
    const queryString = `
    mutation verifyOtp(
      $userId: String!, 
      $otp: String!
      ${inside_flag !== undefined ? `, $insideFlag: Boolean` : ""}
      ${secret_key ? `, $secretKey: String` : ""}
      ${recover_key ? `, $recoverKey: String` : ""}
    ) {
      verifyOtp(
        user_id: $userId, 
        otp: $otp
        ${inside_flag !== undefined ? `, inside_flag: $insideFlag` : ""}
        ${secret_key ? `, secret_key: $secretKey` : ""}
        ${recover_key ? `, recover_key: $recoverKey` : ""}
      ) {
        status
        message
        auth_token
        user
      }
    }
    `;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_verify_otp',
      variables
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        console.error('Error during GraphQL request:', err);
        reject(err);
      });
  });
};
