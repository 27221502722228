import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MdCheckCircle, MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { TokenObj } from '..';
import { useTable, useSortBy, useExpanded, Column, Row } from 'react-table';
import { getAllTokens, verifyTokenId } from 'api/tokens';
import Card from 'components/card';
import DivLoader from 'components/divloader/DivLoader';
import Pagination from 'components/pagination';
import { createColumnHelper } from '@tanstack/react-table';
import Searchbox from 'components/fields/Searchbox';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import StatusRender from 'views/admin/payments/components/StatusRender';
import { BiSolidBadgeCheck } from 'react-icons/bi';
import { getImage } from 'utils/commonFunction';
import { IoEye, IoEyeOutline } from 'react-icons/io5';
// import { getProfile } from 'api/users';
import { getProfile } from 'api/graphQL_users';
import toast from 'react-hot-toast';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import InputField from 'components/fields/InputField';
import { Link } from 'react-router-dom';
 

function Tokenlist(
    props: {
        data: any,
        isLoading: boolean;
        roleData: any;
        page: number;
        setPage: any;
        totalpage: number;
        totalItems: number;
        currentPage: number;
        pageSize: number;
        setPageSize: any;
        fetchAllTokensData: any;
        filter: any;
        setFilter: (value: any) => void;
        // allGateways: any;
        onValueChange: (value: string) => void;
        // filterData: () => void;
        setTokensData: any
    }
) {

    const {
        data,
        pageSize,
        setPageSize,
        isLoading,
        page,
        setPage,
        totalpage,
        totalItems,
        currentPage,
        onValueChange,
        roleData,
        // allGateways,
        fetchAllTokensData,
        setFilter,
        filter,
        // filterData
        setTokensData
    } = props;

    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
    const [isOpenTokenIdModal, setIsOpenTokenIdModal] = useState(false);
    const [tokenVerifyIput, setTokenVerifyIput] = useState<any>({});

    const [method, setRoleMethod] = useState(false);
    const [authCode, setAuthCode] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef<Array<HTMLInputElement | null>>(
      new Array(6).fill(null)
    );

    console.log("methods", roleData)



    useEffect(() => {

        let methods = roleData[0]?.token?.value?.token_show_method_name;
        setRoleMethod(methods)
    }, [roleData])




    const columns: any = useMemo(
        () => [

            { Header: 'CLIENT NAME', accessor: 'client', sortType: 'alphanumeric' },
            { Header: 'DATE', accessor: 'created_at', sortType: 'alphanumeric' },

            { Header: 'UNIQUE ID', accessor: 'id', sortType: 'alphanumeric' },

            { Header: 'TOKEN ID', accessor: 'token', sortType: 'alphanumeric' },
            { Header: `GATEWAY ${method ? "" : "ID"}`, accessor: `${method ? "gateway_name" : "gateway_id"}`, sortType: 'alphanumeric' },
            { Header: 'STATUS', accessor: 'status', sortType: 'alphanumeric' },



        ],
        [method]
    );

    const dataWithExpandedContent = useMemo(
        () =>
            data.map((row: any) => ({
                ...row,
                // subRows: row.payment.map((payment: any) => ({ ...payment })),
            })),
        [data]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: dataWithExpandedContent,
      },
      useSortBy,
      useExpanded
    );

  const handleValueChange = (e: any) => {
    onValueChange(e);
  };

  const onCloseTokenModal = () => {
    setIsOpenTokenIdModal(false);
    setTokenVerifyIput({});
  };

  const handleVerifyTokenId = () => {

    const passcode = authCode.join("")
    let payload = {
      password: tokenVerifyIput?.password,
      otp: passcode,
      token_unique_id: tokenVerifyIput?.token_unique_id,
    };
    verifyTokenId(payload)
      .then((data) => {
        let ResData = data;
        setTokensData((prev: any) => {
          return prev?.map((tData: any) => {
            if (tData?.id == ResData?.unique_id) {
              return {
                ...tData,
                token: ResData?.token,
                is_verified: ResData?.is_verified,
              };
            } else {
              return tData;
            }
          });
        });
        toast.success(data?.message);
        onCloseTokenModal();
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      })
      .finally(() => {});
  };

  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...authCode];
      newCode[index] = value;
      setAuthCode(newCode);

      // Move to the next box if a digit is entered
      if (value !== "" && index < 5 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && authCode[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault(); 
    const pastedData = e.clipboardData.getData("text").trim(); 
  
    if (/^\d{6}$/.test(pastedData)) { 
      const newCode = pastedData.split(""); 
      setAuthCode(newCode);
      inputRefs.current[5]?.focus();
    }
  };
  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6 mb-6"}>
        <header className="relative flex flex-wrap items-center justify-between pt-5">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Tokens
          </div>
          {/* <CardMenu /> */}
          <Searchbox onSearch={handleValueChange} />
        </header>

        <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
          {props?.isLoading ? (
            <DivLoader className="m-5 h-6 w-6  border-indigo-500" />
          ) : (
            <table
              {...getTableProps()}
              className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right"
            >
              <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    key={index}
                    {...headerGroup.getHeaderGroupProps()}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-3 pr-4 pt-4 text-start text-start text-sm font-bold text-gray-900 dark:text-white`}
                      >
                        {column.render("Header")}
                        <div className=" justify-between text-xs text-gray-200">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " "
                              : " "
                            : ""}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows?.length > 0 ? (
                  rows.map((row: Row<TokenObj>) => {
                    prepareRow(row);

                    return (
                      <React.Fragment key={row.id}>
                        <tr
                          {...row.getRowProps()}
                          className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600 "
                        >
                          {row.cells.map((cell, index) => (
                            <td
                              {...cell.getCellProps()}
                              className={`min-w-[150px] border-white/0 py-3  pl-3 pr-4 `}
                            >
                              {" "}
                              <div className="flex items-center">
                                <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                                  {/* {cell.value || "-"} */}

                                  {cell.value ? (
                                    cell?.column?.id == "status" ? (
                                      <div className="flex items-center gap-1.5 text-sm font-bold">
                                        <StatusRender
                                          status={cell.value}
                                          value={cell.value}
                                        />
                                      </div>
                                    ) : cell?.column?.id == "gateway_name" ? (
                                      roleData[0]?.token?.value
                                        ?.token_show_method_name ? (
                                        <span className="flex items-center ">
                                          <img
                                            style={{
                                              height: "auto",
                                              width: "15px",
                                            }}
                                            className="h-auto w-20"
                                            src={getImage(
                                              cell?.row?.original?.gateway_name
                                            )}
                                            alt="Gateway Image"
                                          />
                                          <p className="px-2">
                                            {cell?.row?.original?.gateway_name
                                              ? cell?.row?.original
                                                  ?.gateway_name
                                              : "-"}
                                          </p>
                                        </span>
                                      ) : (
                                        cell?.row?.original?.gateway_id
                                      )
                                    ) : (
                                      cell?.value
                                    )
                                  ) : cell?.column?.id == "token" ? (
                                    <>
                                      <span>{"**********"}</span>
                                      <IoEye
                                        className=" ml-2 h-5  w-5 cursor-pointer"
                                        onClick={() => {
                                          getProfile()
                                            .then((data) => {
                                              let is_auth_2fa_activate =
                                                data[0]?.auth_2fa;

                                              setIsOpenTokenIdModal(true);

                                              setTokenVerifyIput(
                                                (prev: any) => {
                                                  return {
                                                    ...prev,
                                                    token_unique_id:
                                                      cell?.row?.original?.id,
                                                    is_auth_2fa_activate,
                                                  };
                                                }
                                              );
                                            })
                                            .catch((error) => {
                                              toast.error(
                                                "something went wrong! please try after some time."
                                              );
                                            })
                                            .finally(() => {});
                                        }}
                                      />
                                    </>
                                  ) : (
                                    "-"
                                  )}

                                  {cell?.column?.id == "id" && cell.value ? (
                                    cell?.row?.original?.claimed ? (
                                      <>
                                        <BiSolidBadgeCheck
                                          title="Active"
                                          className="ml-2 h-5 w-5  text-green-500"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <BiSolidBadgeCheck
                                          title="Used"
                                          className="ml-2 h-5 w-5 text-gray-400"
                                        />
                                      </>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </td>
                          ))}
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={15}>
                      <p className="p-4 text-center">No records found.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <Pagination
          setPage={setPage}
          page={page}
          totalpage={totalpage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          arraySize={[50, 100, 200]}
        />
      </Card>
      <Modal isOpen={isOpenTokenIdModal} onClose={onCloseTokenModal}>
        <ModalOverlay className="z-20   bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto   !w-max min-w-[350px] !max-w-[85%] shadow md:top-[12vh] ">
          <ModalBody className="">
            <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[450px] flex flex-col !z-[1004] ">
              {!tokenVerifyIput?.is_auth_2fa_activate ? (
                <>
                  <h1 className=" text-2xl font-bold">{"2FA Verification"}</h1>
                  <p className="opacity-50">
                    To see the Token Id please verify 2FA code.
                  </p>
                  <br />

                  <p className="">
                    Your account does not meet the necessary requirements in
                    order to see Token Id, please visit{" "}
                    <Link to="/admin/profile">profile settings</Link> and
                    activate 2FA code.
                  </p>
                  <div className="mt-3 flex  justify-end gap-2">
                    <button
                      onClick={onCloseTokenModal}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    >
                      Close
                    </button>{" "}
                  </div>
                </>
              ) : (
                <>
                  <h1 className=" text-2xl font-bold">{"2FA Verification"}</h1>
                  <p className="opacity-50">
                    To see the Token Id please verify 2FA code.
                  </p>

                  <div className="w-72 bg-white">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full mb-2"
                      value={tokenVerifyIput?.password}
                      label="Password *"
                      placeholder="Enter password"
                      id="password"
                      type={`${tokenVerifyIput?.isShow ? "text" : "password"}`}
                      onChange={(e) => {
                        const name = e.target.id;
                        const value = e.target?.value;
                        setTokenVerifyIput((prev: any) => {
                          return {
                            ...prev,
                            [name]: value,
                          };
                        });
                      }}
                      passwordShow={tokenVerifyIput?.isShow}
                      setPasswordShow={() =>
                        setTokenVerifyIput((prev: any) => {
                          return {
                            ...prev,
                            isShow: !prev?.isShow,
                          };
                        })
                      }
                    />
                  </div>
                  <div className="w-72">
                    <label className='text-gray-900 font-bold px-2 mt-2'>2FA Code *</label>
                    <div className="flex justify-center space-x-2 p-2">
                      
                      {authCode.map((digit, index) => (
                        <input
                          key={index}
                          ref={(el) => (inputRefs.current[index] = el)}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          onPaste={index === 0 ? handlePaste : undefined}
                          className="w-10 h-10 text-center text-lg border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-500"
                        />
                      ))}
                    </div>
                  </div>
                  <div className="mt-3 flex  justify-end gap-2">
                    <button
                      onClick={onCloseTokenModal}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    >
                      Close
                    </button>{" "}
                    <button
                      className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                      onClick={() => {
                        handleVerifyTokenId();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Tokenlist;
