// ==================================
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  getGatewayForClient,
  verifyWalletAddress,
} from "api/client-gateways";
// import { getAllGateways, getAllGatewaysByClient } from "api/gateways";
// import { getAllGateways } from "api/gateways";
import { getAllGateways , getAllGatewaysByClient } from "api/graphQL_gateways";
import { createClientGateway } from "api/graphQL_client-gateways";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
} from "@chakra-ui/react";
import sipe from "assets/img/payment-clients/sipe.png";
import pixLotus from "assets/img/payment-clients/pix.png";
import pix from "assets/img/payment-clients/pix.png";
import blumon from "assets/img/payment-clients/blumon.png";
import stripe from "assets/img/payment-clients/stripe.png";
import memphis from "assets/img/payment-clients/memphis.png";

import repyd from "assets/img/payment-clients/rapyd.png";
import mit from "assets/img/payment-clients/mit.png";
import banwire from "assets/img/payment-clients/banwire.jpg";
import valitor from "assets/img/payment-clients/valitor.png";
import bambora from "assets/img/payment-clients/bambora.png";
import Scipiopay from "assets/img/payment-clients/Scipiopay.png";
import Aurea_via from "assets/img/payment-clients/Aurea_via.png";
import Betapay from "assets/img/payment-clients/Betapay.png";
import Kasha from "assets/img/payment-clients/Kasha.jpg";
import Platy from "assets/img/payment-clients/Platy.png";
import reSet from "assets/img/payment-clients/reset.png";
import Supefina from "assets/img/payment-clients/supefina.svg";
import Linx from "assets/img/payment-clients/linx_logo.png";
import Master_Pay from "assets/img/payment-clients/master_Pay.png";
import Neonpay from "assets/img/payment-clients/neonpay.png";
import Bm2pay from "assets/img/payment-clients/bm2pay.svg";
import Threexpay from "assets/img/payment-clients/3XPAY.png";
import Digitarium from "assets/img/payment-clients/digitarium.png";
import Moneto from "assets/img/payment-clients/moneto.png";
import Tangoindia from "assets/img/payment-clients/tangoindia.png";
import G2pay from "assets/img/payment-clients/g2pay.png";

import { getCurrencySymbol, currencyCodes } from "utils/currencyList"; // Adjust the path if needed

import Card from "components/card";
import Card2 from "components/card/custom";

import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import { useEffect, useState, useContext, useMemo } from "react";
import { toast } from "react-hot-toast";
import { MdCircle, MdClass, MdClose, MdHighlightOff, MdIntegrationInstructions } from "react-icons/md";
import DeleteClientGatewayModal from "./DeleteClientGatewayModal";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";

import GatewayPricing from "./GatewayPricing";
import BankInfo from "./BankInfo";
import WalletAddress from "./WalletAddress";
import Radio from "components/radio";

import { ClientContext } from "ClientProvider";
import { BsPlusCircle, BsDashCircle } from "react-icons/bs";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { IoEyeOutline } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import Checkbox from "components/checkbox";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Link } from "react-router-dom";
// import { getProfile } from "api/users";
import { getProfile } from "api/graphQL_users";
// import Select from 'react-dropdown-select';
import { Select as AntSelect } from "antd";
import countryList from "react-select-country-list";
import WhiteListClients from "views/admin/WhiteList";

interface iGateway {
  client_id?: string;
  id?: string;
  name: string;
  inputs: any;
  image: string;
  to_convert?: any;
  new_currency?: any;
  reserved_pricing?: any;
  settlement?: any;
  is_active?: boolean;
  mid_country_live?: string;
  mid_country_test?: string;
  test_endpoint: string;
  live_endpoint: string;
}

interface velocity {
  transaction_limit: {
    allow_transaction_limit: true;
    limit_no: "12";
    period: "Month";
  };
  blocked_countries: {
    allow_blocked_countries: true;
    country_code: [
      {
        value: "AX";
        label: "Åland Islands";
      },
      {
        value: "AG";
        label: "Antigua and Barbuda";
      },
      {
        value: "AQ";
        label: "Antarctica";
      }
    ];
  };
  blocked_countries_bin: {
    allow_blocked_countries_bin: true;
    country_code: [
      {
        value: "AX";
        label: "Åland Islands";
      },
      {
        value: "AG";
        label: "Antigua and Barbuda";
      },
      {
        value: "AQ";
        label: "Antarctica";
      }
    ];
  };
  max_transaction_limit: {
    allow_max_transaction_limit: true;
    limit_amount: "12";
    currency: "ARS";
  };
  min_transaction_limit: {
    allow_min_transaction_limit: true;
    limit_amount: "12";
    currency: "ARS";
  };
  max_transaction_period: {
    allow_max_transaction_period: true;
    limit_amount: "1212";
    currency: "ARS";
    period: "Day";
  };
  retry_time: {
    allow_retry_time: true;
    limit_minutes: "12";
  };
  max_attempts_day: {
    allow_max_attempts_day: true;
    limit_no: "12";
  };
  max_attempts_week: {
    allow_max_attempts_week: true;
    limit_no: "12";
  };
  max_attempts_month: {
    allow_max_attempts_month: true;
    limit_no: "12";
  };
  max_attempts_total: {
    allow_max_attempts_total: true;
    limit_no: "12";
  };
}

export const GatewayTypes: iGateway[] = [
  {
    client_id: "",
    id: "",
    name: "3xpay",
    is_active: true,
    inputs: {
      api_key_test: "",
      api_secret_test: "",
      api_secret_live: "",
      api_key_live: "",
      is_live: false,
    },

    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: Threexpay,
    // is_active: false,
  },
  {
    client_id: "",
    id: "",
    name: "Stripe",
    is_active: true,
    inputs: {
      secret_key_live: "",
      secret_key_test: "",
      statement_descriptor: "",
      statement_descriptor_suffix: "",
      is_live: false,
    },

    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: stripe,
    // is_active: false,
  },
  {
    client_id: "",
    id: "",
    name: "Sipe",
    is_active: true,

    inputs: {
      key: "",
      business_name: "",
      merchant_id: "",
      app_id: "",
      nonce_string: "",
      body: "",
      notify_url: "",
      base_url: "",
      is_live: false,
      descriptor: "",
    },
    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: sipe,
  },
  {
    client_id: "",
    id: "",
    name: "Blumon",
    is_active: true,

    inputs: {
      business_name: "",
      username: "",
      password: "",
      base_url: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: blumon,
  },
  {
    client_id: "",
    id: "",
    name: "Pix-Lotus",
    inputs: {
      subscription_key: "",
      authorization: "",
      base_url: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    is_active: true,

    to_convert: "",
    new_currency: "",
    image: pixLotus,
  },
  {
    client_id: "",
    id: "",
    name: "Re-set",
    inputs: {
      secretKey_test: "",
      secretKey_live: "",
      brand_id_test: "",
      brand_id_live: "",
      base_url: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",
    is_active: true,

    to_convert: "",
    new_currency: "",
    image: reSet,
  },
  {
    client_id: "",
    id: "",
    name: "Pix",
    inputs: {
      // business_name: "",
      name_test: "",
      name_live: "",
      email_test: "",
      email_live: "",
      // subscription_key_live: "",
      // subscription_key_test: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: pix,
    is_active: true,
  },
  {
    client_id: "",
    id: "",
    name: "Memphis",
    inputs: {
      business_name: "",
      subscription_key_live: "",
      subscription_key_test: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: memphis,
    is_active: true,
  },
  {
    client_id: "",
    id: "",
    name: "Raypd",
    is_active: true,

    inputs: {
      access_key_live: "",
      access_key_test: "",
      secret_key_live: "",
      secret_key_test: "",
      mid_country_test: "",
      mid_country_live: "",
      // to_convert: false,
      // new_currency: "",
      is_live: false,
      descriptor: "",
    },
    to_convert: "",
    new_currency: "",
    image: repyd,

    test_endpoint: "",
    live_endpoint: "",
  },
  {
    client_id: "",
    id: "",
    name: "MIT",
    is_active: true,

    inputs: {
      id_company_live: "",
      id_company_test: "",
      id_branch_live: "",
      id_branch_test: "",
      user_code_live: "",
      user_code_test: "",
      password_live: "",
      password_test: "",
      merchant_id_live: "",
      merchant_id_test: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: mit,
  },
  {
    client_id: "",
    id: "",
    name: "Banwire",
    inputs: {
      username_live: "",
      username_test: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    is_active: true,

    to_convert: "",
    new_currency: "",
    image: banwire,
  },
  {
    client_id: "",
    id: "",
    name: "Valitor",
    inputs: {
      apikey_live: "",
      apikey_test: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",
    is_active: true,

    to_convert: "",
    new_currency: "",
    image: valitor,
  },
  {
    client_id: "",
    id: "",
    name: "Bambora",
    is_active: true,

    inputs: {
      merchantId_test: "",
      merchantId_live: "",
      passcode_test: "",
      passcode_live: "",
      hash_key_test: "",
      hash_key_live: "",
      recurring_passcode_test : "",
      recurring_passcode_live : "",
      reporting_passcode_test : "",
      reporting_passcode_live : "",
      batch_passcode_test : "",
      batch_passcode_live : "",
      subscription_passcode_test : "",
      subscription_passcode_live : "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: bambora,
  },
  {
    client_id: "",
    id: "",
    name: "Scipiopay",
    is_active: true,

    inputs: {
      shop_id_test: "",
      shop_id_live: "",
      // merchantId_live: "",
      shop_secret_key_test: "",
      shop_secret_key_live: "",
      // passcode_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Scipiopay,
  },
  {
    client_id: "",
    id: "",
    name: "Aurea Via",
    is_active: true,

    inputs: {
      companyNum_test: "",
      companyNum_live: "",
      personalHashkey_test: "",
      personalHashkey_live: "",

      test_endpoint: "",
      live_endpoint: "",

      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Aurea_via,
  },
  {
    client_id: "",
    id: "",
    name: "Betapay",
    is_active: true,

    inputs: {
      api_token_test: "",
      api_token_live: "",
      merchant_id_test: "",
      merchant_id_live: "",
      terminal_id_test: "",
      terminal_id_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Betapay,
  },
  {
    client_id: "",
    id: "",
    name: "Kasha",
    is_active: true,

    inputs: {
      api_key_test: "",
      api_key_live: "",
      merchant_domain_test: "",
      merchant_domain_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Kasha,
  },
  {
    client_id: "",
    id: "",
    name: "Linx",
    is_active: true,

    inputs: {
      client_id_test: "",
      client_id_live: "",
      client_secret_test: "",
      client_secret_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Linx,
  },
  {
    client_id: "",
    id: "",
    name: "Supefina",
    is_active: true,

    inputs: {
      merchant_id_test: "",
      merchant_id_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Supefina,
  },
  {
    client_id: "",
    id: "",
    name: "Platy",
    is_active: true,

    inputs: {
      merchant_id_test: "",
      merchant_id_live: "",
      api_key_test: "",
      password_test: "",
      platy_api_key_test: "",
      platy_secret_key_test: "",
      username_test: "",
      api_key_live: "",
      password_live: "",
      platy_api_key_live: "",
      platy_secret_key_live: "",
      username_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Platy,
  },
  {
    client_id: "",
    id: "",
    name: "Platy 2DS",
    is_active: true,

    inputs: {
      merchant_id_test: "",
      merchant_id_live: "",
      api_key_test: "",
      password_test: "",
      platy_api_key_test: "",
      platy_secret_key_test: "",
      username_test: "",
      api_key_live: "",
      password_live: "",
      platy_api_key_live: "",
      platy_secret_key_live: "",
      username_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Platy,
  },
  {
    client_id: "",
    id: "",
    name: "Platy 3DS",
    is_active: true,

    inputs: {
      merchant_id_test: "",
      merchant_id_live: "",
      api_key_test: "",
      password_test: "",
      platy_api_key_test: "",
      platy_secret_key_test: "",
      username_test: "",
      api_key_live: "",
      password_live: "",
      platy_api_key_live: "",
      platy_secret_key_live: "",
      username_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Platy,
  },
  {
    client_id: "",
    id: "",
    name: "Masterpay",
    is_active: true,
    inputs: {
      merchant_private_key_test: "",
      merchant_private_key_live: "",
      is_live: false,
      descriptor: "",
    },
    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: Master_Pay,
  },
  {
    client_id: "",
    id: "",
    name: "Neonpay",
    is_active: true,
    inputs: {
      merchant_key_test: "",
      merchant_key_live: "",
      password_test: "",
      password_live: "",
      // term_url_3ds_test: "",
      // term_url_3ds_live: "",
      is_live: false,
      descriptor: "",
    },
    test_endpoint: "",
    live_endpoint: "",
    to_convert: "",
    new_currency: "",
    image: Neonpay,
  },
  {
    client_id: "",
    id: "",
    name: "Bm2pay",
    is_active: true,

    inputs: {
      client_id_test: "",
      client_id_live: "",
      client_secret_test: "",
      client_secret_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Bm2pay,
  },
  {
    client_id: "",
    id: "",
    name: "Digitarium",
    is_active: true,

    inputs: {
      merchant_key_test: "",
      merchant_key_live: "",
      password_test: "",
      password_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Digitarium,
  },
  {
    client_id: "",
    id: "",
    name: "Tangoindia",
    is_active: true,

    inputs: {
      api_id_test: "",
      api_id_live: "",
      api_key_test: "",
      api_key_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Tangoindia,
  },
  {
    client_id: "",
    id: "",
    name: "Moneto",
    is_active: true,

    inputs: {
      merchant_secret_key_test: "",
      merchant_secret_key_live: "",
      merchant_id_test: "",
      merchant_id_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: Moneto,
  },
  {
    client_id: "",
    id: "",
    name: "G2Pay",
    is_active: true,

    inputs: {
      signing_key_test: "",
      signing_key_live: "",
      api_key_test: "",
      api_key_live: "",
      is_live: false,
      descriptor: "",
    },

    test_endpoint: "",
    live_endpoint: "",

    to_convert: "",
    new_currency: "",
    image: G2pay,
  },
];

interface PredefinedCredentials {
  id: string;
  [key: string]: any;
}

const CreateClientGatewaysModal = ({
  id,
  fetchClients,
  assigned_payment_methods,
  infoData,
}: {
  id: string;
  fetchClients: () => void;
  assigned_payment_methods?: any;
  infoData?: any;
}) => {
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [type, setType] = useState<string>();
  const [hashID, setHashID] = useState<string>();
  const [formValues, setFormValues] = useState<any>({});
  const [addedgateways, setAddedGateways] = useState<iGateway[]>(GatewayTypes);
  const [gatewayTypes, setGatewayTypes] = useState<any[]>([]);
  const [gatewayTypesCopy, setGatewayTypesCopy] = useState<any[]>([]);

  const [formValuesErr, setFormValuesErr] = useState<any>();
  const [toggleBtn, setToggleBtn] = useState<any>(false);
  const [user, setUser] = useState("Jesse Hall");
  const [storeGetwayId, setStoreGateWayId] = useState();
  const [passwordShow, setPasswordShow] = useState(false);
  const [transSelect, setTransSelect] = useState();
  const [reseSelect, setReseSelect] = useState();
  const [bankInfoCount, setBankInfoCount] = useState<number>(1);
  const [walletCount, setWalletCount] = useState<number>(1);
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [methodName, setMethodName] = useState<boolean>(false);
  const [pricingRight, setPricingRight] = useState<boolean>(false);
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] =
    useState<boolean>(false);
  const [settelmentRight, setSettelmentRight] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [currencyConv, setCurrencyConv] =
    useState<string>("requestPassthrough");

  const [selectedCurrency, setSelectedCurrency] = useState<string>("USD");
  const [isOpenWalletAddress, setIsOpenWalletAddress] =
    useState<boolean>(false);
  const [addNewWallet, setAddNewWallet] = useState<any>([]);
  const [descardWallet, setDescardWallet] = useState<any>([]);
  const [wallertVerifyIput, setWallertVerifyIput] = useState<any>({});
  const [isCloseModal, setIsCloseModal] =  useState<boolean>(false);
  const [credentialsType, setCredentialsType] =  useState("custom");
  const [predefinedCredentials,setPredefinedCredentials] = useState<any[]>([]);
  const [selectedCredential,setSelectedCredential] = useState("");
  const [selectedPredefinedCredentials,setSelectedPredefinedCredentials] = useState<PredefinedCredentials | null>(null);
  const [credentialError,setCredentialError] = useState("");

  const CountryOptions = useMemo(() => countryList().getData(), []);
  const binCountryOptions = useMemo(() => countryList().getData(), []);

  const [isValidateAdditionalFeesPricing, setisValidateAdditionalFeesPricing] =
    useState<boolean>(false);

  const [isValidateAdditionalFeesCost, setisValidateAdditionalFeesCost] =
    useState<boolean>(false);

  const [isValidateVelocity, setisValidateVelocity] = useState<boolean>(false);

  const [isValidateAdditionalFees, setisValidateAdditionalFees] =
    useState<boolean>(false);

  const handleCurrencySelect = (event: any) => {
    setSelectedCurrency(event);
  };
  const convertIntoArray = (saved_form_fees_details_test: any) => {
    const data = [
      {
        name: "Payment",
        is_Active:
          saved_form_fees_details_test[0]?.payments === true ? true : false,
        disabled:
          saved_form_fees_details_test[0]?.payments === null ? false : true,
      },
      {
        name: "Refund",
        is_Active:
          saved_form_fees_details_test[0]?.refund === true ? true : false,
        disabled:
          saved_form_fees_details_test[0]?.refund === null ? false : true,
      },
      {
        name: "APM",
        is_Active: saved_form_fees_details_test[0]?.apm === true ? true : false,
        disabled: saved_form_fees_details_test[0]?.apm === null ? false : true,
      },
      {
        name: "Authorization",
        is_Active:
          saved_form_fees_details_test[0]?.authorization === true
            ? true
            : false,
        disabled:
          saved_form_fees_details_test[0]?.authorization === null
            ? false
            : true,
      },
      {
        name: "Subscription",
        is_Active:
          saved_form_fees_details_test[0]?.subscription === true ? true : false,
        disabled:
          saved_form_fees_details_test[0]?.subscription === null ? false : true,
      },
      {
        name: "Token",
        is_Active:
          saved_form_fees_details_test[0]?.token === true ? true : false,
        disabled:
          saved_form_fees_details_test[0]?.token === null ? false : true,
      },
      {
        name: "Payout",
        is_Active:
          saved_form_fees_details_test[0]?.payout === true ? true : false,
        disabled:
          saved_form_fees_details_test[0]?.payout === null ? false : true,
      },
      {
        name: "Payin",
        is_Active:
          saved_form_fees_details_test[0]?.payin === true ? true : false,
        disabled:
          saved_form_fees_details_test[0]?.payin === null ? false : true,
      },
    ];
    return data;
  };
  useEffect(() => {
    const method = singleRoleData[0]?.client?.value?.client_show_method_name;
    const pricing_right =
      singleRoleData[0]?.client?.value?.client_gateway_pricing;
    const settelment_right =
      singleRoleData[0]?.client?.value?.client_gateway_settlement;
    setPricingRight(pricing_right);
    setSettelmentRight(settelment_right);
    setMethodName(method);
    let saved_form_inputs = addedgateways.filter((data) => {
      return data?.name === type;
    });
    let saved_form_inputs_test = gatewayTypes?.filter(
      (data) => data?.name === type
    )?.[0]?.meta_info;

    let saved_form_CardTypes = gatewayTypes?.filter(
      (data) => data?.name === type
    )?.[0]?.card_types;

    let saved_form_fees_details_test = gatewayTypes?.filter(
      (data) => data?.name === type
    );
    // let is_active =

    // console.log(
    //   "saved_form_fees_details_test",
    //   saved_form_fees_details_test,
    //   saved_form_inputs
    // );
    // console.log("==============================", { saved_form_inputs })
    let newSavedInput =
      saved_form_inputs &&
      saved_form_inputs.length > 0 &&
      saved_form_inputs?.map((item: any) => {
        // console.log("====================================");
        // console.log("====================================", { item });
        // console.log("====================================");
        const value = item?.is_live;
        // const is_active = saved_form_fees_details_test[0].is_active;
        const is_active = item?.is_active;

        const clonedObject =
          type === "Stripe"
            ? {
                ...item?.inputs,
                is_live: value === undefined ? false : value,
                is_active: is_active,
                secret_key_test: item?.inputs?.secret_key_test
                  ? item?.inputs?.secret_key_test
                  : saved_form_inputs_test?.secret_key,
                secret_key_live: item?.inputs?.secret_key_live
                  ? item?.inputs?.secret_key_live
                  : "",
                statement_descriptor_suffix: item?.inputs
                  ?.statement_descriptor_suffix
                  ? item?.inputs?.statement_descriptor_suffix
                  : saved_form_inputs_test?.statement_descriptor_suffix,
                statement_descriptor: item?.inputs?.statement_descriptor
                  ? item?.inputs?.statement_descriptor
                  : saved_form_inputs_test?.statement_descriptor,

                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                // is_active: item?.is_active,

                // client_cost:
                //   item?.reserved_pricing?.client_cost ||
                //   item?.reserved_pricing?.client_card_types?.map((i: any) => {
                //     return {
                //       ...i,
                //       percentage_fee: "",
                //       fixed_fee: "",
                //     };
                //   }) ||
                //   [],
                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Pix"
            ? {
                ...item?.inputs,
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                // subscription_key_test: item?.inputs?.subscription_key_test
                //   ? item?.inputs?.subscription_key_test
                //   : saved_form_inputs_test?.subscription_key,
                // subscription_key_live: item?.inputs?.subscription_key_live
                //   ? item?.inputs?.subscription_key_live
                //   : "",
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countrie_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                name_test: item?.inputs?.name_test
                  ? item?.inputs?.name_test
                  : saved_form_inputs_test?.name_test,
                name_live: item?.inputs?.name_live
                  ? item?.inputs?.name_live
                  : "",

                email_test: item?.inputs?.email_test
                  ? item?.inputs?.email_test
                  : saved_form_inputs_test?.email_test,
                email_live: item?.inputs?.email_live
                  ? item?.inputs?.email_live
                  : "",

                // business_name: item?.inputs?.business_name
                //   ? item?.inputs?.business_name
                //   : saved_form_inputs_test?.business_name,

                // client_cost:
                //   item?.reserved_pricing?.client_cost ||
                //   item?.reserved_pricing?.client_card_types.map((i: any) => {
                //     return {
                //       ...i,
                //       percentage_fee: "",
                //       fixed_fee: "",
                //     };
                //   }) ||
                //   [],
                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Banwire"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                username_test: item?.inputs?.username_test
                  ? item?.inputs?.username_test
                  : saved_form_inputs_test?.username_test,
                username_live: item?.inputs?.username_live
                  ? item?.inputs?.username_live
                  : "",

                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                // client_cost:
                //   item?.reserved_pricing?.client_cost ||
                //   item?.reserved_pricing?.client_card_types.map((i: any) => {
                //     return {
                //       ...i,
                //       percentage_fee: "",
                //       fixed_fee: "",
                //     };
                //   }) ||
                //   [],
                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Valitor"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                apikey_test: item?.inputs?.apikey_test
                  ? item?.inputs?.apikey_test
                  : saved_form_inputs_test?.apikey_test,
                apikey_live: item?.inputs?.apikey_live
                  ? item?.inputs?.apikey_live
                  : "",
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Bambora"
            ? {
                ...item?.inputs,
                is_live: value === undefined ? false : value,
                is_active: is_active,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                  advanced_avs : {
                    validate_address  : item?.advanced_avs?.validate_address || false,
                    validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                    validate_email  : item?.advanced_avs?.validate_email || false,
                    validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                  },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                merchantId_test: item?.inputs?.merchantId_test
                  ? item?.inputs?.merchantId_test
                  : saved_form_inputs_test?.merchantId_test,
                merchantId_live: item?.inputs?.merchantId_live
                  ? item?.inputs?.merchantId_live
                  : "",
                passcode_test: item?.inputs?.passcode_test
                  ? item?.inputs?.passcode_test
                  : saved_form_inputs_test?.passcode_test,
                passcode_live: item?.inputs?.passcode_live
                  ? item?.inputs?.passcode_live
                  : "",
                hash_key_test: item?.inputs?.hash_key_test
                  ? item?.inputs?.hash_key_test
                  : saved_form_inputs_test?.hash_key_test,
                hash_key_live: item?.inputs?.hash_key_live
                  ? item?.inputs?.hash_key_live
                  : "",
                recurring_passcode_test: item?.inputs?.recurring_passcode_test
                  ? item?.inputs?.recurring_passcode_test
                  : saved_form_inputs_test?.recurring_passcode_test,
                recurring_passcode_live: item?.inputs?.recurring_passcode_live
                  ? item?.inputs?.recurring_passcode_live
                  : "",
                reporting_passcode_test: item?.inputs?.reporting_passcode_test
                  ? item?.inputs?.reporting_passcode_test
                  : saved_form_inputs_test?.reporting_passcode_test,
                reporting_passcode_live: item?.inputs?.reporting_passcode_live
                  ? item?.inputs?.reporting_passcode_live
                  : "",  
                batch_passcode_test: item?.inputs?.batch_passcode_test
                  ? item?.inputs?.batch_passcode_test
                  : saved_form_inputs_test?.batch_passcode_test,
                batch_passcode_live: item?.inputs?.batch_passcode_live
                  ? item?.inputs?.batch_passcode_live
                  : "", 
                subscription_passcode_test: item?.inputs?.subscription_passcode_test
                  ? item?.inputs?.subscription_passcode_test
                  : saved_form_inputs_test?.subscription_passcode_test,
                subscription_passcode_live: item?.inputs?.subscription_passcode_live
                  ? item?.inputs?.subscription_passcode_live
                  : "",  
                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
              : type === "Digitarium"
              ? {
                  ...item?.inputs,
                  is_live: value === undefined ? false : value,
                  is_active: is_active,
                  test_endpoint: item?.test_endpoint
                    ? item?.test_endpoint
                    : saved_form_fees_details_test[0]?.test_endpoint,
                  live_endpoint: item?.live_endpoint
                    ? item?.live_endpoint
                    : saved_form_fees_details_test[0]?.live_endpoint,
                    advanced_avs : {
                      validate_address  : item?.advanced_avs?.validate_address || false,
                      validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                      validate_email  : item?.advanced_avs?.validate_email || false,
                      validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                    },
                  velocity: {
                    transaction_limit: {
                      allow_transaction_limit:
                        item?.velocity?.transaction_limit
                          ?.allow_transaction_limit || false,
                      limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                      period: item?.velocity?.transaction_limit?.period || "",
                    },
                    blocked_countries: {
                      allow_blocked_countries:
                        item?.velocity?.blocked_countries
                          ?.allow_blocked_countries || false,
                      country_code:
                        item?.velocity?.blocked_countries?.country_code || [],
                    },
                    blocked_countries_bin: {
                      allow_blocked_countries_bin:
                        item?.velocity?.blocked_countries_bin
                          ?.allow_blocked_countries_bin || false,
                      country_code:
                        item?.velocity?.blocked_countries_bin?.country_code || [],
                    },
                    max_transaction_limit: {
                      allow_max_transaction_limit:
                        item?.velocity?.max_transaction_limit
                          ?.allow_max_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.max_transaction_limit?.limit_amount || "",
                      currency:
                        item?.velocity?.max_transaction_limit?.currency || "",
                    },
                    min_transaction_limit: {
                      allow_min_transaction_limit:
                        item?.velocity?.min_transaction_limit
                          ?.allow_min_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.min_transaction_limit?.limit_amount || "",
                      currency:
                        item?.velocity?.min_transaction_limit?.currency || "",
                    },
                    max_transaction_period: {
                      allow_max_transaction_period:
                        item?.velocity?.max_transaction_period
                          ?.allow_max_transaction_period || false,
                      limit_amount:
                        item?.velocity?.max_transaction_period?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_period?.currency || "",
                      period:
                        item?.velocity?.max_transaction_period?.period || "",
                    },
                    retry_time: {
                      allow_retry_time:
                        item?.velocity?.retry_time?.allow_retry_time || false,
                      limit_minutes:
                        item?.velocity?.retry_time?.limit_minutes || "",
                    },
                    max_attempts_day: {
                      allow_max_attempts_day:
                        item?.velocity?.max_attempts_day
                          ?.allow_max_attempts_day || false,
                      limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                    },
                    max_attempts_week: {
                      allow_max_attempts_week:
                        item?.velocity?.max_attempts_week
                          ?.allow_max_attempts_week || false,
                      limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                    },
                    max_attempts_month: {
                      allow_max_attempts_month:
                        item?.velocity?.max_attempts_month
                          ?.allow_max_attempts_month || false,
                      limit_no:
                        item?.velocity?.max_attempts_month?.limit_no || "",
                    },
                    max_attempts_total: {
                      allow_max_attempts_total:
                        item?.velocity?.max_attempts_total
                          ?.allow_max_attempts_total || false,
                      limit_no:
                        item?.velocity?.max_attempts_total?.limit_no || "",
                    },
                  },
  
                  merchantKey_test: item?.inputs?.merchantKey_test
                    ? item?.inputs?.merchantKey_test
                    : saved_form_inputs_test?.merchantKey_test,
                  merchantKey_live: item?.inputs?.merchantKey_live
                    ? item?.inputs?.merchantKey_live
                    : "",
                  password_test: item?.inputs?.password_test
                    ? item?.inputs?.password_test
                    : saved_form_inputs_test?.password_test,
                  password_live: item?.inputs?.password_live
                    ? item?.inputs?.password_live
                    : "", 
                  client_card_types:
                    // item?.reserved_pricing?.client_card_types || [],
                    item?.reserved_pricing?.client_card_types?.map((m: any) => {
                      return {
                        card_name: m?.card_name,
                        percentage_fee: m?.percentage_fee,
                        fixed_fee: m?.fixed_fee,
                        currency_code: m?.currency_code,
                        allow_card: m?.allow_card,
  
                        cost_percentage_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.percentage_fee || "",
                        cost_fixed_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.fixed_fee || "",
                        cost_currency_code: item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.currency_code,
                      };
                    }),
                  // additional_fees: item?.reserved_pricing?.additional_fees || {},
                  additional_fees: {
                    chargeback_fees:
                      item?.reserved_pricing?.additional_fees?.chargeback_fees,
                    dispute_fees:
                      item?.reserved_pricing?.additional_fees?.dispute_fees,
                    reserve_fees:
                      item?.reserved_pricing?.additional_fees?.reserve_fees,
                    refunded_tnx_fees:
                      item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                    declined_tnx_fees:
                      item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                    cost_chargeback_fees:
                      item?.cost?.additional_fees?.chargeback_fees,
                    cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                    cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                    cost_refunded_tnx_fees:
                      item?.cost?.additional_fees?.refunded_tnx_fees,
                    cost_declined_tnx_fees:
                      item?.cost?.additional_fees?.declined_tnx_fees,
                  },
                  cardTypes: saved_form_CardTypes,
                  payout_fees: item?.payout_fees || {},
                  wallet_ids: item?.wallet_ids || [],
                  ewallet_New_addressess: [],
                  disCard_walletes: [],
                  fess_conditions: {
                    apm: saved_form_fees_details_test[0]?.apm,
                    payout: saved_form_fees_details_test[0]?.payout,
                    payin: saved_form_fees_details_test[0]?.payin,
                  },
                  payment_methods: item?.payment_method
                    ? item?.payment_method?.filterPaymentMethod
                    : convertIntoArray(saved_form_fees_details_test),
                  gateway_method_fees:
                    // item?.reserved_pricing?.gateway_method_fees || {},
                    {
                      apm_fees:
                        item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                      cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                      payout_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                      cost_payout_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                      payin_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                      cost_payin_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    },
                  type: type,
                  whitelist_enabled: item?.whitelist_enabled,
                }
                : type === "Tangoindia"
                ? {
                    ...item?.inputs,
                    is_live: value === undefined ? false : value,
                    is_active: is_active,
                    test_endpoint: item?.test_endpoint
                      ? item?.test_endpoint
                      : saved_form_fees_details_test[0]?.test_endpoint,
                    live_endpoint: item?.live_endpoint
                      ? item?.live_endpoint
                      : saved_form_fees_details_test[0]?.live_endpoint,
                      advanced_avs : {
                        validate_address  : item?.advanced_avs?.validate_address || false,
                        validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                        validate_email  : item?.advanced_avs?.validate_email || false,
                        validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                      },
                    velocity: {
                      transaction_limit: {
                        allow_transaction_limit:
                          item?.velocity?.transaction_limit
                            ?.allow_transaction_limit || false,
                        limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                        period: item?.velocity?.transaction_limit?.period || "",
                      },
                      blocked_countries: {
                        allow_blocked_countries:
                          item?.velocity?.blocked_countries
                            ?.allow_blocked_countries || false,
                        country_code:
                          item?.velocity?.blocked_countries?.country_code || [],
                      },
                      blocked_countries_bin: {
                        allow_blocked_countries_bin:
                          item?.velocity?.blocked_countries_bin
                            ?.allow_blocked_countries_bin || false,
                        country_code:
                          item?.velocity?.blocked_countries_bin?.country_code || [],
                      },
                      max_transaction_limit: {
                        allow_max_transaction_limit:
                          item?.velocity?.max_transaction_limit
                            ?.allow_max_transaction_limit || false,
                        limit_amount:
                          item?.velocity?.max_transaction_limit?.limit_amount || "",
                        currency:
                          item?.velocity?.max_transaction_limit?.currency || "",
                      },
                      min_transaction_limit: {
                        allow_min_transaction_limit:
                          item?.velocity?.min_transaction_limit
                            ?.allow_min_transaction_limit || false,
                        limit_amount:
                          item?.velocity?.min_transaction_limit?.limit_amount || "",
                        currency:
                          item?.velocity?.min_transaction_limit?.currency || "",
                      },
                      max_transaction_period: {
                        allow_max_transaction_period:
                          item?.velocity?.max_transaction_period
                            ?.allow_max_transaction_period || false,
                        limit_amount:
                          item?.velocity?.max_transaction_period?.limit_amount ||
                          "",
                        currency:
                          item?.velocity?.max_transaction_period?.currency || "",
                        period:
                          item?.velocity?.max_transaction_period?.period || "",
                      },
                      retry_time: {
                        allow_retry_time:
                          item?.velocity?.retry_time?.allow_retry_time || false,
                        limit_minutes:
                          item?.velocity?.retry_time?.limit_minutes || "",
                      },
                      max_attempts_day: {
                        allow_max_attempts_day:
                          item?.velocity?.max_attempts_day
                            ?.allow_max_attempts_day || false,
                        limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                      },
                      max_attempts_week: {
                        allow_max_attempts_week:
                          item?.velocity?.max_attempts_week
                            ?.allow_max_attempts_week || false,
                        limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                      },
                      max_attempts_month: {
                        allow_max_attempts_month:
                          item?.velocity?.max_attempts_month
                            ?.allow_max_attempts_month || false,
                        limit_no:
                          item?.velocity?.max_attempts_month?.limit_no || "",
                      },
                      max_attempts_total: {
                        allow_max_attempts_total:
                          item?.velocity?.max_attempts_total
                            ?.allow_max_attempts_total || false,
                        limit_no:
                          item?.velocity?.max_attempts_total?.limit_no || "",
                      },
                    },
    
                    api_id_test: item?.inputs?.api_id_test
                      ? item?.inputs?.api_id_test
                      : saved_form_inputs_test?.api_id_test,
                    api_id_live: item?.inputs?.api_id_live
                      ? item?.inputs?.api_id_live
                      : "",
                    api_key_test: item?.inputs?.api_key_test
                      ? item?.inputs?.api_key_test
                      : saved_form_inputs_test?.api_key_test,
                    api_key_live: item?.inputs?.api_key_live
                      ? item?.inputs?.api_key_live
                      : "", 
                    client_card_types:
                      // item?.reserved_pricing?.client_card_types || [],
                      item?.reserved_pricing?.client_card_types?.map((m: any) => {
                        return {
                          card_name: m?.card_name,
                          percentage_fee: m?.percentage_fee,
                          fixed_fee: m?.fixed_fee,
                          currency_code: m?.currency_code,
                          allow_card: m?.allow_card,
    
                          cost_percentage_fee:
                            item?.cost?.client_card_types?.filter(
                              (f: any) => f?.card_name == m?.card_name
                            )[0]?.percentage_fee || "",
                          cost_fixed_fee:
                            item?.cost?.client_card_types?.filter(
                              (f: any) => f?.card_name == m?.card_name
                            )[0]?.fixed_fee || "",
                          cost_currency_code: item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.currency_code,
                        };
                      }),
                    // additional_fees: item?.reserved_pricing?.additional_fees || {},
                    additional_fees: {
                      chargeback_fees:
                        item?.reserved_pricing?.additional_fees?.chargeback_fees,
                      dispute_fees:
                        item?.reserved_pricing?.additional_fees?.dispute_fees,
                      reserve_fees:
                        item?.reserved_pricing?.additional_fees?.reserve_fees,
                      refunded_tnx_fees:
                        item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                      declined_tnx_fees:
                        item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                      cost_chargeback_fees:
                        item?.cost?.additional_fees?.chargeback_fees,
                      cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                      cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                      cost_refunded_tnx_fees:
                        item?.cost?.additional_fees?.refunded_tnx_fees,
                      cost_declined_tnx_fees:
                        item?.cost?.additional_fees?.declined_tnx_fees,
                    },
                    cardTypes: saved_form_CardTypes,
                    payout_fees: item?.payout_fees || {},
                    wallet_ids: item?.wallet_ids || [],
                    ewallet_New_addressess: [],
                    disCard_walletes: [],
                    fess_conditions: {
                      apm: saved_form_fees_details_test[0]?.apm,
                      payout: saved_form_fees_details_test[0]?.payout,
                      payin: saved_form_fees_details_test[0]?.payin,
                    },
                    payment_methods: item?.payment_method
                      ? item?.payment_method?.filterPaymentMethod
                      : convertIntoArray(saved_form_fees_details_test),
                    gateway_method_fees:
                      // item?.reserved_pricing?.gateway_method_fees || {},
                      {
                        apm_fees:
                          item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                        cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                        payout_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                        cost_payout_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                        payin_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                        cost_payin_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                      },
                    type: type,
                    whitelist_enabled: item?.whitelist_enabled,
                  }
                  : type === "G2Pay"
                ? {
                    ...item?.inputs,
                    is_live: value === undefined ? false : value,
                    is_active: is_active,
                    test_endpoint: item?.test_endpoint
                      ? item?.test_endpoint
                      : saved_form_fees_details_test[0]?.test_endpoint,
                    live_endpoint: item?.live_endpoint
                      ? item?.live_endpoint
                      : saved_form_fees_details_test[0]?.live_endpoint,
                      advanced_avs : {
                        validate_address  : item?.advanced_avs?.validate_address || false,
                        validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                        validate_email  : item?.advanced_avs?.validate_email || false,
                        validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                      },
                    velocity: {
                      transaction_limit: {
                        allow_transaction_limit:
                          item?.velocity?.transaction_limit
                            ?.allow_transaction_limit || false,
                        limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                        period: item?.velocity?.transaction_limit?.period || "",
                      },
                      blocked_countries: {
                        allow_blocked_countries:
                          item?.velocity?.blocked_countries
                            ?.allow_blocked_countries || false,
                        country_code:
                          item?.velocity?.blocked_countries?.country_code || [],
                      },
                      blocked_countries_bin: {
                        allow_blocked_countries_bin:
                          item?.velocity?.blocked_countries_bin
                            ?.allow_blocked_countries_bin || false,
                        country_code:
                          item?.velocity?.blocked_countries_bin?.country_code || [],
                      },
                      max_transaction_limit: {
                        allow_max_transaction_limit:
                          item?.velocity?.max_transaction_limit
                            ?.allow_max_transaction_limit || false,
                        limit_amount:
                          item?.velocity?.max_transaction_limit?.limit_amount || "",
                        currency:
                          item?.velocity?.max_transaction_limit?.currency || "",
                      },
                      min_transaction_limit: {
                        allow_min_transaction_limit:
                          item?.velocity?.min_transaction_limit
                            ?.allow_min_transaction_limit || false,
                        limit_amount:
                          item?.velocity?.min_transaction_limit?.limit_amount || "",
                        currency:
                          item?.velocity?.min_transaction_limit?.currency || "",
                      },
                      max_transaction_period: {
                        allow_max_transaction_period:
                          item?.velocity?.max_transaction_period
                            ?.allow_max_transaction_period || false,
                        limit_amount:
                          item?.velocity?.max_transaction_period?.limit_amount ||
                          "",
                        currency:
                          item?.velocity?.max_transaction_period?.currency || "",
                        period:
                          item?.velocity?.max_transaction_period?.period || "",
                      },
                      retry_time: {
                        allow_retry_time:
                          item?.velocity?.retry_time?.allow_retry_time || false,
                        limit_minutes:
                          item?.velocity?.retry_time?.limit_minutes || "",
                      },
                      max_attempts_day: {
                        allow_max_attempts_day:
                          item?.velocity?.max_attempts_day
                            ?.allow_max_attempts_day || false,
                        limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                      },
                      max_attempts_week: {
                        allow_max_attempts_week:
                          item?.velocity?.max_attempts_week
                            ?.allow_max_attempts_week || false,
                        limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                      },
                      max_attempts_month: {
                        allow_max_attempts_month:
                          item?.velocity?.max_attempts_month
                            ?.allow_max_attempts_month || false,
                        limit_no:
                          item?.velocity?.max_attempts_month?.limit_no || "",
                      },
                      max_attempts_total: {
                        allow_max_attempts_total:
                          item?.velocity?.max_attempts_total
                            ?.allow_max_attempts_total || false,
                        limit_no:
                          item?.velocity?.max_attempts_total?.limit_no || "",
                      },
                    },
    
                    signing_key_test: item?.inputs?.signing_key_test
                      ? item?.inputs?.signing_key_test
                      : saved_form_inputs_test?.signing_key_test,
                    signing_key_live: item?.inputs?.signing_key_live
                      ? item?.inputs?.signing_key_live
                      : "",
                    api_key_test: item?.inputs?.api_key_test
                      ? item?.inputs?.api_key_test
                      : saved_form_inputs_test?.api_key_test,
                    api_key_live: item?.inputs?.api_key_live
                      ? item?.inputs?.api_key_live
                      : "", 
                    client_card_types:
                      // item?.reserved_pricing?.client_card_types || [],
                      item?.reserved_pricing?.client_card_types?.map((m: any) => {
                        return {
                          card_name: m?.card_name,
                          percentage_fee: m?.percentage_fee,
                          fixed_fee: m?.fixed_fee,
                          currency_code: m?.currency_code,
                          allow_card: m?.allow_card,
    
                          cost_percentage_fee:
                            item?.cost?.client_card_types?.filter(
                              (f: any) => f?.card_name == m?.card_name
                            )[0]?.percentage_fee || "",
                          cost_fixed_fee:
                            item?.cost?.client_card_types?.filter(
                              (f: any) => f?.card_name == m?.card_name
                            )[0]?.fixed_fee || "",
                          cost_currency_code: item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.currency_code,
                        };
                      }),
                    // additional_fees: item?.reserved_pricing?.additional_fees || {},
                    additional_fees: {
                      chargeback_fees:
                        item?.reserved_pricing?.additional_fees?.chargeback_fees,
                      dispute_fees:
                        item?.reserved_pricing?.additional_fees?.dispute_fees,
                      reserve_fees:
                        item?.reserved_pricing?.additional_fees?.reserve_fees,
                      refunded_tnx_fees:
                        item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                      declined_tnx_fees:
                        item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                      cost_chargeback_fees:
                        item?.cost?.additional_fees?.chargeback_fees,
                      cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                      cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                      cost_refunded_tnx_fees:
                        item?.cost?.additional_fees?.refunded_tnx_fees,
                      cost_declined_tnx_fees:
                        item?.cost?.additional_fees?.declined_tnx_fees,
                    },
                    cardTypes: saved_form_CardTypes,
                    payout_fees: item?.payout_fees || {},
                    wallet_ids: item?.wallet_ids || [],
                    ewallet_New_addressess: [],
                    disCard_walletes: [],
                    fess_conditions: {
                      apm: saved_form_fees_details_test[0]?.apm,
                      payout: saved_form_fees_details_test[0]?.payout,
                      payin: saved_form_fees_details_test[0]?.payin,
                    },
                    payment_methods: item?.payment_method
                      ? item?.payment_method?.filterPaymentMethod
                      : convertIntoArray(saved_form_fees_details_test),
                    gateway_method_fees:
                      // item?.reserved_pricing?.gateway_method_fees || {},
                      {
                        apm_fees:
                          item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                        cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                        payout_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                        cost_payout_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                        payin_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                        cost_payin_fees:
                          item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                      },
                    type: type,
                    whitelist_enabled: item?.whitelist_enabled,
                  }
                : type === "Moneto"
              ? {
                  ...item?.inputs,
                  is_live: value === undefined ? false : value,
                  is_active: is_active,
                  test_endpoint: item?.test_endpoint
                    ? item?.test_endpoint
                    : saved_form_fees_details_test[0]?.test_endpoint,
                  live_endpoint: item?.live_endpoint
                    ? item?.live_endpoint
                    : saved_form_fees_details_test[0]?.live_endpoint,
                    advanced_avs : {
                      validate_address  : item?.advanced_avs?.validate_address || false,
                      validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                      validate_email  : item?.advanced_avs?.validate_email || false,
                      validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                    },
                  velocity: {
                    transaction_limit: {
                      allow_transaction_limit:
                        item?.velocity?.transaction_limit
                          ?.allow_transaction_limit || false,
                      limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                      period: item?.velocity?.transaction_limit?.period || "",
                    },
                    blocked_countries: {
                      allow_blocked_countries:
                        item?.velocity?.blocked_countries
                          ?.allow_blocked_countries || false,
                      country_code:
                        item?.velocity?.blocked_countries?.country_code || [],
                    },
                    blocked_countries_bin: {
                      allow_blocked_countries_bin:
                        item?.velocity?.blocked_countries_bin
                          ?.allow_blocked_countries_bin || false,
                      country_code:
                        item?.velocity?.blocked_countries_bin?.country_code || [],
                    },
                    max_transaction_limit: {
                      allow_max_transaction_limit:
                        item?.velocity?.max_transaction_limit
                          ?.allow_max_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.max_transaction_limit?.limit_amount || "",
                      currency:
                        item?.velocity?.max_transaction_limit?.currency || "",
                    },
                    min_transaction_limit: {
                      allow_min_transaction_limit:
                        item?.velocity?.min_transaction_limit
                          ?.allow_min_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.min_transaction_limit?.limit_amount || "",
                      currency:
                        item?.velocity?.min_transaction_limit?.currency || "",
                    },
                    max_transaction_period: {
                      allow_max_transaction_period:
                        item?.velocity?.max_transaction_period
                          ?.allow_max_transaction_period || false,
                      limit_amount:
                        item?.velocity?.max_transaction_period?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_period?.currency || "",
                      period:
                        item?.velocity?.max_transaction_period?.period || "",
                    },
                    retry_time: {
                      allow_retry_time:
                        item?.velocity?.retry_time?.allow_retry_time || false,
                      limit_minutes:
                        item?.velocity?.retry_time?.limit_minutes || "",
                    },
                    max_attempts_day: {
                      allow_max_attempts_day:
                        item?.velocity?.max_attempts_day
                          ?.allow_max_attempts_day || false,
                      limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                    },
                    max_attempts_week: {
                      allow_max_attempts_week:
                        item?.velocity?.max_attempts_week
                          ?.allow_max_attempts_week || false,
                      limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                    },
                    max_attempts_month: {
                      allow_max_attempts_month:
                        item?.velocity?.max_attempts_month
                          ?.allow_max_attempts_month || false,
                      limit_no:
                        item?.velocity?.max_attempts_month?.limit_no || "",
                    },
                    max_attempts_total: {
                      allow_max_attempts_total:
                        item?.velocity?.max_attempts_total
                          ?.allow_max_attempts_total || false,
                      limit_no:
                        item?.velocity?.max_attempts_total?.limit_no || "",
                    },
                  },
  
                  merchant_secret_key_test: item?.inputs?.merchant_secret_key_test
                    ? item?.inputs?.merchant_secret_key_test
                    : saved_form_inputs_test?.merchant_secret_key_test,
                  merchant_secret_key_live: item?.inputs?.merchant_secret_key_live
                    ? item?.inputs?.merchant_secret_key_live
                    : "",
                  merchant_id_test: item?.inputs?.merchant_id_test
                    ? item?.inputs?.merchant_id_test
                    : saved_form_inputs_test?.merchant_id_test,
                  merchant_id_live: item?.inputs?.merchant_id_live
                    ? item?.inputs?.merchant_id_live
                    : "", 
                  client_card_types:
                    // item?.reserved_pricing?.client_card_types || [],
                    item?.reserved_pricing?.client_card_types?.map((m: any) => {
                      return {
                        card_name: m?.card_name,
                        percentage_fee: m?.percentage_fee,
                        fixed_fee: m?.fixed_fee,
                        currency_code: m?.currency_code,
                        allow_card: m?.allow_card,
  
                        cost_percentage_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.percentage_fee || "",
                        cost_fixed_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.fixed_fee || "",
                        cost_currency_code: item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.currency_code,
                      };
                    }),
                  // additional_fees: item?.reserved_pricing?.additional_fees || {},
                  additional_fees: {
                    chargeback_fees:
                      item?.reserved_pricing?.additional_fees?.chargeback_fees,
                    dispute_fees:
                      item?.reserved_pricing?.additional_fees?.dispute_fees,
                    reserve_fees:
                      item?.reserved_pricing?.additional_fees?.reserve_fees,
                    refunded_tnx_fees:
                      item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                    declined_tnx_fees:
                      item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                    cost_chargeback_fees:
                      item?.cost?.additional_fees?.chargeback_fees,
                    cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                    cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                    cost_refunded_tnx_fees:
                      item?.cost?.additional_fees?.refunded_tnx_fees,
                    cost_declined_tnx_fees:
                      item?.cost?.additional_fees?.declined_tnx_fees,
                  },
                  cardTypes: saved_form_CardTypes,
                  payout_fees: item?.payout_fees || {},
                  wallet_ids: item?.wallet_ids || [],
                  ewallet_New_addressess: [],
                  disCard_walletes: [],
                  fess_conditions: {
                    apm: saved_form_fees_details_test[0]?.apm,
                    payout: saved_form_fees_details_test[0]?.payout,
                    payin: saved_form_fees_details_test[0]?.payin,
                  },
                  payment_methods: item?.payment_method
                    ? item?.payment_method?.filterPaymentMethod
                    : convertIntoArray(saved_form_fees_details_test),
                  gateway_method_fees:
                    // item?.reserved_pricing?.gateway_method_fees || {},
                    {
                      apm_fees:
                        item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                      cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                      payout_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                      cost_payout_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                      payin_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                      cost_payin_fees:
                        item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    },
                  type: type,
                  whitelist_enabled: item?.whitelist_enabled,
                }
            : type === "Re-set"
            ? {
                ...item?.inputs,
                is_live: value === undefined ? false : value,
                is_active: is_active,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                  advanced_avs : {
                    validate_address  : item?.advanced_avs?.validate_address || false,
                    validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                    validate_email  : item?.advanced_avs?.validate_email || false,
                    validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                  },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                secretKey_test: item?.inputs?.secretKey_test
                  ? item?.inputs?.secretKey_test
                  : saved_form_inputs_test?.merchant_id_test,
                secretKey_live: item?.inputs?.secretKey_live
                  ? item?.inputs?.secretKey_live
                  : "",
                brand_id_test: item?.inputs?.brand_id_test
                  ? item?.inputs?.brand_id_test
                  : saved_form_inputs_test?.merchant_id_test,
                brand_id_live: item?.inputs?.brand_id_live
                  ? item?.inputs?.brand_id_live
                  : "",
                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Memphis"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                commerce_id_test: item?.inputs?.commerce_id_test
                  ? item?.inputs?.commerce_id_test
                  : saved_form_inputs_test?.commerce_id_test,
                commerce_id_live: item?.inputs?.commerce_id_live
                  ? item?.inputs?.commerce_id_live
                  : "",
                commerce_name_test: item?.inputs?.commerce_name_test
                  ? item?.inputs?.commerce_name_test
                  : saved_form_inputs_test?.commerce_name_test,
                commerce_name_live: item?.inputs?.commerce_name_live
                  ? item?.inputs?.commerce_name_live
                  : "",
                tkr_test: item?.inputs?.tkr_test
                  ? item?.inputs?.tkr_test
                  : saved_form_inputs_test?.tkr_test,
                tkr_live: item?.inputs?.tkr_live ? item?.inputs?.tkr_live : "",

                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Raypd"
            ? {
                ...item?.inputs,
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                secret_key_test: item?.inputs?.secret_key_test
                  ? item?.inputs?.secret_key_test
                  : saved_form_inputs_test?.secret_key_test,
                secret_key_live: item?.inputs?.secret_key_live
                  ? item?.inputs?.secret_key_live
                  : "",
                mid_country_test: item?.inputs?.mid_country_test
                  ? item?.inputs?.mid_country_test
                  : saved_form_inputs_test?.mid_country_test || "",
                mid_country_live: item?.inputs?.mid_country_live
                  ? item?.inputs?.mid_country_live
                  : "",
                access_key_test: item?.inputs?.access_key_test
                  ? item?.inputs?.access_key_test
                  : saved_form_inputs_test?.access_key_test,
                access_key_live: item?.inputs?.access_key_live
                  ? item?.inputs?.access_key_live
                  : "",
                client_card_types:
                  // item?.reserved_pricing?.client_card_types || [],
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Scipiopay"
            ? {
                ...item?.inputs,
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                shop_id_test: item?.inputs?.shop_id_test
                  ? item?.inputs?.shop_id_test
                  : saved_form_inputs_test?.shop_id_test,
                shop_id_live: item?.inputs?.shop_id_live
                  ? item?.inputs?.shop_id_live
                  : "",
                shop_secret_key_test: item?.inputs?.shop_secret_key_test
                  ? item?.inputs?.shop_secret_key_test
                  : saved_form_inputs_test?.shop_secret_key_test,
                shop_secret_key_live: item?.inputs?.shop_secret_key_live
                  ? item?.inputs?.shop_secret_key_live
                  : "",
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                // additional_fees: item?.reserved_pricing?.additional_fees || {},
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees: {
                  apm_fees:
                    item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                  cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                  payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  cost_payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  cost_payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Platy"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },

                api_key_test: item?.inputs?.api_key_test
                  ? item?.inputs?.api_key_test
                  : saved_form_inputs_test?.api_key_test,
                api_key_live: item?.inputs?.api_key_live
                  ? item?.inputs?.api_key_live
                  : "",

                password_test: item?.inputs?.password_test
                  ? item?.inputs?.password_test
                  : saved_form_inputs_test?.password_test,
                password_live: item?.inputs?.password_live
                  ? item?.inputs?.password_live
                  : "",

                platy_api_key_test: item?.inputs?.platy_api_key_test
                  ? item?.inputs?.platy_api_key_test
                  : saved_form_inputs_test?.platy_api_key_test,
                platy_api_key_live: item?.inputs?.platy_api_key_live
                  ? item?.inputs?.platy_api_key_live
                  : "",

                platy_secret_key_test: item?.inputs?.platy_secret_key_test
                  ? item?.inputs?.platy_secret_key_test
                  : saved_form_inputs_test?.platy_secret_key_test,
                platy_secret_key_live: item?.inputs?.platy_secret_key_live
                  ? item?.inputs?.platy_secret_key_live
                  : "",

                username_test: item?.inputs?.username_test
                  ? item?.inputs?.username_test
                  : saved_form_inputs_test?.platy_secret_key_test,
                username_live: item?.inputs?.username_live
                  ? item?.inputs?.username_live
                  : "",

                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Platy 2DS"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },

                api_key_test: item?.inputs?.api_key_test
                  ? item?.inputs?.api_key_test
                  : saved_form_inputs_test?.api_key_test,
                api_key_live: item?.inputs?.api_key_live
                  ? item?.inputs?.api_key_live
                  : "",

                password_test: item?.inputs?.password_test
                  ? item?.inputs?.password_test
                  : saved_form_inputs_test?.password_test,
                password_live: item?.inputs?.password_live
                  ? item?.inputs?.password_live
                  : "",

                platy_api_key_test: item?.inputs?.platy_api_key_test
                  ? item?.inputs?.platy_api_key_test
                  : saved_form_inputs_test?.platy_api_key_test,
                platy_api_key_live: item?.inputs?.platy_api_key_live
                  ? item?.inputs?.platy_api_key_live
                  : "",

                platy_secret_key_test: item?.inputs?.platy_secret_key_test
                  ? item?.inputs?.platy_secret_key_test
                  : saved_form_inputs_test?.platy_secret_key_test,
                platy_secret_key_live: item?.inputs?.platy_secret_key_live
                  ? item?.inputs?.platy_secret_key_live
                  : "",

                username_test: item?.inputs?.username_test
                  ? item?.inputs?.username_test
                  : saved_form_inputs_test?.platy_secret_key_test,
                username_live: item?.inputs?.username_live
                  ? item?.inputs?.username_live
                  : "",

                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
              : type === "3xpay"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },

                api_key_test: item?.inputs?.api_key_test
                  ? item?.inputs?.api_key_test
                  : saved_form_inputs_test?.api_key_test,
                api_key_live: item?.inputs?.api_key_live
                  ? item?.inputs?.api_key_live
                  : "",

                api_secret_test: item?.inputs?.api_secret_test
                  ? item?.inputs?.api_secret_test
                  : saved_form_inputs_test?.api_secret_test,
                api_secret_live: item?.inputs?.api_secret_live
                  ? item?.inputs?.api_secret_live
                  : "",

                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
              : type === "Platy 3DS"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },

                api_key_test: item?.inputs?.api_key_test
                  ? item?.inputs?.api_key_test
                  : saved_form_inputs_test?.api_key_test,
                api_key_live: item?.inputs?.api_key_live
                  ? item?.inputs?.api_key_live
                  : "",

                password_test: item?.inputs?.password_test
                  ? item?.inputs?.password_test
                  : saved_form_inputs_test?.password_test,
                password_live: item?.inputs?.password_live
                  ? item?.inputs?.password_live
                  : "",

                platy_api_key_test: item?.inputs?.platy_api_key_test
                  ? item?.inputs?.platy_api_key_test
                  : saved_form_inputs_test?.platy_api_key_test,
                platy_api_key_live: item?.inputs?.platy_api_key_live
                  ? item?.inputs?.platy_api_key_live
                  : "",

                platy_secret_key_test: item?.inputs?.platy_secret_key_test
                  ? item?.inputs?.platy_secret_key_test
                  : saved_form_inputs_test?.platy_secret_key_test,
                platy_secret_key_live: item?.inputs?.platy_secret_key_live
                  ? item?.inputs?.platy_secret_key_live
                  : "",

                username_test: item?.inputs?.username_test
                  ? item?.inputs?.username_test
                  : saved_form_inputs_test?.platy_secret_key_test,
                username_live: item?.inputs?.username_live
                  ? item?.inputs?.username_live
                  : "",

                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "MIT"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                id_branch_test: item?.inputs?.id_branch_test
                  ? item?.inputs?.id_branch_test
                  : saved_form_inputs_test?.id_branch_test,
                id_branch_live: item?.inputs?.id_branch_live
                  ? item?.inputs?.id_branch_live
                  : "",
                id_company_test: item?.inputs?.id_company_test
                  ? item?.inputs?.id_company_test
                  : saved_form_inputs_test?.id_company_test,
                id_company_live: item?.inputs?.id_company_live
                  ? item?.inputs?.id_company_live
                  : "",
                merchant_id_test: item?.inputs?.merchant_id_test
                  ? item?.inputs?.merchant_id_test
                  : saved_form_inputs_test?.id_company_test,
                merchant_id_live: item?.inputs?.merchant_id_live
                  ? item?.inputs?.merchant_id_live
                  : "",
                password_test: item?.inputs?.password_test
                  ? item?.inputs?.password_test
                  : saved_form_inputs_test?.id_company_test,
                password_live: item?.inputs?.password_live
                  ? item?.inputs?.password_live
                  : "",
                user_code_test: item?.inputs?.user_code_test
                  ? item?.inputs?.user_code_test
                  : saved_form_inputs_test?.id_company_test,
                user_code_live: item?.inputs?.user_code_live
                  ? item?.inputs?.user_code_live
                  : "",
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Betapay"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                api_token_test: item?.inputs?.api_token_test
                  ? item?.inputs?.api_token_test
                  : saved_form_inputs_test?.api_token_test,
                api_token_live: item?.inputs?.api_token_live
                  ? item?.inputs?.api_token_live
                  : "",
                merchant_id_test: item?.inputs?.merchant_id_test
                  ? item?.inputs?.merchant_id_test
                  : saved_form_inputs_test?.merchant_id_test,
                merchant_id_live: item?.inputs?.merchant_id_live
                  ? item?.inputs?.merchant_id_live
                  : "",
                terminal_id_test: item?.inputs?.terminal_id_test
                  ? item?.inputs?.terminal_id_test
                  : saved_form_inputs_test?.terminal_id_test,
                terminal_id_live: item?.inputs?.terminal_id_live
                  ? item?.inputs?.terminal_id_live
                  : "",
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees: {
                  apm_fees:
                    item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                  cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                  payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  cost_payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  cost_payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Aurea Via"
            ? {
                ...item?.inputs,
                is_active: is_active,
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                companyNum_test: item?.inputs?.companyNum_test
                  ? item?.inputs?.companyNum_test
                  : saved_form_inputs_test?.companyNum_test,
                companyNum_live: item?.inputs?.companyNum_live
                  ? item?.inputs?.companyNum_live
                  : "",
                personalHashkey_test: item?.inputs?.personalHashkey_test
                  ? item?.inputs?.personalHashkey_test
                  : saved_form_inputs_test?.personalHashkey_test,
                personalHashkey_live: item?.inputs?.personalHashkey_live
                  ? item?.inputs?.personalHashkey_live
                  : "",

                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Kasha"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,
                api_key_test: item?.inputs?.api_key_test
                  ? item?.inputs?.api_key_test
                  : saved_form_inputs_test?.api_key_test,
                api_key_live: item?.inputs?.api_key_live
                  ? item?.inputs?.api_key_live
                  : "",

                merchant_domain_test: item?.inputs?.merchant_domain_test
                  ? item?.inputs?.merchant_domain_test
                  : saved_form_inputs_test?.merchant_domain_test,
                merchant_domain_live: item?.inputs?.merchant_domain_live
                  ? item?.inputs?.merchant_domain_live
                  : "",
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees: {
                  apm_fees:
                    item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                  cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                  payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  cost_payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  cost_payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Linx"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                client_id_test: item?.inputs?.client_id_test
                  ? item?.inputs?.client_id_test
                  : saved_form_inputs_test?.client_id_test,
                client_id_live: item?.inputs?.client_id_live
                  ? item?.inputs?.client_id_live
                  : "",

                client_secret_test: item?.inputs?.client_secret_test
                  ? item?.inputs?.client_secret_test
                  : saved_form_inputs_test?.client_secret_test,
                client_secret_live: item?.inputs?.client_secret_live
                  ? item?.inputs?.client_secret_live
                  : "",
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees: {
                  apm_fees:
                    item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                  cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                  payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  cost_payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  cost_payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Supefina"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                merchant_id_test: item?.inputs?.merchant_id_test
                  ? item?.inputs?.merchant_id_test
                  : saved_form_inputs_test?.merchant_id_test,
                merchant_id_live: item?.inputs?.merchant_id_live
                  ? item?.inputs?.merchant_id_live
                  : "",
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees: {
                  apm_fees:
                    item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                  cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                  payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  cost_payout_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                  payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  cost_payin_fees:
                    item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Masterpay"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                merchant_private_key_test: item?.inputs
                  ?.merchant_private_key_test
                  ? item?.inputs?.merchant_private_key_test
                  : saved_form_inputs_test?.merchant_private_key_test,
                merchant_private_key_live: item?.inputs
                  ?.merchant_private_key_live
                  ? item?.inputs?.merchant_private_key_live
                  : "",
                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
            : type === "Neonpay"
            ? {
                ...item?.inputs,
                is_active: is_active,
                advanced_avs : {
                  validate_address  : item?.advanced_avs?.validate_address || false,
                  validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                  validate_email  : item?.advanced_avs?.validate_email || false,
                  validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                },
                velocity: {
                  transaction_limit: {
                    allow_transaction_limit:
                      item?.velocity?.transaction_limit
                        ?.allow_transaction_limit || false,
                    limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                    period: item?.velocity?.transaction_limit?.period || "",
                  },
                  blocked_countries: {
                    allow_blocked_countries:
                      item?.velocity?.blocked_countries
                        ?.allow_blocked_countries || false,
                    country_code:
                      item?.velocity?.blocked_countries?.country_code || [],
                  },
                  blocked_countries_bin: {
                    allow_blocked_countries_bin:
                      item?.velocity?.blocked_countries_bin
                        ?.allow_blocked_countries_bin || false,
                    country_code:
                      item?.velocity?.blocked_countries_bin?.country_code || [],
                  },
                  max_transaction_limit: {
                    allow_max_transaction_limit:
                      item?.velocity?.max_transaction_limit
                        ?.allow_max_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.max_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.max_transaction_limit?.currency || "",
                  },
                  min_transaction_limit: {
                    allow_min_transaction_limit:
                      item?.velocity?.min_transaction_limit
                        ?.allow_min_transaction_limit || false,
                    limit_amount:
                      item?.velocity?.min_transaction_limit?.limit_amount || "",
                    currency:
                      item?.velocity?.min_transaction_limit?.currency || "",
                  },
                  max_transaction_period: {
                    allow_max_transaction_period:
                      item?.velocity?.max_transaction_period
                        ?.allow_max_transaction_period || false,
                    limit_amount:
                      item?.velocity?.max_transaction_period?.limit_amount ||
                      "",
                    currency:
                      item?.velocity?.max_transaction_period?.currency || "",
                    period:
                      item?.velocity?.max_transaction_period?.period || "",
                  },
                  retry_time: {
                    allow_retry_time:
                      item?.velocity?.retry_time?.allow_retry_time || false,
                    limit_minutes:
                      item?.velocity?.retry_time?.limit_minutes || "",
                  },
                  max_attempts_day: {
                    allow_max_attempts_day:
                      item?.velocity?.max_attempts_day
                        ?.allow_max_attempts_day || false,
                    limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                  },
                  max_attempts_week: {
                    allow_max_attempts_week:
                      item?.velocity?.max_attempts_week
                        ?.allow_max_attempts_week || false,
                    limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                  },
                  max_attempts_month: {
                    allow_max_attempts_month:
                      item?.velocity?.max_attempts_month
                        ?.allow_max_attempts_month || false,
                    limit_no:
                      item?.velocity?.max_attempts_month?.limit_no || "",
                  },
                  max_attempts_total: {
                    allow_max_attempts_total:
                      item?.velocity?.max_attempts_total
                        ?.allow_max_attempts_total || false,
                    limit_no:
                      item?.velocity?.max_attempts_total?.limit_no || "",
                  },
                },

                is_live: value === undefined ? false : value,
                test_endpoint: item?.test_endpoint
                  ? item?.test_endpoint
                  : saved_form_fees_details_test[0]?.test_endpoint,
                live_endpoint: item?.live_endpoint
                  ? item?.live_endpoint
                  : saved_form_fees_details_test[0]?.live_endpoint,

                merchant_key_test: item?.inputs?.merchant_key_test
                  ? item?.inputs?.merchant_key_test
                  : saved_form_inputs_test?.merchant_key_test,
                merchant_key_live: item?.inputs?.merchant_key_live
                  ? item?.inputs?.merchant_key_live
                  : "",

                password_test: item?.inputs?.password_test
                  ? item?.inputs?.password_test
                  : saved_form_inputs_test?.password_test,
                password_live: item?.inputs?.password_live
                  ? item?.inputs?.password_live
                  : "",

                // term_url_3ds_test: item?.inputs?.term_url_3ds_test
                //   ? item?.inputs?.term_url_3ds_test
                //   : saved_form_inputs_test?.term_url_3ds_test || "",
                // term_url_3ds_live: item?.inputs?.term_url_3ds_live
                //   ? item?.inputs?.term_url_3ds_live
                  // : "",

                client_card_types:
                  item?.reserved_pricing?.client_card_types?.map((m: any) => {
                    return {
                      card_name: m?.card_name,
                      percentage_fee: m?.percentage_fee,
                      fixed_fee: m?.fixed_fee,
                      currency_code: m?.currency_code,
                      allow_card: m?.allow_card,

                      cost_percentage_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.percentage_fee || "",
                      cost_fixed_fee:
                        item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.fixed_fee || "",
                      cost_currency_code: item?.cost?.client_card_types?.filter(
                        (f: any) => f?.card_name == m?.card_name
                      )[0]?.currency_code,
                    };
                  }),
                additional_fees: {
                  chargeback_fees:
                    item?.reserved_pricing?.additional_fees?.chargeback_fees,
                  dispute_fees:
                    item?.reserved_pricing?.additional_fees?.dispute_fees,
                  reserve_fees:
                    item?.reserved_pricing?.additional_fees?.reserve_fees,
                  refunded_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                  declined_tnx_fees:
                    item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                  cost_chargeback_fees:
                    item?.cost?.additional_fees?.chargeback_fees,
                  cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                  cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                  cost_refunded_tnx_fees:
                    item?.cost?.additional_fees?.refunded_tnx_fees,
                  cost_declined_tnx_fees:
                    item?.cost?.additional_fees?.declined_tnx_fees,
                },
                cardTypes: saved_form_CardTypes,
                payout_fees: item?.payout_fees || {},
                wallet_ids: item?.wallet_ids || [],
                ewallet_New_addressess: [],
                disCard_walletes: [],
                fess_conditions: {
                  apm: saved_form_fees_details_test[0]?.apm,
                  payout: saved_form_fees_details_test[0]?.payout,
                  payin: saved_form_fees_details_test[0]?.payin,
                },
                payment_methods: item?.payment_method
                  ? item?.payment_method?.filterPaymentMethod
                  : convertIntoArray(saved_form_fees_details_test),
                gateway_method_fees:
                  // item?.reserved_pricing?.gateway_method_fees || {},
                  {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                type: type,
                whitelist_enabled: item?.whitelist_enabled,
              }
              : type === "Bm2pay"
              ? {
                  ...item?.inputs,
                  is_active: is_active,
                  advanced_avs : {
                    validate_address  : item?.advanced_avs?.validate_address || false,
                    validate_phone_number  : item?.advanced_avs?.validate_phone_number || false,
                    validate_email  : item?.advanced_avs?.validate_email || false,
                    validate_ip_address  : item?.advanced_avs?.validate_ip_address || false,
                  },
                  velocity: {
                    transaction_limit: {
                      allow_transaction_limit:
                        item?.velocity?.transaction_limit
                          ?.allow_transaction_limit || false,
                      limit_no: item?.velocity?.transaction_limit?.limit_no || "",
                      period: item?.velocity?.transaction_limit?.period || "",
                    },
                    blocked_countries: {
                      allow_blocked_countries:
                        item?.velocity?.blocked_countries
                          ?.allow_blocked_countries || false,
                      country_code:
                        item?.velocity?.blocked_countries?.country_code || [],
                    },
                    blocked_countries_bin: {
                      allow_blocked_countries_bin:
                        item?.velocity?.blocked_countries_bin
                          ?.allow_blocked_countries_bin || false,
                      country_code:
                        item?.velocity?.blocked_countries_bin?.country_code || [],
                    },
                    max_transaction_limit: {
                      allow_max_transaction_limit:
                        item?.velocity?.max_transaction_limit
                          ?.allow_max_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.max_transaction_limit?.limit_amount || "",
                      currency:
                        item?.velocity?.max_transaction_limit?.currency || "",
                    },
                    min_transaction_limit: {
                      allow_min_transaction_limit:
                        item?.velocity?.min_transaction_limit
                          ?.allow_min_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.min_transaction_limit?.limit_amount || "",
                      currency:
                        item?.velocity?.min_transaction_limit?.currency || "",
                    },
                    max_transaction_period: {
                      allow_max_transaction_period:
                        item?.velocity?.max_transaction_period
                          ?.allow_max_transaction_period || false,
                      limit_amount:
                        item?.velocity?.max_transaction_period?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_period?.currency || "",
                      period:
                        item?.velocity?.max_transaction_period?.period || "",
                    },
                    retry_time: {
                      allow_retry_time:
                        item?.velocity?.retry_time?.allow_retry_time || false,
                      limit_minutes:
                        item?.velocity?.retry_time?.limit_minutes || "",
                    },
                    max_attempts_day: {
                      allow_max_attempts_day:
                        item?.velocity?.max_attempts_day
                          ?.allow_max_attempts_day || false,
                      limit_no: item?.velocity?.max_attempts_day?.limit_no || "",
                    },
                    max_attempts_week: {
                      allow_max_attempts_week:
                        item?.velocity?.max_attempts_week
                          ?.allow_max_attempts_week || false,
                      limit_no: item?.velocity?.max_attempts_week?.limit_no || "",
                    },
                    max_attempts_month: {
                      allow_max_attempts_month:
                        item?.velocity?.max_attempts_month
                          ?.allow_max_attempts_month || false,
                      limit_no:
                        item?.velocity?.max_attempts_month?.limit_no || "",
                    },
                    max_attempts_total: {
                      allow_max_attempts_total:
                        item?.velocity?.max_attempts_total
                          ?.allow_max_attempts_total || false,
                      limit_no:
                        item?.velocity?.max_attempts_total?.limit_no || "",
                    },
                  },
  
                  is_live: value === undefined ? false : value,
                  test_endpoint: item?.test_endpoint
                    ? item?.test_endpoint
                    : saved_form_fees_details_test[0]?.test_endpoint,
                  live_endpoint: item?.live_endpoint
                    ? item?.live_endpoint
                    : saved_form_fees_details_test[0]?.live_endpoint,
  
                  client_id_test: item?.inputs?.client_id_test
                    ? item?.inputs?.client_id_test
                    : saved_form_inputs_test?.client_id_test,
                  client_id_live: item?.inputs?.client_id_live
                    ? item?.inputs?.client_id_live
                    : "",
  
                  client_secret_test: item?.inputs?.client_secret_test
                    ? item?.inputs?.client_secret_test
                    : saved_form_inputs_test?.client_secret_test,
                  client_secret_live: item?.inputs?.client_secret_live
                    ? item?.inputs?.client_secret_live
                    : "",
                  client_card_types:
                    item?.reserved_pricing?.client_card_types?.map((m: any) => {
                      return {
                        card_name: m?.card_name,
                        percentage_fee: m?.percentage_fee,
                        fixed_fee: m?.fixed_fee,
                        currency_code: m?.currency_code,
                        allow_card: m?.allow_card,
  
                        cost_percentage_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.percentage_fee || "",
                        cost_fixed_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.fixed_fee || "",
                        cost_currency_code: item?.cost?.client_card_types?.filter(
                          (f: any) => f?.card_name == m?.card_name
                        )[0]?.currency_code,
                      };
                    }),
                  additional_fees: {
                    chargeback_fees:
                      item?.reserved_pricing?.additional_fees?.chargeback_fees,
                    dispute_fees:
                      item?.reserved_pricing?.additional_fees?.dispute_fees,
                    reserve_fees:
                      item?.reserved_pricing?.additional_fees?.reserve_fees,
                    refunded_tnx_fees:
                      item?.reserved_pricing?.additional_fees?.refunded_tnx_fees,
                    declined_tnx_fees:
                      item?.reserved_pricing?.additional_fees?.declined_tnx_fees,
                    cost_chargeback_fees:
                      item?.cost?.additional_fees?.chargeback_fees,
                    cost_dispute_fees: item?.cost?.additional_fees?.dispute_fees,
                    cost_reserve_fees: item?.cost?.additional_fees?.reserve_fees,
                    cost_refunded_tnx_fees:
                      item?.cost?.additional_fees?.refunded_tnx_fees,
                    cost_declined_tnx_fees:
                      item?.cost?.additional_fees?.declined_tnx_fees,
                  },
                  cardTypes: saved_form_CardTypes,
                  payout_fees: item?.payout_fees || {},
                  wallet_ids: item?.wallet_ids || [],
                  ewallet_New_addressess: [],
                  disCard_walletes: [],
                  fess_conditions: {
                    apm: saved_form_fees_details_test[0]?.apm,
                    payout: saved_form_fees_details_test[0]?.payout,
                    payin: saved_form_fees_details_test[0]?.payin,
                  },
                  payment_methods: item?.payment_method
                    ? item?.payment_method?.filterPaymentMethod
                    : convertIntoArray(saved_form_fees_details_test),
                  gateway_method_fees: {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                  type: type,
                  whitelist_enabled: item?.whitelist_enabled,
                }
            : type === "Sipe" || type === "Blumon" || type === "Pix-Lotus"
            ? saved_form_inputs?.[0]?.client_id !== ""
              ? {
                  ...item?.inputs,
                  // is_live: value,
                  is_live: value === undefined ? false : value,
                  is_active: is_active,
                  velocity: {
                    transaction_limit: {
                      allow_transaction_limit:
                        item?.velocity?.transaction_limit
                          ?.allow_transaction_limit || false,
                      limit_no:
                        item?.velocity?.transaction_limit?.limit_no || "",
                      period: item?.velocity?.transaction_limit?.period || "",
                    },
                    blocked_countries: {
                      allow_blocked_countries:
                        item?.velocity?.blocked_countries
                          ?.allow_blocked_countries || false,
                      country_code:
                        item?.velocity?.blocked_countries?.country_code || [],
                    },
                    blocked_countries_bin: {
                      allow_blocked_countries_bin:
                        item?.velocity?.blocked_countries_bin
                          ?.allow_blocked_countries_bin || false,
                      country_code:
                        item?.velocity?.blocked_countries_bin?.country_code ||
                        [],
                    },
                    max_transaction_limit: {
                      allow_max_transaction_limit:
                        item?.velocity?.max_transaction_limit
                          ?.allow_max_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.max_transaction_limit?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_limit?.currency || "",
                    },
                    min_transaction_limit: {
                      allow_min_transaction_limit:
                        item?.velocity?.min_transaction_limit
                          ?.allow_min_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.min_transaction_limit?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.min_transaction_limit?.currency || "",
                    },
                    max_transaction_period: {
                      allow_max_transaction_period:
                        item?.velocity?.max_transaction_period
                          ?.allow_max_transaction_period || false,
                      limit_amount:
                        item?.velocity?.max_transaction_period?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_period?.currency || "",
                      period:
                        item?.velocity?.max_transaction_period?.period || "",
                    },
                    retry_time: {
                      allow_retry_time:
                        item?.velocity?.retry_time?.allow_retry_time || false,
                      limit_minutes:
                        item?.velocity?.retry_time?.limit_minutes || "",
                    },
                    max_attempts_day: {
                      allow_max_attempts_day:
                        item?.velocity?.max_attempts_day
                          ?.allow_max_attempts_day || false,
                      limit_no:
                        item?.velocity?.max_attempts_day?.limit_no || "",
                    },
                    max_attempts_week: {
                      allow_max_attempts_week:
                        item?.velocity?.max_attempts_week
                          ?.allow_max_attempts_week || false,
                      limit_no:
                        item?.velocity?.max_attempts_week?.limit_no || "",
                    },
                    max_attempts_month: {
                      allow_max_attempts_month:
                        item?.velocity?.max_attempts_month
                          ?.allow_max_attempts_month || false,
                      limit_no:
                        item?.velocity?.max_attempts_month?.limit_no || "",
                    },
                    max_attempts_total: {
                      allow_max_attempts_total:
                        item?.velocity?.max_attempts_total
                          ?.allow_max_attempts_total || false,
                      limit_no:
                        item?.velocity?.max_attempts_total?.limit_no || "",
                    },
                  },

                  client_card_types:
                    item?.reserved_pricing?.client_card_types?.map((m: any) => {
                      return {
                        card_name: m?.card_name,
                        percentage_fee: m?.percentage_fee,
                        fixed_fee: m?.fixed_fee,
                        currency_code: m?.currency_code,
                        allow_card: m?.allow_card,
                        cost_percentage_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.percentage_fee || "",
                        cost_fixed_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.fixed_fee || "",
                        cost_currency_code:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.currency_code,
                      };
                    }),

                  test_endpoint: item?.test_endpoint
                    ? item?.test_endpoint
                    : saved_form_fees_details_test[0]?.test_endpoint,
                  live_endpoint: item?.live_endpoint
                    ? item?.live_endpoint
                    : saved_form_fees_details_test[0]?.live_endpoint,
                  additional_fees: {
                    chargeback_fees:
                      item?.reserved_pricing?.additional_fees?.chargeback_fees,
                    dispute_fees:
                      item?.reserved_pricing?.additional_fees?.dispute_fees,
                    reserve_fees:
                      item?.reserved_pricing?.additional_fees?.reserve_fees,
                    refunded_tnx_fees:
                      item?.reserved_pricing?.additional_fees
                        ?.refunded_tnx_fees,
                    declined_tnx_fees:
                      item?.reserved_pricing?.additional_fees
                        ?.declined_tnx_fees,
                    cost_chargeback_fees:
                      item?.cost?.additional_fees?.chargeback_fees,
                    cost_dispute_fees:
                      item?.cost?.additional_fees?.dispute_fees,
                    cost_reserve_fees:
                      item?.cost?.additional_fees?.reserve_fees,
                    cost_refunded_tnx_fees:
                      item?.cost?.additional_fees?.refunded_tnx_fees,
                    cost_declined_tnx_fees:
                      item?.cost?.additional_fees?.declined_tnx_fees,
                  },
                  cardTypes: saved_form_CardTypes,
                  payout_fees: item?.payout_fees || {},
                  wallet_ids: item?.wallet_ids || [],
                  ewallet_New_addressess: [],
                  disCard_walletes: [],
                  fess_conditions: {
                    apm: saved_form_fees_details_test[0]?.apm,
                    payout: saved_form_fees_details_test[0]?.payout,
                    payin: saved_form_fees_details_test[0]?.payin,
                  },
                  payment_methods: convertIntoArray(
                    saved_form_fees_details_test
                  ),
                  gateway_method_fees: {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                  type: type,
                  whitelist_enabled: item?.whitelist_enabled,
                }
              : {
                  ...item?.inputs,
                  // is_live: value,
                  is_live: value === undefined ? false : value,
                  is_active: is_active,
                  velocity: {
                    transaction_limit: {
                      allow_transaction_limit:
                        item?.velocity?.transaction_limit
                          ?.allow_transaction_limit || false,
                      limit_no:
                        item?.velocity?.transaction_limit?.limit_no || "",
                      period: item?.velocity?.transaction_limit?.period || "",
                    },
                    blocked_countries: {
                      allow_blocked_countries:
                        item?.velocity?.blocked_countries
                          ?.allow_blocked_countries || false,
                      country_code:
                        item?.velocity?.blocked_countries?.country_code || [],
                    },
                    blocked_countries_bin: {
                      allow_blocked_countries_bin:
                        item?.velocity?.blocked_countries_bin
                          ?.allow_blocked_countries_bin || false,
                      country_code:
                        item?.velocity?.blocked_countries_bin?.country_code ||
                        [],
                    },
                    max_transaction_limit: {
                      allow_max_transaction_limit:
                        item?.velocity?.max_transaction_limit
                          ?.allow_max_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.max_transaction_limit?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_limit?.currency || "",
                    },
                    min_transaction_limit: {
                      allow_min_transaction_limit:
                        item?.velocity?.min_transaction_limit
                          ?.allow_min_transaction_limit || false,
                      limit_amount:
                        item?.velocity?.min_transaction_limit?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.min_transaction_limit?.currency || "",
                    },
                    max_transaction_period: {
                      allow_max_transaction_period:
                        item?.velocity?.max_transaction_period
                          ?.allow_max_transaction_period || false,
                      limit_amount:
                        item?.velocity?.max_transaction_period?.limit_amount ||
                        "",
                      currency:
                        item?.velocity?.max_transaction_period?.currency || "",
                      period:
                        item?.velocity?.max_transaction_period?.period || "",
                    },
                    retry_time: {
                      allow_retry_time:
                        item?.velocity?.retry_time?.allow_retry_time || false,
                      limit_minutes:
                        item?.velocity?.retry_time?.limit_minutes || "",
                    },
                    max_attempts_day: {
                      allow_max_attempts_day:
                        item?.velocity?.max_attempts_day
                          ?.allow_max_attempts_day || false,
                      limit_no:
                        item?.velocity?.max_attempts_day?.limit_no || "",
                    },
                    max_attempts_week: {
                      allow_max_attempts_week:
                        item?.velocity?.max_attempts_week
                          ?.allow_max_attempts_week || false,
                      limit_no:
                        item?.velocity?.max_attempts_week?.limit_no || "",
                    },
                    max_attempts_month: {
                      allow_max_attempts_month:
                        item?.velocity?.max_attempts_month
                          ?.allow_max_attempts_month || false,
                      limit_no:
                        item?.velocity?.max_attempts_month?.limit_no || "",
                    },
                    max_attempts_total: {
                      allow_max_attempts_total:
                        item?.velocity?.max_attempts_total
                          ?.allow_max_attempts_total || false,
                      limit_no:
                        item?.velocity?.max_attempts_total?.limit_no || "",
                    },
                  },

                  ...saved_form_inputs_test,
                  client_card_types:
                    item?.reserved_pricing?.client_card_types?.map((m: any) => {
                      return {
                        card_name: m?.card_name,
                        percentage_fee: m?.percentage_fee,
                        fixed_fee: m?.fixed_fee,
                        currency_code: m?.currency_code,
                        allow_card: m?.allow_card,

                        cost_percentage_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.percentage_fee || "",
                        cost_fixed_fee:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.fixed_fee || "",
                        cost_currency_code:
                          item?.cost?.client_card_types?.filter(
                            (f: any) => f?.card_name == m?.card_name
                          )[0]?.currency_code,
                      };
                    }),

                  test_endpoint: item?.test_endpoint
                    ? item?.test_endpoint
                    : saved_form_fees_details_test[0]?.test_endpoint,
                  live_endpoint: item?.live_endpoint
                    ? item?.live_endpoint
                    : saved_form_fees_details_test[0]?.live_endpoint,
                  additional_fees: {
                    chargeback_fees:
                      item?.reserved_pricing?.additional_fees?.chargeback_fees,
                    dispute_fees:
                      item?.reserved_pricing?.additional_fees?.dispute_fees,
                    reserve_fees:
                      item?.reserved_pricing?.additional_fees?.reserve_fees,
                    refunded_tnx_fees:
                      item?.reserved_pricing?.additional_fees
                        ?.refunded_tnx_fees,
                    declined_tnx_fees:
                      item?.reserved_pricing?.additional_fees
                        ?.declined_tnx_fees,
                    cost_chargeback_fees:
                      item?.cost?.additional_fees?.chargeback_fees,
                    cost_dispute_fees:
                      item?.cost?.additional_fees?.dispute_fees,
                    cost_reserve_fees:
                      item?.cost?.additional_fees?.reserve_fees,
                    cost_refunded_tnx_fees:
                      item?.cost?.additional_fees?.refunded_tnx_fees,
                    cost_declined_tnx_fees:
                      item?.cost?.additional_fees?.declined_tnx_fees,
                  },
                  cardTypes: saved_form_CardTypes,
                  payout_fees: item?.payout_fees || {},
                  wallet_ids: item?.wallet_ids || [],
                  ewallet_New_addressess: [],
                  disCard_walletes: [],
                  fess_conditions: {
                    apm: saved_form_fees_details_test[0]?.apm,
                    payout: saved_form_fees_details_test[0]?.payout,
                    payin: saved_form_fees_details_test[0]?.payin,
                  },
                  payment_methods: convertIntoArray(
                    saved_form_fees_details_test
                  ),
                  gateway_method_fees: {
                    apm_fees:
                      item?.reserved_pricing?.gateway_method_fees?.apm_fees,
                    cost_apm_fees: item?.cost?.gateway_method_fees?.apm_fees,
                    payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    cost_payout_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payout_fees,
                    payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                    cost_payin_fees:
                      item?.reserved_pricing?.gateway_method_fees?.payin_fees,
                  },
                  type: type,
                  whitelist_enabled: item?.whitelist_enabled,
                }
            : {
                ...item?.inputs,
                is_live: value,
              };

        return {
          ...item,
          inputs: clonedObject,
        };
      });

    if (newSavedInput && newSavedInput.length > 0 && newSavedInput[0]?.inputs) {
      setFormValues(newSavedInput[0]?.inputs ?? {});
    } else {
      let form_inputs = gatewayTypes.filter((data) => data?.name === type);
      if (form_inputs && form_inputs.length > 0) {
        setFormValues(form_inputs[0]?.inputs);
      }
    }
  }, [type, addedgateways, gatewayTypes]);

  const handleReset = () => {
    setFormValues({});
    setFormValuesErr({});
  };

  const handleClose = () => {
    handleReset();
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };

  const handleCurrencyChange = (value: string) => {
    setCurrencyConv(value);
  };

  function handleValueChange(e: any) {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
    setFormValuesErr({ ...formValuesErr, [e.target.id]: "" });
  }

  function handleCheckChange(e: any) {
    const filterPaymentMethod = formValues?.payment_methods?.map(
      (method: any) => {
        if (method.name === e.target.id) {
          return {
            ...method,
            is_Active: !method.is_Active,
          };
        } else {
          return {
            ...method,
            is_Active: method.is_Active,
          };
        }
      }
    );
    setFormValues({ ...formValues, payment_methods: filterPaymentMethod });
  }

  const handlePriceValueChange = (
    e: any,
    index: number,
    cardType: string,
    inputFieldType: any
  ) => {
    if (inputFieldType == "cost_cardType_currency_code") {
      setFormValuesErr((prev: any) => {
        return {
          ...prev,
          client_card_types: prev?.client_card_types?.map((m: any) => {
            if (cardType == m?.card_name) {
              return {
                ...m,
                cost_currency_code: "",
              };
            } else {
              return m;
            }
          }),
        };
      });

      if (
        formValues?.client_card_types?.filter(
          (f: any) => f?.card_name == cardType
        )?.length > 0
      ) {
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_card_types: prev?.client_card_types?.map(
              (m: any, i: number) => {
                if (cardType == m?.card_name) {
                  return {
                    ...m,
                    cost_currency_code: e,
                  };
                } else {
                  return m;
                }
              }
            ),
          };
        });
      } else {
        let data = {
          card_name: cardType,
          cost_currency_code: e,
        };
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_card_types: [...prev?.client_card_types, data],
          };
        });
      }
    } else if (inputFieldType == "cardType_currency_code") {
      setFormValuesErr((prev: any) => {
        return {
          ...prev,
          client_card_types: prev?.client_card_types?.map((m: any) => {
            if (cardType == m?.card_name) {
              return {
                ...m,
                currency_code: "",
              };
            } else {
              return m;
            }
          }),
        };
      });
      if (
        formValues?.client_card_types?.filter(
          (f: any) => f?.card_name == cardType
        )?.length > 0
      ) {
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_card_types: prev?.client_card_types?.map(
              (m: any, i: number) => {
                if (cardType == m?.card_name) {
                  return {
                    ...m,
                    currency_code: e,
                  };
                } else {
                  return m;
                }
              }
            ),
          };
        });
      } else {
        let data = {
          card_name: cardType,
          currency_code: e,
        };
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_card_types: [...prev?.client_card_types, data],
          };
        });
      }
    } else {
      const name = e.target.id;
      let value = e.target.value;

      if (name == "allow_card") {
        setFormValuesErr((prev: any) => {
          return {
            ...prev,
            client_card_types: prev?.client_card_types?.map((m: any) => {
              if (cardType == m?.card_name) {
                return {
                  ...m,
                  percentage_fee: "",
                  fixed_fee: "",
                  currency_code: "",
                  cost_percentage_fee: "",
                  cost_fixed_fee: "",
                  cost_currency_code: "",
                };
              } else {
                return m;
              }
            }),
          };
        });
      } else {
        setFormValuesErr((prev: any) => {
          return {
            ...prev,
            client_card_types: prev?.client_card_types?.map((m: any) => {
              if (cardType == m?.card_name) {
                return {
                  ...m,
                  [name]: "",
                };
              } else {
                return m;
              }
            }),
          };
        });
      }

      if (name == "allow_card") {
        value = value == "true" ? true : false;
      }

      if (name == "percentage_fee") {
        value = value >= 0 && value <= 100 ? value : "";
      }

      if (name == "cost_percentage_fee") {
        value = value >= 0 && value <= 100 ? value : "";
      }
      if (name == "fixed_fee") {
        value = value >= 0 ? value : "";
      }
      if (name == "cost_fixed_fee") {
        value = value >= 0 ? value : "";
      }

      if (
        formValues?.client_card_types?.filter(
          (f: any) => f?.card_name == cardType
        )?.length > 0
      ) {
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_card_types: prev?.client_card_types?.map(
              (m: any, i: number) => {
                if (cardType == m?.card_name) {
                  return {
                    ...m,
                    [name]: value,
                  };
                } else {
                  return m;
                }
              }
            ),
          };
        });
      } else {
        let data = {
          card_name: cardType,
          [name]: value,
        };

        if (formValues?.client_card_types?.length > 0) {
          setFormValues((prev: any) => {
            return {
              ...prev,
              client_card_types: [...prev?.client_card_types, data],
            };
          });
        } else {
          setFormValues((prev: any) => {
            return {
              ...prev,
              client_card_types: [data],
            };
          });
        }
      }
    }
  };

  const handleCostValueChange = (
    e: any,
    index: number,
    cardType: string,
    inputFieldType: any
  ) => {
    if (inputFieldType == "cardType_currency_code") {
      if (
        formValues?.client_card_types?.filter(
          (f: any) => f?.card_name == cardType
        )?.length > 0
      ) {
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_cost: prev?.client_cost?.map((m: any, i: number) => {
              if (cardType == m?.card_name) {
                return {
                  ...m,
                  currency_code: e,
                };
              } else {
                return m;
              }
            }),
          };
        });
      } else {
        let data = {
          card_name: cardType,
          currency_code: e,
        };
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_cost: [...prev?.client_cost, data],
          };
        });
      }
    } else {
      const name = e.target.id;
      let value = e.target.value;

      if (name == "allow_card") {
        value = value == "true" ? true : false;
      }

      if (name == "percentage_fee") {
        value = value >= 0 && value <= 100 ? value : "";
      }

      if (name == "fixed_fee") {
        value = value >= 0 ? value : "";
      }

      if (
        formValues?.client_cost?.filter((f: any) => f?.card_name == cardType)
          ?.length > 0
      ) {
        setFormValues((prev: any) => {
          return {
            ...prev,
            client_cost: prev?.client_cost?.map((m: any, i: number) => {
              if (cardType == m?.card_name) {
                return {
                  ...m,
                  [name]: value,
                };
              } else {
                return m;
              }
            }),
          };
        });
      } else {
        let data = {
          card_name: cardType,
          [name]: value,
        };

        setFormValues((prev: any) => {
          return {
            ...prev,
            client_cost: [...prev?.client_cost, data],
          };
        });
      }
    }
  };

  useEffect(() => {
    setFormValuesErr((prev: any) => {
      return {};
    });
    setisValidateAdditionalFeesPricing(false);
    setisValidateAdditionalFeesCost(true);
  }, [type]);

  let filterError: any = [];

  const validateCinetCardTypes = () => {
    filterError = formValues?.client_card_types
      ?.filter((f: any) => {
        return f?.allow_card;
      })
      ?.map((mData: any) => {
        return {
          ...mData,
          percentage_fee: mData?.percentage_fee ? "" : "Required field.",
          fixed_fee: mData?.fixed_fee ? "" : "Required field.",
          currency_code: mData?.currency_code ? "" : "Required field.",
          cost_percentage_fee: mData?.cost_percentage_fee
            ? ""
            : "Required field.",
          cost_fixed_fee: mData?.cost_fixed_fee ? "" : "Required field.",
          cost_currency_code: mData?.cost_currency_code
            ? ""
            : "Required field.",
        };
      });

    setFormValuesErr((prev: any) => {
      return {
        ...prev,
        client_card_types: filterError,
      };
    });
  };
  useEffect(() => {
    validateCinetCardTypes();
  }, [formValues?.client_card_types]);

  useEffect(() => {
    validateCinetAdditionalFees();
  }, [formValues?.additional_fees]);

  let additional_fees_filter_error: any = {};

  const validateCinetAdditionalFees = () => {
    let additional_fees: any = {};

    let is_chargeback_fees_for_pricing_required =
      formValues?.additional_fees?.chargeback_fees?.allow_chargeback_fees;

    let is_chargeback_fees_for_cost_required =
      formValues?.additional_fees?.cost_chargeback_fees?.allow_chargeback_fees;
    if (is_chargeback_fees_for_pricing_required) {
      additional_fees = {
        ...additional_fees,
        chargeback_fees: {
          percentage_fee: formValues?.additional_fees?.chargeback_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.chargeback_fees?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.chargeback_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }

    if (is_chargeback_fees_for_cost_required) {
      additional_fees = {
        ...additional_fees,
        cost_chargeback_fees: {
          percentage_fee: formValues?.additional_fees?.cost_chargeback_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.cost_chargeback_fees
            ?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.cost_chargeback_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }

    let is_dispute_fees_for_pricing_required =
      formValues?.additional_fees?.dispute_fees?.allow_dispute_fees;
    let is_dispute_fees_for_cost_required =
      formValues?.additional_fees?.cost_dispute_fees?.allow_dispute_fees;
    if (is_dispute_fees_for_pricing_required) {
      additional_fees = {
        ...additional_fees,
        dispute_fees: {
          percentage_fee: formValues?.additional_fees?.dispute_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.dispute_fees?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.dispute_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }
    if (is_dispute_fees_for_cost_required) {
      additional_fees = {
        ...additional_fees,
        cost_dispute_fees: {
          percentage_fee: formValues?.additional_fees?.cost_dispute_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.cost_dispute_fees?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.cost_dispute_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }

    let is_declined_tnx_fees_for_pricing_required =
      formValues?.additional_fees?.declined_tnx_fees?.allow_declined_tnx_fees;
    let is_declined_tnx_fees_for_cost_required =
      formValues?.additional_fees?.cost_declined_tnx_fees
        ?.allow_declined_tnx_fees;
    if (is_declined_tnx_fees_for_pricing_required) {
      additional_fees = {
        ...additional_fees,
        declined_tnx_fees: {
          percentage_fee: formValues?.additional_fees?.declined_tnx_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.declined_tnx_fees?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.declined_tnx_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }
    if (is_declined_tnx_fees_for_cost_required) {
      additional_fees = {
        ...additional_fees,
        cost_declined_tnx_fees: {
          percentage_fee: formValues?.additional_fees?.cost_declined_tnx_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.cost_declined_tnx_fees
            ?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.cost_declined_tnx_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }

    let is_refunded_tnx_fees_for_pricing_required =
      formValues?.additional_fees?.refunded_tnx_fees?.allow_refunded_tnx_fees;
    let is_refunded_tnx_fees_for_cost_required =
      formValues?.additional_fees?.cost_refunded_tnx_fees
        ?.allow_refunded_tnx_fees;
    if (is_refunded_tnx_fees_for_pricing_required) {
      additional_fees = {
        ...additional_fees,
        refunded_tnx_fees: {
          percentage_fee: formValues?.additional_fees?.refunded_tnx_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.refunded_tnx_fees?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.refunded_tnx_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }
    if (is_refunded_tnx_fees_for_cost_required) {
      additional_fees = {
        ...additional_fees,
        cost_refunded_tnx_fees: {
          percentage_fee: formValues?.additional_fees?.cost_refunded_tnx_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          fixed_fee: formValues?.additional_fees?.cost_refunded_tnx_fees
            ?.fixed_fee
            ? ""
            : "Required field.",
          currency_code: formValues?.additional_fees?.cost_refunded_tnx_fees
            ?.currency_code
            ? ""
            : "Required field.",
        },
      };
    }

    let is_reserve_fees_for_pricing_required =
      formValues?.additional_fees?.reserve_fees?.allow_reserve_fees;
    let is_reserve_fees_for_cost_required =
      formValues?.additional_fees?.cost_reserve_fees?.allow_reserve_fees;
    if (is_reserve_fees_for_pricing_required) {
      additional_fees = {
        ...additional_fees,
        reserve_fees: {
          percentage_fee: formValues?.additional_fees?.reserve_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          number_of_days: formValues?.additional_fees?.reserve_fees
            ?.number_of_days
            ? ""
            : "Required field.",
        },
      };
    }
    if (is_reserve_fees_for_cost_required) {
      additional_fees = {
        ...additional_fees,
        cost_reserve_fees: {
          percentage_fee: formValues?.additional_fees?.cost_reserve_fees
            ?.percentage_fee
            ? ""
            : "Required field.",
          number_of_days: formValues?.additional_fees?.cost_reserve_fees
            ?.number_of_days
            ? ""
            : "Required field.",
        },
      };
    }

    additional_fees_filter_error = additional_fees;

    let validateObj = {};
    hasEmptyValuesInClient_additional_fees = Object?.keys(
      additional_fees_filter_error
    )
      .map((objKey: any) => {
        if (additional_fees_filter_error[objKey]?.percentage_fee) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.fixed_fee) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.currency_code) {
          return true;
        }

        if (additional_fees_filter_error[objKey]?.number_of_days) {
          return true;
        }

        if (additional_fees_filter_error[objKey]?.percentage_fee) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.fixed_fee) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.currency_code) {
          return true;
        }

        if (additional_fees_filter_error[objKey]?.number_of_days) {
          return true;
        }

        return false;
      })
      ?.some((sData: boolean) => {
        return sData == true;
      });

    let hasEmptyValuesInClient_additional_feesInPricing = Object?.keys(
      additional_fees_filter_error
    )
      .map((objKey: any) => {
        if (objKey.search("cost") == -1) {
          if (additional_fees_filter_error[objKey]?.percentage_fee) {
            return true;
          }
          if (additional_fees_filter_error[objKey]?.fixed_fee) {
            return true;
          }
          if (additional_fees_filter_error[objKey]?.currency_code) {
            return true;
          }

          if (additional_fees_filter_error[objKey]?.number_of_days) {
            return true;
          }
        }

        return false;
      })
      ?.some((sData: boolean) => {
        return sData == true;
      });

    let hasEmptyValuesInClient_additional_fees_Cost = Object?.keys(
      additional_fees_filter_error
    )
      .map((objKey: any) => {
        if (objKey.search("cost") != -1) {
          if (additional_fees_filter_error[objKey]?.percentage_fee) {
            return true;
          }
          if (additional_fees_filter_error[objKey]?.fixed_fee) {
            return true;
          }
          if (additional_fees_filter_error[objKey]?.currency_code) {
            return true;
          }

          if (additional_fees_filter_error[objKey]?.number_of_days) {
            return true;
          }
        }

        return false;
      })
      ?.some((sData: boolean) => {
        return sData == true;
      });

    setisValidateAdditionalFeesPricing(
      hasEmptyValuesInClient_additional_feesInPricing
    );
    setisValidateAdditionalFeesCost(
      hasEmptyValuesInClient_additional_fees_Cost
    );

    setFormValuesErr((prev: any) => {
      return {
        ...prev,
        additional_fees: additional_fees,
      };
    });
  };

  useEffect(() => {
    validateVelocityEntries();
  }, [formValues?.velocity]);

  const validateVelocityEntries = () => {
    let velocity: any = {};

    let is_transaction_limit =
      formValues?.velocity?.transaction_limit?.allow_transaction_limit;
    if (is_transaction_limit) {
      velocity = {
        ...velocity,
        transaction_limit: {
          limit_no: formValues?.velocity?.transaction_limit?.limit_no
            ? ""
            : "Required field.",
          period: formValues?.velocity?.transaction_limit?.period
            ? ""
            : "Required field.",
        },
      };
    }

    let is_max_transaction_limit =
      formValues?.velocity?.max_transaction_limit?.allow_max_transaction_limit;
    if (is_max_transaction_limit) {
      velocity = {
        ...velocity,
        max_transaction_limit: {
          limit_amount: formValues?.velocity?.max_transaction_limit
            ?.limit_amount
            ? ""
            : "Required field.",
          currency: formValues?.velocity?.max_transaction_limit?.currency
            ? ""
            : "Required field.",
        },
      };
    }

    let is_min_transaction_limit =
      formValues?.velocity?.min_transaction_limit?.allow_min_transaction_limit;
    if (is_min_transaction_limit) {
      velocity = {
        ...velocity,
        min_transaction_limit: {
          limit_amount: formValues?.velocity?.min_transaction_limit
            ?.limit_amount
            ? ""
            : "Required field.",
          currency: formValues?.velocity?.min_transaction_limit?.currency
            ? ""
            : "Required field.",
        },
      };
    }

    let is_max_transaction_period =
      formValues?.velocity?.max_transaction_period
        ?.allow_max_transaction_period;
    if (is_max_transaction_period) {
      velocity = {
        ...velocity,
        max_transaction_period: {
          limit_amount: formValues?.velocity?.max_transaction_period
            ?.limit_amount
            ? ""
            : "Required field.",
          currency: formValues?.velocity?.max_transaction_period?.currency
            ? ""
            : "Required field.",
          period: formValues?.velocity?.max_transaction_period?.period
            ? ""
            : "Required field.",
        },
      };
    }

    let is_retry_time = formValues?.velocity?.retry_time?.allow_retry_time;
    if (is_retry_time) {
      velocity = {
        ...velocity,
        retry_time: {
          limit_minutes: formValues?.velocity?.retry_time?.limit_minutes
            ? ""
            : "Required field.",
        },
      };
    }

    let is_max_attempts_day =
      formValues?.velocity?.max_attempts_day?.allow_max_attempts_day;
    if (is_max_attempts_day) {
      velocity = {
        ...velocity,
        max_attempts_day: {
          limit_no: formValues?.velocity?.max_attempts_day?.limit_no
            ? ""
            : "Required field.",
        },
      };
    }
    let is_max_attempts_week =
      formValues?.velocity?.max_attempts_week?.allow_max_attempts_week;
    if (is_max_attempts_week) {
      velocity = {
        ...velocity,
        max_attempts_week: {
          limit_no: formValues?.velocity?.max_attempts_week?.limit_no
            ? ""
            : "Required field.",
        },
      };
    }
    let is_max_attempts_month =
      formValues?.velocity?.max_attempts_month?.allow_max_attempts_month;
    if (is_max_attempts_month) {
      velocity = {
        ...velocity,
        max_attempts_month: {
          limit_no: formValues?.velocity?.max_attempts_month?.limit_no
            ? ""
            : "Required field.",
        },
      };
    }
    let is_max_attempts_total =
      formValues?.velocity?.max_attempts_total?.allow_max_attempts_total;
    if (is_max_attempts_total) {
      velocity = {
        ...velocity,
        max_attempts_total: {
          limit_no: formValues?.velocity?.max_attempts_total?.limit_no
            ? ""
            : "Required field.",
        },
      };
    }
    let is_blocked_countries =
      formValues?.velocity?.blocked_countries?.allow_blocked_countries;
    if (is_blocked_countries) {
      velocity = {
        ...velocity,
        blocked_countries: {
          country_code: formValues?.velocity?.blocked_countries?.country_code
            ? ""
            : "Required field.",
        },
      };
    }

    additional_fees_filter_error = velocity;

    let validateObj = {};
    hasEmptyValuesInClient_additional_fees = Object?.keys(
      additional_fees_filter_error
    )
      .map((objKey: any) => {
        if (additional_fees_filter_error[objKey]?.limit_no) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.period) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.currency) {
          return true;
        }

        if (additional_fees_filter_error[objKey]?.limit_amount) {
          return true;
        }

        if (additional_fees_filter_error[objKey]?.limit_minutes) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.country_code) {
          return true;
        }
        return false;
      })
      ?.some((sData: boolean) => {
        return sData == true;
      });

    setisValidateVelocity(hasEmptyValuesInClient_additional_fees);

    setFormValuesErr((prev: any) => {
      return {
        ...prev,
        velocity: velocity,
      };
    });
  };

  const handleAdditionalValueChange = (e: any, feeType: string) => {
    if (feeType == "cost_chargeback_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_chargeback_fees: {
              ...prev?.additional_fees?.cost_chargeback_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_dispute_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_dispute_fees: {
              ...prev?.additional_fees?.cost_dispute_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "dispute_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            dispute_fees: {
              ...prev?.additional_fees?.dispute_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "chargeback_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            chargeback_fees: {
              ...prev?.additional_fees?.chargeback_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "decliened_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            declined_tnx_fees: {
              ...prev?.additional_fees?.declined_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_decliened_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_declined_tnx_fees: {
              ...prev?.additional_fees?.cost_declined_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "refunded_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            refunded_tnx_fees: {
              ...prev?.additional_fees?.refunded_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_refunded_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_refunded_tnx_fees: {
              ...prev?.additional_fees?.cost_refunded_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else {
      const name = e.target.id;
      let value = e.target.value;

      if (name == "percentage_fee") {
        value = value >= 0 && value <= 100 ? value : "";
      }

      if (name == "fixed_fee") {
        value = value >= 0 ? value : "";
      }

      if (name == "allow_chargeback_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_dispute_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_declined_tnx_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_refunded_tnx_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_reserve_fees") {
        value = value == "true" ? true : false;
      }

      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            [feeType]: {
              ...prev?.additional_fees[feeType],
              [name]: value,
            },
          },
        };
      });
    }
  };

  const handleAdditionalValueChangeOfCost = (e: any, feeType: string) => {
    if (feeType == "cost_chargeback_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_chargeback_fees: {
              ...prev?.additional_fees?.cost_chargeback_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_dispute_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_dispute_fees: {
              ...prev?.additional_fees?.cost_dispute_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "dispute_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            dispute_fees: {
              ...prev?.additional_fees?.dispute_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "chargeback_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            chargeback_fees: {
              ...prev?.additional_fees?.chargeback_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "decliened_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            declined_tnx_fees: {
              ...prev?.additional_fees?.declined_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_decliened_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_declined_tnx_fees: {
              ...prev?.additional_fees?.cost_declined_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "refunded_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            refunded_tnx_fees: {
              ...prev?.additional_fees?.refunded_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_refunded_tnx_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            cost_refunded_tnx_fees: {
              ...prev?.additional_fees?.cost_refunded_tnx_fees,
              currency_code: e,
            },
          },
        };
      });
    } else {
      const name = e.target.id;
      let value = e.target.value;

      if (name == "percentage_fee") {
        value = value >= 0 && value <= 100 ? value : "";
      }

      if (name == "fixed_fee") {
        value = value >= 0 ? value : "";
      }

      if (name == "allow_chargeback_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_dispute_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_declined_tnx_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_refunded_tnx_fees") {
        value = value == "true" ? true : false;
      }

      if (name == "allow_reserve_fees") {
        value = value == "true" ? true : false;
      }

      setFormValues((prev: any) => {
        return {
          ...prev,
          additional_fees: {
            ...prev?.additional_fees,
            [feeType]: {
              ...prev?.additional_fees[feeType],
              [name]: value,
            },
          },
        };
      });
    }
  };

  const handleGatewayMethodfeesValueChange = (e: any, feeType: string) => {
    if (feeType == "cost_payin_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            cost_payin_fees: {
              ...prev?.gateway_method_fees?.cost_payin_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_payout_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            cost_payout_fees: {
              ...prev?.gateway_method_fees?.cost_payout_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "cost_apm_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            cost_apm_fees: {
              ...prev?.gateway_method_fees?.cost_apm_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "apm_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            apm_fees: {
              ...prev?.gateway_method_fees?.apm_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "payout_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            payout_fees: {
              ...prev?.gateway_method_fees?.payout_fees,
              currency_code: e,
            },
          },
        };
      });
    } else if (feeType == "payin_currency_code") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            payin_fees: {
              ...prev?.gateway_method_fees?.payin_fees,
              currency_code: e,
            },
          },
        };
      });
    } else {
      const name = e.target.id;
      let value = e.target.value;

      if (name == "percentage_fee") {
        value = value >= 0 && value <= 100 ? value : "";
      }

      if (name == "fixed_fee") {
        value = value >= 0 ? value : "";
      }
      // if (name == "cost_percentage_fee") {
      //   value = value >= 0 && value <= 100 ? value : "";
      // }

      // if (name == "cost_fixed_fee") {
      //   value = value >= 0 ? value : "";
      // }

      setFormValues((prev: any) => {
        return {
          ...prev,
          gateway_method_fees: {
            ...prev?.gateway_method_fees,
            [feeType]: {
              ...prev?.gateway_method_fees[feeType],
              [name]: value,
            },
          },
        };
      });
    }
  };

  const handleVelocityValueChange = (
    e: any,
    fieldType: string,
    rowType: string
  ) => {
    if (rowType == "transaction_limit") {
      if (fieldType == "period") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              transaction_limit: {
                ...prev?.velocity?.transaction_limit,
                period: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;
        if (name == "limit_no") {
          value = value >= 0 ? value : "";
        }
        if (name == "allow_transaction_limit") {
          value = value == "true" ? true : false;
        }
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (rowType == "max_transaction_limit") {
      if (fieldType == "currency") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              max_transaction_limit: {
                ...prev?.velocity?.max_transaction_limit,
                currency: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;
        if (name == "limit_amount") {
          value = value >= 0 ? value : "";
        }
        if (name == "allow_max_transaction_limit") {
          value = value == "true" ? true : false;
        }
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (rowType == "min_transaction_limit") {
      if (fieldType == "currency") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              min_transaction_limit: {
                ...prev?.velocity?.min_transaction_limit,
                currency: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;
        if (name == "limit_amount") {
          value = value >= 0 ? value : "";
        }
        if (name == "allow_min_transaction_limit") {
          value = value == "true" ? true : false;
        }
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (rowType == "max_transaction_period") {
      if (fieldType == "period") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              max_transaction_period: {
                ...prev?.velocity?.max_transaction_period,
                period: e,
              },
            },
          };
        });
      } else if (fieldType == "currency") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              max_transaction_period: {
                ...prev?.velocity?.max_transaction_period,
                currency: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;
        if (name == "limit_amount") {
          value = value >= 0 ? value : "";
        }
        if (name == "allow_max_transaction_period") {
          value = value == "true" ? true : false;
        }
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (rowType == "retry_time") {
      if (fieldType == "currency") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              min_transaction_limit: {
                ...prev?.velocity?.min_transaction_limit,
                currency: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;
        if (name == "limit_minutes") {
          value = value >= 0 ? value : "";
        }
        if (name == "allow_retry_time") {
          value = value == "true" ? true : false;
        }
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (
      [
        "max_attempts_day",
        "max_attempts_week",
        "max_attempts_month",
        "max_attempts_total",
      ].includes(rowType)
    ) {
      if (fieldType == "currency") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              min_transaction_limit: {
                ...prev?.velocity?.min_transaction_limit,
                currency: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;
        if (name == "limit_no") {
          value = value >= 0 ? value : "";
        }
        if (name == "allow_max_attempts_day") {
          value = value == "true" ? true : false;
        }
        if (name == "allow_max_attempts_week") {
          value = value == "true" ? true : false;
        }
        if (name == "allow_max_attempts_month") {
          value = value == "true" ? true : false;
        }
        if (name == "allow_max_attempts_total") {
          value = value == "true" ? true : false;
        }
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (["blocked_countries"].includes(rowType)) {
      if (fieldType == "country_code") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              blocked_countries: {
                ...prev?.velocity?.blocked_countries,
                country_code: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;

        if (name == "allow_blocked_countries") {
          value = value == "true" ? true : false;
        }

        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
    if (["blocked_countries_bin"].includes(rowType)) {
      if (fieldType == "country_code") {
        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              blocked_countries_bin: {
                ...prev?.velocity?.blocked_countries_bin,
                country_code: e,
              },
            },
          };
        });
      } else {
        const name = e.target.id;
        let value = e.target.value;

        if (name == "allow_blocked_countries_bin") {
          value = value == "true" ? true : false;
        }

        setFormValues((prev: any) => {
          return {
            ...prev,
            velocity: {
              ...prev?.velocity,
              [rowType]: {
                ...prev?.velocity[rowType],
                [name]: value,
              },
            },
          };
        });
      }
    }
  };

  const handleAdvancedAVSChange = (e: any,fieldType: string) => {
    const { checked } = e.target;

    setFormValues((prev: any) => {
      return {
        ...prev,
        advanced_avs: {
          ...prev.advanced_avs,
          [fieldType]: checked,
        },
      };
    });
  
  }

  const handlePayoutValueChange = (e: any, sectionType: string) => {
    const name = e.target.id;
    let value = e.target.value;

    if (name == "percentage") {
      value = value >= 0 && value <= 100 ? value : "";
    }

    if (name == "fixed_fee") {
      value = value >= 0 ? value : "";
    }

    setFormValues((prev: any) => {
      return {
        ...prev,
        payout_fees: {
          ...prev?.payout_fees,
          [name]: value,
          // }
        },
      };
    });
  };

  const handleEwalletValueChange = (
    e: any,
    wallet_data: any,
    wallet_value_type: string
  ) => {
    const name = e.target.id;
    let value = e.target.value;
    if (name == "percentage") {
      value = value >= 0 && value <= 100 ? value : "";
    }

    if (name == "fixed_fee") {
      value = value >= 0 ? value : "";
    }

    if (
      formValues?.wallet_ids?.filter(
        (f: any) => f?.wallet_id == wallet_data?.wallet_id
      )?.length > 0
    ) {
      setFormValues((prev: any) => {
        return {
          ...prev,
          wallet_ids: prev?.wallet_ids?.map((m: any, i: number) => {
            if (wallet_data?.wallet_id == m?.wallet_id) {
              return {
                ...m,
                [name]: value,
              };
            } else {
              return m;
            }
          }),
        };
      });
    } else {
      let data = {
        wallet_address: "",
        percentage: "",
        [name]: value,
      };
      setFormValues((prev: any) => {
        return {
          ...prev,
          wallet_ids: [...prev?.wallet_ids, data],
        };
      });
    }
  };

  const handleReseSelectChange = (selectedValue: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      reserved: selectedValue,
    }));
  };

  const handleInputChange = (field: string, value: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleNewWallet = (e_type: string, NewWalletIndex: any) => {
    if (e_type == "add_New_wallet") {
      let isCompleted = formValues?.ewallet_New_addressess?.every(
        (wallet_details: any) => {
          return (
            wallet_details?.wallet_address != "" &&
            wallet_details?.percentage != ""
          );
        }
      );
      if (isCompleted) {
        let newData = {
          wallet_address: "",
          percentage: "",
        };

        setFormValues((prev: any) => {
          return {
            ...prev,
            ewallet_New_addressess: [...prev?.ewallet_New_addressess, newData],
          };
        });
      } else {
        toast.error("Please Enter all details for wallet!");
      }
    }

    if (e_type == "remove_New_wallet") {
      setFormValues((prev: any) => {
        return {
          ...prev,
          ewallet_New_addressess: prev?.ewallet_New_addressess?.filter(
            (f: any, index: number) => Number(index) != Number(NewWalletIndex)
          ),
        };
      });
    }
  };

  const handleDiscardExistingWallets = (walletData: any) => {
    if (walletData?.is_verified) {
      const WalletAddress = walletData?.wallet_id;

      let tempArr: any = [];
      if (formValues?.disCard_walletes?.length > 0) {
        formValues?.disCard_walletes?.forEach((m: any) => {
          tempArr.push(m);
        });
      }
      tempArr.push(WalletAddress);
      setFormValues((prev: any) => {
        return {
          ...prev,
          wallet_ids: prev?.wallet_ids?.filter(
            (f: any) => f?.wallet_id != WalletAddress
          ),
          disCard_walletes: tempArr,
        };
      });
    } else {
      toast.error("You can not remove wallet, please Verify with 2FA code!");
    }
  };

  const handleVerifyWallet = () => {
    let pwdd = wallertVerifyIput?.password;
    let passCoded = wallertVerifyIput?.passCode;
    if (!pwdd || !passCoded) {
      toast.error("Please enter all required fields!");
      return;
    }
    let gateway = gatewayTypes.filter((data) => data?.name === type);
    let gateway_id = gateway && gateway.length > 0 ? gateway[0]?.id : "";
    let data: any = {
      id: id,
      gateway_id: gateway_id,
      password: wallertVerifyIput?.password,
      otp: wallertVerifyIput?.passCode,
      wallet_id: wallertVerifyIput?.wallet_id,
    };

    verifyWalletAddress(data)
      .then((data) => {
        let ewallet = {
          ...data?.ewallet,
          is_verified: data?.is_verified,
        };
        let res_wallet_id = ewallet?.wallet_id;
        setFormValues((prev: any) => {
          return {
            ...prev,
            wallet_ids: prev?.wallet_ids?.map((m: any) => {
              if (res_wallet_id == m?.wallet_id) {
                return {
                  ...m,
                  ...ewallet,
                };
              } else {
                return m;
              }
            }),
          };
        });
        toast.success("Verified sucessfully!");
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })

      .finally(() => {
        setIsLoading(false);
        onCloseWalletAddress();
      });
  };

  const handleNewWalletValueChange = (
    e: any,
    wallet_data: any,
    wallet_value_type: string,
    wallet_Index: number
  ) => {
    const name = e.target.id;
    let value = e.target.value;
    if (name == "percentage") {
      value = value >= 0 && value <= 100 ? value : "";
    }

    if (name == "fixed_fee") {
      value = value >= 0 ? value : "";
    }
    setFormValues((prev: any) => {
      return {
        ...prev,
        ewallet_New_addressess: prev?.ewallet_New_addressess?.map(
          (wallet_details: any, i: number) => {
            if (i == wallet_Index) {
              return {
                ...wallet_details,
                [name]: value,
              };
            } else {
              return wallet_details;
            }
          }
        ),
      };
    });
  };

  const fetchAllGateways = () => {
    setIsLoading(true);
    getAllGateways(assigned_payment_methods?.assigned_payment_methods)
      .then((data) => {
        console.log("graphql called create client gateway modal fetch all gateways", data)
        console.log("Krenil", data);

        setGatewayTypes(data);
        if (Array.isArray(data)) {
          if (data.length > 0) {
            setType(data[0]?.name);
            setHashID(data[0]?.id);
          }

          const newDataArray = data.map((elem) => ({
            ...elem,
            image: getImage(elem.name),
          }));
          setGatewayTypes(newDataArray);
          setGatewayTypesCopy(newDataArray);

          data.forEach((elem) => {
            setStoreGateWayId(elem.id);
          });
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Something went wrong while fetching gateways"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getImage = (name: string) => {
    if (name === "Sipe") {
      return sipe;
    } else if (name === "Pix-Lotus") {
      return pixLotus;
    } else if (name === "3xpay") {
      return Threexpay;
    } else if (name === "Pix") {
      return pix;
    } else if (name === "Blumon") {
      return blumon;
    } else if (name === "Stripe") {
      return stripe;
    } else if (name === "Memphis") {
      return memphis;
    } else if (name === "Raypd") {
      return repyd;
    } else if (name === "Banwire") {
      return banwire;
    } else if (name === "Valitor") {
      return valitor;
    } else if (name === "Bambora") {
      return bambora;
    } else if (name === "Digitarium") {
      return Digitarium;
    } else if (name === "Tangoindia") {
      return Tangoindia;
    } else if (name === "G2Pay") {
      return G2pay;
    } else if (name === "Moneto") {
      return Moneto;
    } else if (name === "Re-set") {
      return reSet;
    } else if (name === "MIT") {
      return mit;
    } else if (name === "Scipiopay") {
      return Scipiopay;
    } else if (name === "Betapay") {
      return Betapay;
    } else if (name === "Aurea Via") {
      return Aurea_via;
    } else if (name === "Kasha") {
      return Kasha;
    } else if (name === "Supefina") {
      return Supefina;
    } else if (name === "Platy") {
      return Platy;
    } else if (name === "Platy 2DS") {
      return Platy;
    } else if (name === "Platy 3DS") {
      return Platy;  
    } else if (name === "Linx") {
      return Linx;
    } else if (name === "Masterpay") {
      return Master_Pay;
    }else if (name === "Neonpay") {
      return Neonpay;
    }
  else if (name === "Bm2pay") {
    return Bm2pay;
  }
  };

  const fetchAddedGateways = () => {
    setIsLoading(true);
    getAllGatewaysByClient(id)
      .then((data) => {
        if(data.status != "Error") {
          setAddedGateways((preval: any) => {
            return preval.map((ele: any) => {
              let filtered = data.filter((d: any) => d?.name === ele?.name);
              if (filtered) {
                return { ...ele, ...filtered[0], inputs: filtered[0]?.auth_info };
              }
            });
          });
          setCurrencyConv(
            data.filter((d: any) => d?.name == type)?.to_convert
              ? "currencyConverter"
              : "requestPassthrough"
          );
        } else {
          handleClose();
          toast.error(data.message);
          
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Something went wrong while fetching gateways"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCredentialSelect = (event: any) => {
    setSelectedCredential(event);
    const fetchCredential = predefinedCredentials.find((d: any) => d?.id === event);

    setSelectedPredefinedCredentials(fetchCredential)
    setCredentialError("")
  }

  useEffect(() => {

    const selectedGateway = gatewayTypes.find((d: any) => d?.name === type);

    if(selectedGateway != undefined && selectedGateway !== null){
      const predefined_credentials = selectedGateway?.meta_info?.predefined_credentials

      if(predefined_credentials != undefined && selectedGateway !== null &&  predefined_credentials.length > 0) {
        setPredefinedCredentials(predefined_credentials)
      }
    }

    const addGateway : any = addedgateways.find((d: any) => d?.name === type);
    if(addGateway != undefined && addGateway !== null){
      if (addGateway?.auth_info) {
        const credType = addGateway?.auth_info?.credential_type || 'custom';

        setCredentialsType(credType);

        if(credType == 'predefined') {
          const preCred = addGateway?.auth_info?.predefinedCredentials;
          setSelectedCredential(preCred.id);
          setSelectedPredefinedCredentials(preCred);
        }
      }
    }
    
    setCurrencyConv(
      addedgateways.filter((d: any) => d?.name == type)[0]?.to_convert
        ? "currencyConverter"
        : "requestPassthrough"
    );
    setSelectedCurrency(
      addedgateways.filter((d: any) => d?.name == type)[0]?.to_convert
        ? addedgateways.filter((d: any) => d?.name == type)[0]?.new_currency
        : "USD"
    );
  }, [type, addedgateways]);

  let hasEmptyValuesInClient_additional_fees = false;

  function validateData() {
    // debugger;
    console.log("test prod ::: type ::", type);

    console.log("formValues",formValues);
    let escape_arr = [
      "statement_descriptor",
      "statement_descriptor_suffix",
      "business_name",
      "descriptor",
      "mid_country_live",
      "mid_country_test",
    ];
    if (type === "Stripe") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? "secret_key_live"
          : "secret_key_test";
      escape_arr?.push(data);
    } else if (type === "Pix") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["name_live", "email_live"]
          : ["name_test", "email_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Memphis") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["commerce_id_live", "commerce_name_live", "tkr_live"]
          : ["commerce_id_test", "commerce_name_test", "tkr_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Raypd") {
      console.log("test prod ::: inside raypd");
      console.log("test prod ::: formvalues :::", formValues);
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["access_key_live", "secret_key_live"]
          : ["access_key_test", "secret_key_test"];
      console.log("test prod ::: validation data", { data });
      data?.map((item) => {
        return escape_arr?.push(item);
      });
      console.log("test prod ::: escape array ::", { escape_arr });
    } else if (type === "Banwire") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["username_live", "username_live"]
          : ["username_test", "username_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Valitor") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["apikey_live", "apikey_live"]
          : ["apikey_test", "apikey_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Bambora") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["merchantId_live", "passcode_live", "hash_key_live","recurring_passcode_live","reporting_passcode_live","batch_passcode_live","subscription_passcode_live"]
          : ["merchantId_test", "passcode_test", "hash_key_test","recurring_passcode_test","reporting_passcode_test","batch_passcode_test","subscription_passcode_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Digitarium") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["merchantKey_live", "password_live"]
          : ["merchantKey_test", "password_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Tangoindia") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["api_id_live", "api_key_live"]
          : ["api_id_test", "api_key_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "G2Pay") {
        const data =
          formValues?.is_live === false || formValues?.is_live === undefined
            ? ["signing_key_live", "api_key_live"]
            : ["signing_key_test", "api_key_test"];
        data?.map((item) => {
          return escape_arr?.push(item);
        });
    } else if (type === "Moneto") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["merchant_secret_key_live", "merchant_id_live"]
          : ["merchant_secret_key_test", "merchant_id_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Re-set") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["secretKey_live", "brand_id_live"]
          : ["secretKey_test", "brand_id_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "MIT") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? [
              "id_branch_live",
              "id_company_live",
              "merchant_id_live",
              "password_live",
              "user_code_live",
            ]
          : [
              "id_branch_test",
              "id_company_test",
              "merchant_id_test",
              "password_test",
              "user_code_test",
            ];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Scipiopay") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["shop_secret_key_live", "shop_id_live"]
          : ["shop_id_test", "shop_secret_key_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Betapay") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["api_token_live", "merchant_id_live", "terminal_id_live"]
          : ["api_token_test", "merchant_id_test", "terminal_id_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Aurea Via") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["companyNum_live", "personalHashkey_live"]
          : ["companyNum_test", "personalHashkey_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Kasha") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["api_key_live", "merchant_domain_live"]
          : ["api_key_test", "merchant_domain_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Linx") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["client_id_live", "client_secret_live"]
          : ["client_id_test", "client_secret_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Supefina") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["merchant_id_live"]
          : ["merchant_id_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Platy") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? [
              "api_key_live",
              "password_live",
              "platy_api_key_live",
              "platy_secret_key_live",
              "username_live",
            ]
          : [
              "api_key_test",
              "password_test",
              "platy_api_key_test",
              "platy_secret_key_test",
              "username_test",
            ];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "3xpay") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? [
              "api_key_live",
              "api_secret_live"
            ]
          : [
              "api_key_test",
              "api_secret_test"
            ];

            console.log("Data",data);
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Platy 2DS") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? [
              "api_key_live",
              "password_live",
              "platy_api_key_live",
              "platy_secret_key_live",
              "username_live",
            ]
          : [
              "api_key_test",
              "password_test",
              "platy_api_key_test",
              "platy_secret_key_test",
              "username_test",
            ];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Platy 3DS") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? [
              "api_key_live",
              "password_live",
              "platy_api_key_live",
              "platy_secret_key_live",
              "username_live",
            ]
          : [
              "api_key_test",
              "password_test",
              "platy_api_key_test",
              "platy_secret_key_test",
              "username_test",
            ];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Masterpay") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["merchant_private_key_live"]
          : ["merchant_private_key_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Neonpay") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["merchant_key_live", "password_live"]
          : ["merchant_key_test", "password_test"]
          // ? ["merchant_key_live", "password_live", "term_url_3ds_live"]
          // : ["merchant_key_test", "password_test", "term_url_3ds_test"]
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } else if (type === "Bm2pay") {
      const data =
        formValues?.is_live === false || formValues?.is_live === undefined
          ? ["client_id_live", "client_secret_live"]
          : ["client_id_test", "client_secret_test"];
      data?.map((item) => {
        return escape_arr?.push(item);
      });
    } 

    let verifier: any;
    Object.keys(formValues).forEach((key: any) => {
      let temp_arr = escape_arr.filter((ele) => key === ele);
      console.log("test prod ::: temp arr", { temp_arr });

      if (!(temp_arr.length > 0)) {
        if (!formValues[key as keyof any]) {
          verifier = { ...verifier, [key]: "Please enter a value" };
        }
      }
    });

    console.log("verifier",verifier);

    if(credentialsType == 'predefined') {
      if(selectedCredential == "") {
        setCredentialError("Please Select Predefined Credentials");
        return false;
      } else {
        setCredentialError("")
      }
    }

    setFormValuesErr(verifier);
    validateCinetCardTypes();
    validateCinetAdditionalFees();
    validateVelocityEntries();

    hasEmptyValuesInClient_additional_fees = Object?.keys(
      additional_fees_filter_error
    )
      .map((objKey: any) => {
        if (additional_fees_filter_error[objKey]?.percentage_fee) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.fixed_fee) {
          return true;
        }
        if (additional_fees_filter_error[objKey]?.currency_code) {
          return true;
        }

        if (additional_fees_filter_error[objKey]?.number_of_days) {
          return true;
        }
        return false;
      })
      ?.some((sData: boolean) => {
        return sData == true;
      });

    if (hasEmptyValuesInClient_additional_fees) {
      console.log(
        "test prod ::: hasEmptyValuesInClient_additional_fees",
        hasEmptyValuesInClient_additional_fees
      );
      return false;
    }
    if (isValidateVelocity) {
      console.log("test prod ::: isValidateVelocity", isValidateVelocity);
      return false;
    }

    if (isValidateAdditionalFees) {
      console.log(
        "test prod ::: isValidateAdditionalFees",
        isValidateAdditionalFees
      );
      return false;
    }
    if (isValidateAdditionalFeesPricing) {
      console.log(
        "test prod ::: isValidateAdditionalFeesPricing",
        isValidateAdditionalFeesPricing
      );
      return false;
    }   

    const hasEmptyValuesInClient_card_types = filterError
      ?.map((objData: any) => {
        if (objData?.percentage_fee) {
          return true;
        }
        if (objData?.fixed_fee) {
          return true;
        }
        if (objData?.currency_code) {
          return true;
        }
        if (objData?.fixed_fee) {
          return true;
        }
        if (objData?.cost_percentage_fee) {
          return true;
        }
        if (objData?.cost_fixed_fee) {
          return true;
        }
        if (objData?.cost_currency_code) {
          return true;
        }

        return false;
      })
      ?.some((sData: boolean) => {
        return sData == true;
      });

    if (hasEmptyValuesInClient_card_types) {
      console.log(
        "test prod ::: hasEmptyValuesInClient_card_types",
        hasEmptyValuesInClient_card_types
      );
      return false;
    }

    const hasEmptyValues = Object.entries(formValues).some(([key, value]) => {
      let temp_arr_ = escape_arr.filter((ele) => key === ele);
      if (!(temp_arr_.length > 0)) {
        return typeof value === "string"
          ? value?.toString()?.trim() === ""
          : value === "";
      }
    });

    if (hasEmptyValues) {
      console.log("test prod ::: hasEmptyValues", hasEmptyValues);
      return false;
    }

    return true;
  }

  const handleSubmit = () => {
    

    let validate = validateData();
    
    const bank_account_number_details = [...Array(bankInfoCount)]
      .map((_, index) => {
        let accountNo = formValues?.[`accountNo_${index}`];
        let transitNo = formValues?.[`transitNo_${index}`];
        let institutionNo = formValues?.[`institutionNo_${index}`];

        if (accountNo && transitNo && institutionNo) {
          delete formValues[`accountNo_${index}`];
          delete formValues[`transitNo_${index}`];
          delete formValues[`institutionNo_${index}`];
          return {
            account_number: accountNo,
            transit_number: transitNo,
            institution_number: institutionNo,
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);

    setFormValues({
      ...formValues,
      bank_account_number_details: bank_account_number_details,
    });
 
    if (validate) {
      let savedgateway = addedgateways.filter((data) => data?.name === type);
      let saved_gateway_id =
        savedgateway.length > 0 ? savedgateway[0]?.client_id : "";
      let gateway = gatewayTypes.filter((data) => data?.name === type);
      let gateway_id = gateway && gateway.length > 0 ? gateway[0]?.id : "";
      let newVALUE;
      if (type === "Memphis") {
        newVALUE = {
          commerce_id_live:
            formValues?.is_live === true
              ? formValues?.commerce_id_live
                ? formValues?.commerce_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.commerce_id_live
              ? savedgateway[0]?.inputs?.commerce_id_live
              : "",
          commerce_id_test:
            formValues?.is_live === false
              ? formValues?.commerce_id_test
                ? formValues?.commerce_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.commerce_id_test
              ? savedgateway[0]?.inputs?.commerce_id_test
              : "",
          commerce_name_live:
            formValues?.is_live === true
              ? formValues?.commerce_name_live
                ? formValues?.commerce_name_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.commerce_name_live
              ? savedgateway[0]?.inputs?.commerce_name_live
              : "",
          commerce_name_test:
            formValues?.is_live === false
              ? formValues?.commerce_name_test
                ? formValues?.commerce_name_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.commerce_name_test
              ? savedgateway[0]?.inputs?.commerce_name_test
              : "",
          tkr_live:
            formValues?.is_live === true
              ? formValues?.tkr_live
                ? formValues?.tkr_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.tkr_live
              ? savedgateway[0]?.inputs?.tkr_live
              : "",
          tkr_test:
            formValues?.is_live === false
              ? formValues?.tkr_test
                ? formValues?.tkr_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.tkr_test
              ? savedgateway[0]?.inputs?.tkr_test
              : "",
          descriptor: formValues?.descriptor,

          priority: formValues?.priority,
        };
      }
      if (type === "Pix") {
        newVALUE = {
          name_live:
            formValues?.is_live === true
              ? formValues?.name_live
                ? formValues?.name_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.name_live
              ? savedgateway[0]?.inputs?.name_live
              : "",
          name_test:
            formValues?.is_live === false
              ? formValues?.name_test
                ? formValues?.name_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.name_test
              ? savedgateway[0]?.inputs?.name_test
              : "",

          email_live:
            formValues?.is_live === true
              ? formValues?.email_live
                ? formValues?.email_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.email_live
              ? savedgateway[0]?.inputs?.email_live
              : "",
          // business_name: formValues?.business_name
          //   ? formValues?.business_name?.trim()
          //   : "",
          email_test:
            formValues?.is_live === false
              ? formValues?.email_test
                ? formValues?.email_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.email_test
              ? savedgateway[0]?.inputs?.email_test
              : "",

          descriptor: formValues?.descriptor,
          priority: formValues?.priority,
        };
      }
      if (type === "Stripe") {
        newVALUE = {
          statement_descriptor: formValues?.statement_descriptor
            ? formValues?.statement_descriptor
            : "",
          statement_descriptor_suffix: formValues?.statement_descriptor_suffix
            ? formValues?.statement_descriptor_suffix
            : "",
          secret_key_live:
            formValues?.is_live === true
              ? formValues?.secret_key_live
                ? formValues?.secret_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.secret_key_live
              ? savedgateway[0]?.inputs?.secret_key_live
              : "",
          secret_key_test:
            formValues?.is_live === false
              ? formValues?.secret_key_test
                ? formValues?.secret_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.secret_key_test?.trim()
              ? savedgateway[0]?.inputs?.secret_key_test
              : "",
          priority: formValues?.priority,
        };
      }
      if (type === "Raypd") {
        newVALUE = {
          secret_key_live:
            formValues?.is_live === true
              ? formValues?.secret_key_live
                ? formValues?.secret_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.secret_key_live
              ? savedgateway[0]?.inputs?.secret_key_live
              : "",
          secret_key_test:
            formValues?.is_live === false
              ? formValues?.secret_key_test
                ? formValues?.secret_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.secret_key_test
              ? savedgateway[0]?.inputs?.secret_key_test
              : "",
          mid_country_live:
            formValues?.is_live === true
              ? formValues?.mid_country_live
                ? formValues?.mid_country_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.mid_country_live
              ? savedgateway[0]?.inputs?.mid_country_live
              : "",
          mid_country_test:
            formValues?.is_live === false
              ? formValues?.mid_country_test
                ? formValues?.mid_country_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.mid_country_test
              ? savedgateway[0]?.inputs?.mid_country_test
              : "",
          access_key_live:
            formValues?.is_live === true
              ? formValues?.access_key_live
                ? formValues?.access_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.access_key_live
              ? savedgateway[0]?.inputs?.access_key_live
              : "",
          access_key_test:
            formValues?.is_live === false
              ? formValues?.access_key_test
                ? formValues?.access_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.access_key_test
              ? savedgateway[0]?.inputs?.access_key_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Banwire") {
        newVALUE = {
          username_live:
            formValues?.is_live === true
              ? formValues?.username_live
                ? formValues?.username_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_live
              ? savedgateway[0]?.inputs?.username_live
              : "",
          username_test:
            formValues?.is_live === false
              ? formValues?.username_test
                ? formValues?.username_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_test
              ? savedgateway[0]?.inputs?.username_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Valitor") {
        newVALUE = {
          apikey_live:
            formValues?.is_live === true
              ? formValues?.apikey_live
                ? formValues?.apikey_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.apikey_live
              ? savedgateway[0]?.inputs?.apikey_live
              : "",
          apikey_test:
            formValues?.is_live === false
              ? formValues?.apikey_test
                ? formValues?.apikey_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.apikey_test
              ? savedgateway[0]?.inputs?.apikey_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Bambora") {
        newVALUE = {
          merchantId_live:
            formValues?.is_live === true
              ? formValues?.merchantId_live
                ? formValues?.merchantId_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchantId_live
              ? savedgateway[0]?.inputs?.merchantId_live
              : "",
          merchantId_test:
            formValues?.is_live === false
              ? formValues?.merchantId_test
                ? formValues?.merchantId_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchantId_test
              ? savedgateway[0]?.inputs?.merchantId_test
              : "",
          passcode_live:
            formValues?.is_live === true
              ? formValues?.passcode_live
                ? formValues?.passcode_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.passcode_live
              ? savedgateway[0]?.inputs?.passcode_live
              : "",
          passcode_test:
            formValues?.is_live === false
              ? formValues?.passcode_test
                ? formValues?.passcode_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.passcode_test
              ? savedgateway[0]?.inputs?.passcode_test
              : "",
          hash_key_test:
            formValues?.is_live === false
              ? formValues?.hash_key_test
                ? formValues?.hash_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.hash_key_test
              ? savedgateway[0]?.inputs?.hash_key_test
              : "",
          hash_key_live:
            formValues?.is_live === true
              ? formValues?.hash_key_live
                ? formValues?.hash_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.hash_key_live
              ? savedgateway[0]?.inputs?.hash_key_live
              : "",
          recurring_passcode_test:
            formValues?.is_live === false
              ? formValues?.recurring_passcode_test
                ? formValues?.recurring_passcode_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.recurring_passcode_test
              ? savedgateway[0]?.inputs?.recurring_passcode_test
              : "",
          recurring_passcode_live:
            formValues?.is_live === true
              ? formValues?.recurring_passcode_live
                ? formValues?.recurring_passcode_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.recurring_passcode_live
              ? savedgateway[0]?.inputs?.recurring_passcode_live
              : "",
          reporting_passcode_test:
            formValues?.is_live === false
              ? formValues?.reporting_passcode_test
                ? formValues?.reporting_passcode_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.reporting_passcode_test
              ? savedgateway[0]?.inputs?.reporting_passcode_test
              : "",
          reporting_passcode_live:
            formValues?.is_live === true
              ? formValues?.reporting_passcode_live
                ? formValues?.reporting_passcode_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.reporting_passcode_live
              ? savedgateway[0]?.inputs?.reporting_passcode_live
              : "",
          batch_passcode_test:
            formValues?.is_live === false
              ? formValues?.batch_passcode_test
                ? formValues?.batch_passcode_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.batch_passcode_test
              ? savedgateway[0]?.inputs?.batch_passcode_test
              : "",
          batch_passcode_live:
            formValues?.is_live === true
              ? formValues?.batch_passcode_live
                ? formValues?.batch_passcode_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.batch_passcode_live
              ? savedgateway[0]?.inputs?.batch_passcode_live
              : "",
          subscription_passcode_test:
            formValues?.is_live === false
              ? formValues?.subscription_passcode_test
                ? formValues?.subscription_passcode_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.subscription_passcode_test
              ? savedgateway[0]?.inputs?.subscription_passcode_test
              : "",
          subscription_passcode_live:
            formValues?.is_live === true
              ? formValues?.subscription_passcode_live
                ? formValues?.subscription_passcode_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.subscription_passcode_live
              ? savedgateway[0]?.inputs?.subscription_passcode_live
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Digitarium") {
        newVALUE = {
          merchantKey_live:
            formValues?.is_live === true
              ? formValues?.merchantKey_live
                ? formValues?.merchantKey_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchantKey_live
              ? savedgateway[0]?.inputs?.merchantKey_live
              : "",
          merchantKey_test:
            formValues?.is_live === false
              ? formValues?.merchantKey_test
                ? formValues?.merchantKey_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchantKey_test
              ? savedgateway[0]?.inputs?.merchantKey_test
              : "",
          password_live:
            formValues?.is_live === true
              ? formValues?.password_live
                ? formValues?.password_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_live
              ? savedgateway[0]?.inputs?.password_live
              : "",
          password_test:
            formValues?.is_live === false
              ? formValues?.password_test
                ? formValues?.password_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_test
              ? savedgateway[0]?.inputs?.password_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Tangoindia") {
        newVALUE = {
          api_id_live:
            formValues?.is_live === true
              ? formValues?.api_id_live
                ? formValues?.api_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_id_live
              ? savedgateway[0]?.inputs?.api_id_live
              : "",
          api_id_test:
            formValues?.is_live === false
              ? formValues?.api_id_test
                ? formValues?.api_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_id_test
              ? savedgateway[0]?.inputs?.api_id_test
              : "",
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "G2Pay") {
        newVALUE = {
          signing_key_live:
            formValues?.is_live === true
              ? formValues?.signing_key_live
                ? formValues?.signing_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.signing_key_live
              ? savedgateway[0]?.inputs?.signing_key_live
              : "",
          signing_key_test:
            formValues?.is_live === false
              ? formValues?.signing_key_test
                ? formValues?.signing_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.signing_key_test
              ? savedgateway[0]?.inputs?.signing_key_test
              : "",
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Moneto") {
        newVALUE = {
          merchant_secret_key_live:
            formValues?.is_live === true
              ? formValues?.merchant_secret_key_live
                ? formValues?.merchant_secret_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_secret_key_live
              ? savedgateway[0]?.inputs?.merchant_secret_key_live
              : "",
          merchant_secret_key_test:
            formValues?.is_live === false
              ? formValues?.merchant_secret_key_test
                ? formValues?.merchant_secret_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_secret_key_test
              ? savedgateway[0]?.inputs?.merchant_secret_key_test
              : "",
          merchant_id_live:
            formValues?.is_live === true
              ? formValues?.merchant_id_live
                ? formValues?.merchant_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_live
              ? savedgateway[0]?.inputs?.merchant_id_live
              : "",
          merchant_id_test:
            formValues?.is_live === false
              ? formValues?.merchant_id_test
                ? formValues?.merchant_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_test
              ? savedgateway[0]?.inputs?.merchant_id_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Re-set") {
        newVALUE = {
          secretKey_live:
            formValues?.is_live === true
              ? formValues?.secretKey_live
                ? formValues?.secretKey_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.secretKey_live
              ? savedgateway[0]?.inputs?.secretKey_live
              : "",
          secretKey_test:
            formValues?.is_live === false
              ? formValues?.secretKey_test
                ? formValues?.secretKey_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.secretKey_test
              ? savedgateway[0]?.inputs?.secretKey_test
              : "",
          brand_id_live:
            formValues?.is_live === true
              ? formValues?.brand_id_live
                ? formValues?.brand_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.brand_id_live
              ? savedgateway[0]?.inputs?.brand_id_live
              : "",
          brand_id_test:
            formValues?.is_live === false
              ? formValues?.brand_id_test
                ? formValues?.brand_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.brand_id_test
              ? savedgateway[0]?.inputs?.brand_id_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "MIT") {
        newVALUE = {
          id_branch_live:
            formValues?.is_live === true
              ? formValues?.id_branch_live
                ? formValues?.id_branch_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.id_branch_live
              ? savedgateway[0]?.inputs?.id_branch_live
              : "",
          id_branch_test:
            formValues?.is_live === false
              ? formValues?.id_branch_test
                ? formValues?.id_branch_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.id_branch_test
              ? savedgateway[0]?.inputs?.id_branch_test
              : "",
          id_company_live:
            formValues?.is_live === true
              ? formValues?.id_company_live
                ? formValues?.id_company_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.id_company_live
              ? savedgateway[0]?.inputs?.id_company_live
              : "",
          id_company_test:
            formValues?.is_live === false
              ? formValues?.id_company_test
                ? formValues?.id_company_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.id_company_test
              ? savedgateway[0]?.inputs?.id_company_test
              : "",

          merchant_id_live:
            formValues?.is_live === true
              ? formValues?.merchant_id_live
                ? formValues?.merchant_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_live
              ? savedgateway[0]?.inputs?.merchant_id_live
              : "",
          merchant_id_test:
            formValues?.is_live === false
              ? formValues?.merchant_id_test
                ? formValues?.merchant_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_test
              ? savedgateway[0]?.inputs?.merchant_id_test
              : "",

          password_live:
            formValues?.is_live === true
              ? formValues?.password_live
                ? formValues?.password_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_live
              ? savedgateway[0]?.inputs?.password_live
              : "",
          password_test:
            formValues?.is_live === false
              ? formValues?.password_test
                ? formValues?.password_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_test
              ? savedgateway[0]?.inputs?.password_test
              : "",

          user_code_live:
            formValues?.is_live === true
              ? formValues?.user_code_live
                ? formValues?.user_code_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.user_code_live
              ? savedgateway[0]?.inputs?.user_code_live
              : "",
          user_code_test:
            formValues?.is_live === false
              ? formValues?.user_code_test
                ? formValues?.user_code_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.user_code_test
              ? savedgateway[0]?.inputs?.user_code_test
              : "",
          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Betapay") {
        newVALUE = {
          api_token_live:
            formValues?.is_live === true
              ? formValues?.api_token_live
                ? formValues?.api_token_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_token_live
              ? savedgateway[0]?.inputs?.api_token_live
              : "",
          api_token_test:
            formValues?.is_live === false
              ? formValues?.api_token_test
                ? formValues?.api_token_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_token_test
              ? savedgateway[0]?.inputs?.api_token_test
              : "",
          merchant_id_live:
            formValues?.is_live === true
              ? formValues?.merchant_id_live
                ? formValues?.merchant_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_live
              ? savedgateway[0]?.inputs?.merchant_id_live
              : "",
          merchant_id_test:
            formValues?.is_live === true
              ? formValues?.merchant_id_test
                ? formValues?.merchant_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_test
              ? savedgateway[0]?.inputs?.merchant_id_test
              : "",
          terminal_id_live:
            formValues?.is_live === true
              ? formValues?.terminal_id_live
                ? formValues?.terminal_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.terminal_id_live
              ? savedgateway[0]?.inputs?.terminal_id_live
              : "",
          terminal_id_test:
            formValues?.is_live === true
              ? formValues?.terminal_id_test
                ? formValues?.terminal_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.terminal_id_test
              ? savedgateway[0]?.inputs?.terminal_id_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Aurea Via") {
        newVALUE = {
          companyNum_live:
            formValues?.is_live === true
              ? formValues?.companyNum_live
                ? formValues?.companyNum_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.companyNum_live
              ? savedgateway[0]?.inputs?.companyNum_live
              : "",
          companyNum_test:
            formValues?.is_live === false
              ? formValues?.companyNum_test
                ? formValues?.companyNum_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.companyNum_test
              ? savedgateway[0]?.inputs?.companyNum_test
              : "",
          personalHashkey_live:
            formValues?.is_live === true
              ? formValues?.personalHashkey_live
                ? formValues?.personalHashkey_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.personalHashkey_live
              ? savedgateway[0]?.inputs?.personalHashkey_live
              : "",
          personalHashkey_test:
            formValues?.is_live === false
              ? formValues?.personalHashkey_test
                ? formValues?.personalHashkey_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.personalHashkey_test
              ? savedgateway[0]?.inputs?.personalHashkey_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Kasha") {
        newVALUE = {
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",

          merchant_domain_live:
            formValues?.is_live === true
              ? formValues?.merchant_domain_live
                ? formValues?.merchant_domain_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_domain_live
              ? savedgateway[0]?.inputs?.merchant_domain_live
              : "",
          merchant_domain_test:
            formValues?.is_live === false
              ? formValues?.merchant_domain_test
                ? formValues?.merchant_domain_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_domain_test
              ? savedgateway[0]?.inputs?.merchant_domain_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Linx") {
        newVALUE = {
          client_id_live:
            formValues?.is_live === true
              ? formValues?.client_id_live
                ? formValues?.client_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_id_live
              ? savedgateway[0]?.inputs?.client_id_live
              : "",
          client_id_test:
            formValues?.is_live === false
              ? formValues?.client_id_test
                ? formValues?.client_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_id_test
              ? savedgateway[0]?.inputs?.client_id_test
              : "",

          client_secret_live:
            formValues?.is_live === true
              ? formValues?.client_secret_live
                ? formValues?.client_secret_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_secret_live
              ? savedgateway[0]?.inputs?.client_secret_live
              : "",
          client_secret_test:
            formValues?.is_live === false
              ? formValues?.client_secret_test
                ? formValues?.client_secret_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_secret_test
              ? savedgateway[0]?.inputs?.client_secret_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Supefina") {
        newVALUE = {
          merchant_id_live:
            formValues?.is_live === true
              ? formValues?.merchant_id_live
                ? formValues?.merchant_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_live
              ? savedgateway[0]?.inputs?.merchant_id_live
              : "",
          merchant_id_test:
            formValues?.is_live === false
              ? formValues?.merchant_id_test
                ? formValues?.merchant_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.merchant_id_test
              ? savedgateway[0]?.inputs?.merchant_id_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Platy") {
        newVALUE = {
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",
          password_live:
            formValues?.is_live === true
              ? formValues?.password_live
                ? formValues?.password_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_live
              ? savedgateway[0]?.inputs?.password_live
              : "",
          password_test:
            formValues?.is_live === false
              ? formValues?.password_test
                ? formValues?.password_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_test
              ? savedgateway[0]?.inputs?.password_test
              : "",

          platy_api_key_live:
            formValues?.is_live === false
              ? formValues?.platy_api_key_live
                ? formValues?.platy_api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_api_key_live
              ? savedgateway[0]?.inputs?.platy_api_key_live
              : "",

          platy_api_key_test:
            formValues?.is_live === false
              ? formValues?.platy_api_key_test
                ? formValues?.platy_api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_api_key_test
              ? savedgateway[0]?.inputs?.platy_api_key_test
              : "",

          platy_secret_key_live:
            formValues?.is_live === false
              ? formValues?.platy_secret_key_live
                ? formValues?.platy_secret_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_secret_key_live
              ? savedgateway[0]?.inputs?.platy_secret_key_live
              : "",

          platy_secret_key_test:
            formValues?.is_live === false
              ? formValues?.platy_secret_key_test
                ? formValues?.platy_secret_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_secret_key_test
              ? savedgateway[0]?.inputs?.platy_secret_key_test
              : "",

          username_live:
            formValues?.is_live === false
              ? formValues?.username_live
                ? formValues?.username_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_live
              ? savedgateway[0]?.inputs?.username_live
              : "",

          username_test:
            formValues?.is_live === false
              ? formValues?.username_test
                ? formValues?.username_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_test
              ? savedgateway[0]?.inputs?.username_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "3xpay") {
        newVALUE = {
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",
          
          api_secret_live:
            formValues?.is_live === false
              ? formValues?.api_secret_live
                ? formValues?.api_secret_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_secret_live
              ? savedgateway[0]?.inputs?.api_secret_live
              : "",

          api_secret_test:
            formValues?.is_live === false
              ? formValues?.api_secret_test
                ? formValues?.api_secret_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_secret_test
              ? savedgateway[0]?.inputs?.api_secret_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Platy 2DS") {
        newVALUE = {
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",
          password_live:
            formValues?.is_live === true
              ? formValues?.password_live
                ? formValues?.password_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_live
              ? savedgateway[0]?.inputs?.password_live
              : "",
          password_test:
            formValues?.is_live === false
              ? formValues?.password_test
                ? formValues?.password_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_test
              ? savedgateway[0]?.inputs?.password_test
              : "",

          platy_api_key_live:
            formValues?.is_live === false
              ? formValues?.platy_api_key_live
                ? formValues?.platy_api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_api_key_live
              ? savedgateway[0]?.inputs?.platy_api_key_live
              : "",

          platy_api_key_test:
            formValues?.is_live === false
              ? formValues?.platy_api_key_test
                ? formValues?.platy_api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_api_key_test
              ? savedgateway[0]?.inputs?.platy_api_key_test
              : "",

          platy_secret_key_live:
            formValues?.is_live === false
              ? formValues?.platy_secret_key_live
                ? formValues?.platy_secret_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_secret_key_live
              ? savedgateway[0]?.inputs?.platy_secret_key_live
              : "",

          platy_secret_key_test:
            formValues?.is_live === false
              ? formValues?.platy_secret_key_test
                ? formValues?.platy_secret_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_secret_key_test
              ? savedgateway[0]?.inputs?.platy_secret_key_test
              : "",

          username_live:
            formValues?.is_live === false
              ? formValues?.username_live
                ? formValues?.username_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_live
              ? savedgateway[0]?.inputs?.username_live
              : "",

          username_test:
            formValues?.is_live === false
              ? formValues?.username_test
                ? formValues?.username_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_test
              ? savedgateway[0]?.inputs?.username_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Platy 3DS") {
        newVALUE = {
          api_key_live:
            formValues?.is_live === true
              ? formValues?.api_key_live
                ? formValues?.api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_live
              ? savedgateway[0]?.inputs?.api_key_live
              : "",
          api_key_test:
            formValues?.is_live === false
              ? formValues?.api_key_test
                ? formValues?.api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.api_key_test
              ? savedgateway[0]?.inputs?.api_key_test
              : "",
          password_live:
            formValues?.is_live === true
              ? formValues?.password_live
                ? formValues?.password_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_live
              ? savedgateway[0]?.inputs?.password_live
              : "",
          password_test:
            formValues?.is_live === false
              ? formValues?.password_test
                ? formValues?.password_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.password_test
              ? savedgateway[0]?.inputs?.password_test
              : "",

          platy_api_key_live:
            formValues?.is_live === false
              ? formValues?.platy_api_key_live
                ? formValues?.platy_api_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_api_key_live
              ? savedgateway[0]?.inputs?.platy_api_key_live
              : "",

          platy_api_key_test:
            formValues?.is_live === false
              ? formValues?.platy_api_key_test
                ? formValues?.platy_api_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_api_key_test
              ? savedgateway[0]?.inputs?.platy_api_key_test
              : "",

          platy_secret_key_live:
            formValues?.is_live === false
              ? formValues?.platy_secret_key_live
                ? formValues?.platy_secret_key_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_secret_key_live
              ? savedgateway[0]?.inputs?.platy_secret_key_live
              : "",

          platy_secret_key_test:
            formValues?.is_live === false
              ? formValues?.platy_secret_key_test
                ? formValues?.platy_secret_key_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.platy_secret_key_test
              ? savedgateway[0]?.inputs?.platy_secret_key_test
              : "",

          username_live:
            formValues?.is_live === false
              ? formValues?.username_live
                ? formValues?.username_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_live
              ? savedgateway[0]?.inputs?.username_live
              : "",

          username_test:
            formValues?.is_live === false
              ? formValues?.username_test
                ? formValues?.username_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.username_test
              ? savedgateway[0]?.inputs?.username_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (type === "Bm2pay") {
        newVALUE = {
          client_id_live:
            formValues?.is_live === true
              ? formValues?.client_id_live
                ? formValues?.client_id_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_id_live
              ? savedgateway[0]?.inputs?.client_id_live
              : "",
          client_id_test:
            formValues?.is_live === false
              ? formValues?.client_id_test
                ? formValues?.client_id_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_id_test
              ? savedgateway[0]?.inputs?.client_id_test
              : "",

          client_secret_live:
            formValues?.is_live === true
              ? formValues?.client_secret_live
                ? formValues?.client_secret_live?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_secret_live
              ? savedgateway[0]?.inputs?.client_secret_live
              : "",
          client_secret_test:
            formValues?.is_live === false
              ? formValues?.client_secret_test
                ? formValues?.client_secret_test?.trim()
                : ""
              : savedgateway[0]?.inputs?.client_secret_test
              ? savedgateway[0]?.inputs?.client_secret_test
              : "",

          descriptor: formValues?.descriptor,
        };
      }
      if (gateway_id) {
        setIsLoading(true);
        let NewWalletDetails: any = [];
        if (
          formValues?.wallet_ids?.filter((f: any) => f?.wallet_address)
            ?.length > 0
        ) {
          formValues?.wallet_ids
            ?.filter((f: any) => f?.wallet_address)
            ?.forEach((data: any) => {
              NewWalletDetails.push(data);
            });
        }
        if (formValues?.ewallet_New_addressess?.length > 0) {
          formValues?.ewallet_New_addressess?.forEach((data: any) => {
            NewWalletDetails.push(data);
          });
        }

        let newFormValuesForPayload = {
          ...formValues,
        };

        Object.keys(newFormValuesForPayload)?.forEach((key) => {
          if (
            [
              "client_card_types",
              "additional_fees",
              "payout_fees",
              "wallet_ids",
              "disCard_walletes",
              "ewallet_New_addressess",
              "bank_account_numbers",
              "cardTypes",
              "fess_conditions",
              "payment_methods",
              "velocity",
              "advanced_avs",
              "whitelist_enabled",
              "cost",
            ].includes(key)
          ) {
            delete newFormValuesForPayload[key];
          }
        });
        const filterPaymentMethod = formValues?.payment_methods?.map(
          (i: any) => {
            return {
              ...i,
              name: i.name.toLowerCase(),
            };
          }
        );
        const defaultAVS = {
          validate_address : false,
          validate_phone_number : false,
          validate_email : false,
          validate_ip_address : false
        }

        const newAuthInfo = {
          ...(
            type === "Pix" || type === "Stripe"
              ? newVALUE
              : newFormValuesForPayload
          ),
          credential_type : credentialsType,
          predefinedCredentials : selectedPredefinedCredentials ? selectedPredefinedCredentials : null
        };

        const inputs = {
          client_id: id,
          gateway_id: gateway_id,
          method: saved_gateway_id ? "put" : "post",
          body: {
            auth_info: newAuthInfo
          },
          is_live: formValues?.is_live ? formValues?.is_live : false,
          to_convert: currencyConv == "requestPassthrough" ? false : true,
          new_currency:
            currencyConv == "requestPassthrough" ? "none" : selectedCurrency,
          reserved_pricing: {
            client_card_types: formValues?.client_card_types?.map((m: any) => {
              return {
                card_name: m?.card_name,
                percentage_fee: m?.percentage_fee,
                fixed_fee: m?.fixed_fee,
                currency_code: m?.currency_code,
                allow_card: m?.allow_card,
              };
            }),
            additional_fees: {
              chargeback_fees: formValues?.additional_fees?.chargeback_fees,
              dispute_fees: formValues?.additional_fees?.dispute_fees,
              reserve_fees: formValues?.additional_fees?.reserve_fees,
              refunded_tnx_fees: formValues?.additional_fees?.refunded_tnx_fees,
              declined_tnx_fees: formValues?.additional_fees?.declined_tnx_fees,
            },
            // gateway_method_fees: formValues?.gateway_method_fees,
            gateway_method_fees: {
              apm_fees: formValues?.gateway_method_fees?.apm_fees,
              payout_fees: formValues?.gateway_method_fees?.payout_fees,
              payin_fees: formValues?.gateway_method_fees?.payin_fees,
            },
            // client_cost: formValues?.client_cost,
          },
          settlement: {
            payout_fees: formValues?.payout_fees,
            ewallet_addressess: NewWalletDetails,
            discarded_wallets: formValues?.disCard_walletes,
          },
          cost: {
            client_card_types: formValues?.client_card_types?.map((m: any) => {
              return {
                card_name: m?.card_name,
                percentage_fee: m?.cost_percentage_fee,
                fixed_fee: m?.cost_fixed_fee,
                currency_code: m?.cost_currency_code,
                allow_card: m?.allow_card,
              };
            }),
            additional_fees: {
              chargeback_fees:
                formValues?.additional_fees?.cost_chargeback_fees,
              dispute_fees: formValues?.additional_fees?.cost_dispute_fees,
              reserve_fees: formValues?.additional_fees?.cost_reserve_fees,
              refunded_tnx_fees:
                formValues?.additional_fees?.cost_refunded_tnx_fees,
              declined_tnx_fees:
                formValues?.additional_fees?.cost_declined_tnx_fees,
            },
            gateway_method_fees: {
              apm_fees: formValues?.gateway_method_fees?.cost_apm_fees,
              payout_fees: formValues?.gateway_method_fees?.cost_payout_fees,
              payin_fees: formValues?.gateway_method_fees?.cost_payin_fees,
            },
          },
          velocity: formValues?.velocity,
          advanced_avs: (formValues?.advanced_avs) ? formValues?.advanced_avs : defaultAVS,
          payment_method: { filterPaymentMethod },
          is_active: formValues?.is_active, //formValues.payment_methods,
          whitelist_enabled:
            formValues?.whitelist_enabled == undefined
              ? false
              : formValues?.whitelist_enabled,
        };


        console.log("test prod ::: inputs", { inputs });

        createClientGateway(inputs)
          .then((data) => {
            fetchClients();
            toast.success("Client gateway saved");
            fetchClients();
          })
          .catch((err) => {
            console.log("test prod ::: error in create client", err);
            toast.error(
              err?.response?.data?.message ?? "Client gateway adding failed"
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleToggle = () => {
    setSelectedCredential("");
    setSelectedPredefinedCredentials(null);
    setCredentialsType("custom");
    setFormValues({
      ...formValues,
      is_live: !formValues.is_live,
    });
  };

  const handleActiveToggle = (type: any) => {
    
    setFormValues({
      ...formValues,
      is_active: !formValues.is_active,
    });
    const filterGateway = gatewayTypes.map((i) => {
      if (i.name === type?.target?.id) {
        return {
          ...i,
          is_active: !i.is_active,
        };
      } else {
        return {
          ...i,
          is_active: i.is_active,
        };
      }
    });
  };

  const handleAddBankInfo = () => {
    setBankInfoCount((prevCount) => prevCount + 1);
  };

  const handleRemoveBankInfo = () => {
    setBankInfoCount((prevCount) => prevCount - 1);
  };

  const handleAddWallet = () => {
    setWalletCount((prevCount) => prevCount + 1);
  };

  const handleRemoveWallet = () => {
    setWalletCount((prevCount) => prevCount - 1);
  };

  const onCloseWalletAddress = () => {
    setIsOpenWalletAddress(false);
    setWallertVerifyIput({});
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isOpenWalletAddress ? "hidden" : "auto";
  }, [isOpenWalletAddress]);

  return (
    <>
      <button className="text-blue-500 outline-none" title="Payment Gateways">
        <MdIntegrationInstructions
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            fetchAllGateways();
            fetchAddedGateways();
            onOpen();
          }}
        />
      </button> 
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[70vh] max-w-[800px] overflow-auto rounded-[20px] !p-3 sm:my-8 sm:min-h-[100vh] sm:w-full"> */}
          {/* <ModalBody className="scrollbarhide max-h-[70vh] overflow-auto rounded-[20px] sm:max-h-[100vh]"> */}
            {/* <Card extra=" max-w-[800px] px-[30px] pt-[35px] pb-[35px] flex flex-col !z-[1004] "> */}
            <Card2 extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
              isOpen && !isCloseModal ? "card-enter" : "card-exit"
            }`}>
              <ModalHeader className="flex py-[20px] mb-[10px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
              <MdHighlightOff
                className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                onClick={handleClose}
              />
              {infoData?.cell?.row?.original?.name}
              {" - "}Payment Gateways
              </ModalHeader>
              <ModalBody className="p-[25px]">
                <div className="flex max-w-[940px]  items-center gap-2 overflow-auto pb-[10px] scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
                  {gatewayTypes.map((data: iGateway) => {
                    return (
                      <Card
                        extra={`p-2 bg-gray-100 border w-[150px] min-w-[150px] cursor-pointer ${
                          data.name === type
                            ? "border-indigo-400"
                            : "border-gray-200 dark:!border-white/10"
                        }`}
                        onClick={() => {
                          setType(data?.name);
                          setHashID(data?.id);
                          setGatewayTypes(gatewayTypesCopy);
                        }}
                        key={Math.random()}
                      >
                        <div className="position-relative flex h-full  w-32 flex-col items-center justify-between gap-2">
                          <div
                            className={`w-100 end-0 top-0 m-2 px-1 ${
                              addedgateways?.filter(
                                (f: any) => f?.name == data?.name
                              )?.length > 0
                                ? addedgateways?.filter(
                                    (f: any) => f?.name == data?.name
                                  )[0]?.is_active
                                  ? "text-green-500"
                                  : "text-red-500"
                                : data?.is_active
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                            style={{
                              position: "absolute",
                              backgroundColor:
                                addedgateways?.filter(
                                  (f: any) => f?.name == data?.name
                                )?.length > 0
                                  ? addedgateways?.filter(
                                      (f: any) => f?.name == data?.name
                                    )[0]?.is_active
                                    ? "#bcf1bc"
                                    : "#f7b4b4"
                                  : data?.is_active
                                  ? "#bcf1bc"
                                  : "#f7b4b4",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <b>
                              {addedgateways?.filter(
                                (f: any) => f?.name == data?.name
                              )?.length > 0
                                ? addedgateways?.filter(
                                    (f: any) => f?.name == data?.name
                                  )[0]?.is_active
                                  ? "ACTIVE"
                                  : "INACTIVE"
                                : data?.is_active
                                ? "ACTIVE"
                                : "INACTIVE"}
                            </b>
                            {/* <b>{data?.is_active ? "ACTIVE" : "INACTIVE"}</b> */}
                          </div>
                          {/* )} */}
                          <div className="flex h-full items-center justify-center">
                            {methodName ? (
                              <img
                                src={data?.image}
                                alt={data?.name}
                                className="h-24 w-24 object-contain"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <p className="break-all capitalize">
                            {methodName
                              ? data?.name.replaceAll("_", " ")
                              : data?.id}
                          </p>
                        </div>
                      </Card>
                    );
                  })}
                </div>
                {isLoading && (
                  <DivLoader className="absolute right-2 top-6 m-3 h-6 w-6 border-indigo-500" />
                )}

                <div className="mt-4 flex justify-end">
                  <label className="relative mb-3 inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      className="peer sr-only"
                    // @ts-ignore
                      checked={formValues?.is_active}
                      id={formValues?.type}
                      onChange={handleActiveToggle}
                    />

                    <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />

                    <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                      {formValues?.is_active ? "ACTIVE" : "INACTIVE"}
                    </span>
                  </label>
                </div>
                <Accordion className="w-full" allowToggle >
                  <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                    <h4>
                      <AccordionButton className="flex justify-between">
                        <span className="text-left font-bold text-navy-900 dark:text-white">
                          Credentials
                        </span>
                        <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white ">
                      {type && (
                        <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                          <div className="flex justify-between">
                            <p className="mb-0 text-xl font-bold">
                              {methodName ? (
                                type === "Sipe" ? (
                                  "Integrate SIPE"
                                ) : type === "Blumon" ? (
                                  "Integrate Blumon"
                                ) : type === "Pix" ? (
                                  "Integrate PIX"
                                ) : type === "Pix-Lotus" ? (
                                  "Integrate Pix-Lotus"
                                ) : type === "Stripe" ? (
                                  "Integrate Stripe"
                                ) : type === "Memphis" ? (
                                  "Integrate Memphis"
                                ) : type === "Raypd" ? (
                                  "Integrate Raypd"
                                ) : type === "MIT" ? (
                                  "Integrate MIT"
                                ) : type === "Banwire" ? (
                                  "Integrate Banwire"
                                ) : type === "Valitor" ? (
                                  "Integrate Valitor"
                                ) : type === "Bambora" ? (
                                  "Integrate Bambora"
                                ) : type === "Re-set" ? (
                                  "Integrate Re-set"
                                ) : type === "Scipiopay" ? (
                                  "Integrate Scipiopay"
                                ) : type === "Aurea Via" ? (
                                  "Integrate Aurea Via"
                                ) : type === "Betapay" ? (
                                  "Integrate Betapay"
                                ) : type === "Kasha" ? (
                                  "Integrate Kasha"
                                ) : type === "Linx" ? (
                                  "Integrate Linx"
                                ) : type === "Supefina" ? (
                                  "Integrate Supefina"
                                ) : type === "Platy" ? (
                                  "Integrate Platy"
                                ) : type === "Platy 2DS" ? (
                                  "Integrate Platy 2DS"
                                ) : type === "Platy 3DS" ? (
                                  "Integrate Platy 3DS"
                                ) : type === "Masterpay" ? (
                                  "Integrate Masterpay"
                                ) : type === "Neonpay" ? (
                                  "Integrate Neonpay"
                                ) : type === "Bm2pay" ? (
                                  "Integrate Bm2pay"
                                ) : type === "Moneto" ? (
                                  "Integrate Moneto"
                                ) : type === "3xpay" ? (
                                  "Integrate 3xpay"
                                ) : type === "Tangoindia" ? (
                                  "Integrate Tangoindia"
                                ) : type === "Digitarium" ? (
                                  "Integrate Digitarium"
                                ) : (
                                  ""
                                )
                              ) : (
                                <span className="emethod item-center flex justify-around">
                                  Integrate{" "}
                                  <ShortTruncateCopy
                                    info={hashID.slice(0, hashID.search("-"))}
                                    showCopy={false}
                                  />
                                </span>
                              )}
                            </p>
                            {/* {// console.log("formValues :::", {formValues})} */}

                            {type === "Sipe" ||
                            type === "Blumon" ||
                            type === "Pix" ||
                            type === "Pix-Lotus" ||
                            type === "Stripe" ||
                            type === "Raypd" ||
                            type === "MIT" ||
                            type === "Banwire" ||
                            type === "Valitor" ||
                            type === "Bambora" ||
                            type === "Re-set" ||
                            type === "Memphis" ||
                            type === "Scipiopay" ||
                            type === "Aurea Via" ||
                            type === "Betapay" ||
                            type === "Kasha" ||
                            type === "Linx" ||
                            type === "Supefina" ||
                            type === "Platy" ||
                            type === "3xpay" ||
                            type === "Platy 2DS" ||
                            type === "Platy 3DS" ||
                            type === "Masterpay" ||
                            type === "Bm2pay" ||
                            type === "Digitarium" ||
                            type === "Tangoindia" ||
                            type === "G2Pay" ||
                            type === "Moneto" ||
                            type === "Neonpay" ? (
                              <div className="flex justify-end">
                                <label className="relative mb-3 inline-flex cursor-pointer items-center">
                                  <input
                                    type="checkbox"
                                    className="peer sr-only"
                                    // @ts-ignore
                                    checked={formValues && formValues.is_live}
                                    onChange={handleToggle}
                                  />

                                  <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-indigo-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />

                                  <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                                    {formValues.is_live ? "Live" : "Test"}
                                  </span>
                                </label>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="flex justify-between">
                            <p className="mb-0 pl-2 font-bold text-gray-900">
                              Choose Credentials
                            </p>
                          </div>  

                          <div className="mt-2 flex gap-8 mb-2">
                            <div className="flex gap-2">
                              <Radio
                                id="custom"
                                value={"custom"}
                                checked={credentialsType === "custom"}
                                onChange={(e :any) => {
                                  setCredentialsType(e.target.value);
                                  setSelectedCredential("");
                                  setSelectedPredefinedCredentials(null);
                                  setCredentialError("");
                                }}
                              />
                              <label htmlFor="custom">
                                Custom
                              </label>
                            </div>
                            <div className="flex gap-2">
                              <Radio
                                id="predefined" 
                                value={"predefined"}
                                checked={credentialsType === "predefined"}
                                onChange={(e:any) => {
                                  setCredentialsType(e.target.value);
                                  setSelectedCredential("");
                                  setSelectedPredefinedCredentials(null);
                                  setCredentialError("");
                                }}
                              />
                              <label htmlFor="predefined">
                                Predefined
                              </label>
                            </div>
                          </div>  
                          {credentialsType === "predefined" && (
                          <>
                            <div className="mb-2 mt-4">
                              <label
                                htmlFor="org_id"
                                className={`ml-1.5 text-sm font-bold text-gray-900 dark:text-white `}
                              >
                                Select Predefined Credential
                                <span className="important">*</span>
                              </label>

                              <AntSelect
                                showSearch
                                placeholder="Select credential"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "").includes(
                                    input?.toUpperCase()
                                  )
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                className="w-full"
                                options={predefinedCredentials.map((credential) => {
                                  const preCred = credential;
                                  return {
                                    value: preCred?.id,
                                    label: `${preCred?.name}`,
                                  };
                                })}
                                value={selectedCredential}
                                onChange={handleCredentialSelect}
                                notFoundContent={"No options found"}
                                status={credentialError ? "error" : ""}
                                
                              />
                              {credentialError && (
                                <div className="error-message mt-1" style={{ color: 'red', fontSize: '12px' }}>
                                  {credentialError}
                                </div>
                              )}
                            </div>
                            {selectedPredefinedCredentials && (
                              <div className="mb-2 mt-4">
                                  {Object.entries(selectedPredefinedCredentials).map(([key, value]) => {
                                    if (key === "id" || key === "name") return null;

                                    if ((key === "test_endpoint" && formValues.is_live) || 
                                        (key === "live_endpoint" && !formValues.is_live)) {
                                      return null;
                                    }
                                    const formattedLabel = key
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, (char) => char.toUpperCase());
                                    return (
                                      <div key={key} className="form-group">
                                        <InputField
                                          variant="auth"
                                          extra="mb-1"
                                          label={formattedLabel}
                                          placeholder="https://gateway.com"
                                          type="text"
                                          value={value}
                                          disabled
                                        />
                                      </div>
                                    )
                                  })}    
                                  <InputField
                                    variant="auth"
                                    extra="mb-1"
                                    label="Descriptor"
                                    placeholder="descriptor"
                                    id="descriptor"
                                    type="text"
                                    value={formValues?.descriptor ?? ""}
                                    state={formValuesErr?.descriptor ? "error" : ""}
                                    onChange={handleValueChange}
                                  />     
                              </div>
                            )}
                          </>
                          )}
                          {credentialsType === "custom" && (
                          <>        
                            {type === "Sipe" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant ID*"
                                  placeholder="Google inc."
                                  id="merchant_id"
                                  type="text"
                                  value={formValues?.merchant_id ?? ""}
                                  state={formValuesErr?.merchant_id ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="App ID*"
                                  placeholder="Google inc."
                                  id="app_id"
                                  type="text"
                                  value={formValues?.app_id ?? ""}
                                  state={formValuesErr?.app_id ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Nonce Str*"
                                  placeholder="Google inc."
                                  id="nonce_string"
                                  type="text"
                                  value={formValues?.nonce_string ?? ""}
                                  state={formValuesErr?.nonce_string ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Notify URL*"
                                  placeholder=""
                                  id="notify_url"
                                  type="text"
                                  value={formValues?.notify_url ?? ""}
                                  state={formValuesErr?.notify_url ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Blumon" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Business Name*"
                                  placeholder="Google inc."
                                  id="business_name"
                                  type="text"
                                  value={formValues?.business_name ?? ""}
                                  state={
                                    formValuesErr?.business_name ? "error" : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Username*"
                                  placeholder="test"
                                  id="username"
                                  type="text"
                                  value={formValues?.username ?? ""}
                                  state={formValuesErr?.username ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="********"
                                  id="password"
                                  type={passwordShow ? "text" : "password"}
                                  value={formValues?.password ?? ""}
                                  state={formValuesErr?.password ? "error" : ""}
                                  onChange={handleValueChange}
                                  passwordShow={passwordShow}
                                  setPasswordShow={setPasswordShow}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Stripe" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Secret Key*"
                                  placeholder="Secret key"
                                  id={
                                    formValues.is_live === true
                                      ? "secret_key_live"
                                      : formValues.is_live === false
                                      ? "secret_key_test"
                                      : "secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <div className="mt-3 flex w-full gap-3">
                                  <InputField
                                    variant="auth"
                                    extra="mb-1 w-full"
                                    label="Statement Descriptor"
                                    placeholder="Custom descriptor"
                                    id="statement_descriptor"
                                    type="text"
                                    value={formValues?.statement_descriptor ?? ""}
                                    state={
                                      formValuesErr?.statement_descriptor
                                        ? "error"
                                        : ""
                                    }
                                    onChange={handleValueChange}
                                  />
                                  <InputField
                                    variant="auth"
                                    extra="mb-1 w-full"
                                    label="Statement Descriptor Suffix"
                                    placeholder="Custom descriptor suffix"
                                    id="statement_descriptor_suffix"
                                    type="text"
                                    value={
                                      formValues?.statement_descriptor_suffix ?? ""
                                    }
                                    state={
                                      formValuesErr?.statement_descriptor_suffix
                                        ? "error"
                                        : ""
                                    }
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </>
                            )}

                            {type === "Pix-Lotus" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Subscription key*"
                                  placeholder=""
                                  id="subscription_key"
                                  type="text"
                                  value={formValues?.subscription_key ?? ""}
                                  state={
                                    formValuesErr?.subscription_key ? "error" : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1 w-full"
                                  label="Authorization"
                                  placeholder=""
                                  id="authorization"
                                  type="text"
                                  value={formValues?.authorization ?? ""}
                                  state={
                                    formValuesErr?.authorization ? "error" : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1 w-full"
                                  label="Base URL"
                                  placeholder=""
                                  id="base_url"
                                  type="text"
                                  value={formValues?.base_url ?? ""}
                                  state={formValuesErr?.base_url ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Pix" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Email*"
                                  placeholder="Email"
                                  id={
                                    formValues.is_live === true
                                      ? "email_live"
                                      : formValues.is_live === false
                                      ? "email_test"
                                      : "email_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.email_live
                                      : formValues.is_live === false
                                      ? formValues?.email_test
                                      : formValues?.email_test
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.email_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.email_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="Password"
                                  id={
                                    formValues.is_live === true
                                      ? "name_live"
                                      : formValues.is_live === false
                                      ? "name_test"
                                      : "name_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.name_live
                                      : formValues.is_live === false
                                      ? formValues?.name_test
                                      : formValues?.name_test
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.name_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.name_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Memphis" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Commerce Id*"
                                  placeholder="Commerce Id"
                                  id={
                                    formValues.is_live === true
                                      ? "commerce_id_live"
                                      : formValues.is_live === false
                                      ? "commerce_id_test"
                                      : "commerce_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.commerce_id_live
                                      : formValues.is_live === false
                                      ? formValues?.commerce_id_test
                                      : formValues?.commerce_id_test
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.commerce_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.commerce_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Commerce Name*"
                                  placeholder="Commerce Name"
                                  id={
                                    formValues.is_live === true
                                      ? "commerce_name_live"
                                      : formValues.is_live === false
                                      ? "commerce_name_test"
                                      : "commerce_name_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.commerce_name_live
                                      : formValues.is_live === false
                                      ? formValues?.commerce_name_test
                                      : formValues?.commerce_name_test
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.commerce_name_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.commerce_name_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="TKR*"
                                  placeholder="TKR"
                                  id={
                                    formValues.is_live === true
                                      ? "tkr_live"
                                      : formValues.is_live === false
                                      ? "tkr_test"
                                      : "tkr_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.tkr_live
                                      : formValues.is_live === false
                                      ? formValues?.tkr_test
                                      : formValues?.tkr_test
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.tkr_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.tkr_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                {/* <label
                                  className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
                                  htmlFor="types"
                                >
                                  Identification type*
                                </label>
                                <select
                                  name="select"
                                  id="types"
                                  className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                                  value={formValues?.types}
                                  onChange={handleValueChange}
                                >
                                  <option value="">Select type</option>
                                  <option value="cpf">CPF</option>
                              
                                </select>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Identification number*"
                                  placeholder="Enter identification number"
                                  id="number"
                                  type="number"
                                  value={formValues?.number ?? ""}
                                  state={formValuesErr?.number ? "error" : ""}
                                  onChange={handleValueChange}
                                /> */}
                                {/* <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="First name*"
                                  placeholder="first name"
                                  id="first_name"
                                  type="text"
                                  value={formValues?.first_name ?? ""}
                                  state={formValuesErr?.first_name ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Last name*"
                                  placeholder="last name"
                                  id="last_name"
                                  type="text"
                                  value={formValues?.last_name ?? ""}
                                  state={formValuesErr?.last_name ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Email*"
                                  placeholder="Enter email"
                                  id="email"
                                  type="email"
                                  value={formValues?.email ?? ""}
                                  state={formValuesErr?.email ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Description*"
                                  placeholder="Enter description"
                                  id="description"
                                  type="text"
                                  value={formValues?.description ?? ""}
                                  state={formValuesErr?.description ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Amount*"
                                  placeholder="Enter amount"
                                  id="amount"
                                  type="number"
                                  value={formValues?.amount ?? ""}
                                  state={formValuesErr?.amount ? "error" : ""}
                                  onChange={handleValueChange}
                                />

                                <label
                                  className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
                                  htmlFor="types"
                                >
                                  Identification type*
                                </label>
                                <select
                                  name="select"
                                  id="types"
                                  className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                                  value={formValues?.type}
                                  onChange={handleValueChange}
                                >
                                  <option value="">Select type</option>
                                  <option value="cpf">CPF</option>
                                  <option value="cpf1">CPF1</option>
                                </select>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Identification number*"
                                  placeholder="Enter identification number"
                                  id="number"
                                  type="number"
                                  value={formValues?.number ?? ""}
                                  state={formValuesErr?.number ? "error" : ""}
                                  onChange={handleValueChange}
                                /> */}
                              </>
                            )}

                            {type === "Raypd" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Secret Key*"
                                  placeholder="Secret key"
                                  id={
                                    formValues.is_live === true
                                      ? "secret_key_live"
                                      : formValues.is_live === false
                                      ? "secret_key_test"
                                      : "secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Access Key*"
                                  placeholder="Access key"
                                  id={
                                    formValues.is_live === true
                                      ? "access_key_live"
                                      : formValues.is_live === false
                                      ? "access_key_test"
                                      : "access_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.access_key_live
                                      : formValues.is_live === false
                                      ? formValues?.access_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.access_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.access_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="MID Country"
                                  placeholder="MID Country"
                                  id={
                                    formValues.is_live === true
                                      ? "mid_country_live"
                                      : formValues.is_live === false
                                      ? "mid_country_test"
                                      : "mid_country_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.mid_country_live
                                      : formValues.is_live === false
                                      ? formValues?.mid_country_test
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Banwire" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Username*"
                                  placeholder="Username"
                                  id={
                                    formValues.is_live === true
                                      ? "username_live"
                                      : formValues.is_live === false
                                      ? "username_test"
                                      : "username_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.username_live
                                      : formValues.is_live === false
                                      ? formValues?.username_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.username_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.username_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Valitor" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="API Key*"
                                  placeholder="API Key"
                                  id={
                                    formValues.is_live === true
                                      ? "apikey_live"
                                      : formValues.is_live === false
                                      ? "apikey_test"
                                      : "apikey_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.apikey_live
                                      : formValues.is_live === false
                                      ? formValues?.apikey_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.apikey_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.apikey_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Bambora" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Id*"
                                  placeholder="Merchant Id"
                                  id={
                                    formValues.is_live === true
                                      ? "merchantId_live"
                                      : formValues.is_live === false
                                      ? "merchantId_test"
                                      : "merchantId_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchantId_live
                                      : formValues.is_live === false
                                      ? formValues?.merchantId_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchantId_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchantId_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Payment Passcode*"
                                  placeholder="Payment Passcode"
                                  id={
                                    formValues.is_live === true
                                      ? "passcode_live"
                                      : formValues.is_live === false
                                      ? "passcode_test"
                                      : "passcode_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.passcode_live
                                      : formValues.is_live === false
                                      ? formValues?.passcode_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.passcode_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.passcode_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Hash Key*"
                                  placeholder="Hash Key"
                                  id={
                                    formValues.is_live === true
                                      ? "hash_key_live"
                                      : formValues.is_live === false
                                      ? "hash_key_test"
                                      : "hash_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.hash_key_live
                                      : formValues.is_live === false
                                      ? formValues?.hash_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.hash_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.hash_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Recurring Passcode*"
                                  placeholder="Recurring Passcode"
                                  id={
                                    formValues.is_live === true
                                      ? "recurring_passcode_live"
                                      : formValues.is_live === false
                                      ? "recurring_passcode_test"
                                      : "recurring_passcode_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.recurring_passcode_live
                                      : formValues.is_live === false
                                      ? formValues?.recurring_passcode_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.recurring_passcode_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.recurring_passcode_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Reporting Passcode*"
                                  placeholder="Reporting Passcode"
                                  id={
                                    formValues.is_live === true
                                      ? "reporting_passcode_live"
                                      : formValues.is_live === false
                                      ? "reporting_passcode_test"
                                      : "reporting_passcode_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.reporting_passcode_live
                                      : formValues.is_live === false
                                      ? formValues?.reporting_passcode_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.reporting_passcode_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.reporting_passcode_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Batch Passcode*"
                                  placeholder="Batch Passcode"
                                  id={
                                    formValues.is_live === true
                                      ? "batch_passcode_live"
                                      : formValues.is_live === false
                                      ? "batch_passcode_test"
                                      : "batch_passcode_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.batch_passcode_live
                                      : formValues.is_live === false
                                      ? formValues?.batch_passcode_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.batch_passcode_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.batch_passcode_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Subscription Passcode*"
                                  placeholder="Subscription Passcode"
                                  id={
                                    formValues.is_live === true
                                      ? "subscription_passcode_live"
                                      : formValues.is_live === false
                                      ? "subscription_passcode_test"
                                      : "subscription_passcode_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.subscription_passcode_live
                                      : formValues.is_live === false
                                      ? formValues?.subscription_passcode_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.subscription_passcode_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.subscription_passcode_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Digitarium" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Key*"
                                  placeholder="Merchant Key"
                                  id={
                                    formValues.is_live === true
                                      ? "merchantKey_live"
                                      : formValues.is_live === false
                                      ? "merchantKey_test"
                                      : "merchantKey_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchantKey_live
                                      : formValues.is_live === false
                                      ? formValues?.merchantKey_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchantKey_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchantKey_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="Password"
                                  id={
                                    formValues.is_live === true
                                      ? "password_live"
                                      : formValues.is_live === false
                                      ? "password_test"
                                      : "password_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.password_live
                                      : formValues.is_live === false
                                      ? formValues?.password_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.password_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.password_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Tangoindia" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api ID*"
                                  placeholder="Api ID"
                                  id={
                                    formValues.is_live === true
                                      ? "api_id_live"
                                      : formValues.is_live === false
                                      ? "api_id_test"
                                      : "api_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_id_live
                                      : formValues.is_live === false
                                      ? formValues?.api_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "G2Pay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Signing Key*"
                                  placeholder="Signing Key"
                                  id={
                                    formValues.is_live === true
                                      ? "signing_key_live"
                                      : formValues.is_live === false
                                      ? "signing_key_test"
                                      : "signing_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.signing_key_live
                                      : formValues.is_live === false
                                      ? formValues?.signing_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.signing_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.signing_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Moneto" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Secret Key*"
                                  placeholder="Merchant Secret Key"
                                  id={
                                    formValues.is_live === true
                                      ? "merchant_secret_key_live"
                                      : formValues.is_live === false
                                      ? "merchant_secret_key_test"
                                      : "merchant_secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchant_secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.merchant_secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchant_secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchant_secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant ID*"
                                  placeholder="Merchant ID"
                                  id={
                                    formValues.is_live === true
                                      ? "merchant_id_live"
                                      : formValues.is_live === false
                                      ? "merchant_id_test"
                                      : "merchant_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchant_id_live
                                      : formValues.is_live === false
                                      ? formValues?.merchant_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchant_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchant_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Re-set" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Secret Key*"
                                  placeholder="Secret Key"
                                  id={
                                    formValues.is_live === true
                                      ? "secretKey_live"
                                      : formValues.is_live === false
                                      ? "secretKey_test"
                                      : "secretKey_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.secretKey_live
                                      : formValues.is_live === false
                                      ? formValues?.secretKey_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.secretKey_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.secretKey_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Brand ID*"
                                  placeholder="Brand ID"
                                  id={
                                    formValues.is_live === true
                                      ? "brand_id_live"
                                      : formValues.is_live === false
                                      ? "brand_id_test"
                                      : "brand_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.brand_id_live
                                      : formValues.is_live === false
                                      ? formValues?.brand_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.brand_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.brand_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "MIT" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Id Branch*"
                                  placeholder="Id Branch"
                                  id={
                                    formValues.is_live === true
                                      ? "id_branch_live"
                                      : formValues.is_live === false
                                      ? "id_branch_test"
                                      : "id_branch_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.id_branch_live
                                      : formValues.is_live === false
                                      ? formValues?.id_branch_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.id_branch_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.id_branch_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Id Company*"
                                  placeholder="Id Company"
                                  id={
                                    formValues.is_live === true
                                      ? "id_company_live"
                                      : formValues.is_live === false
                                      ? "id_company_test"
                                      : "id_company_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.id_company_live
                                      : formValues.is_live === false
                                      ? formValues?.id_company_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.id_company_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.id_company_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Id*"
                                  placeholder="Google inc."
                                  id={
                                    formValues.is_live === true
                                      ? "merchant_id_live"
                                      : formValues.is_live === false
                                      ? "merchant_id_test"
                                      : "merchant_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchant_id_live
                                      : formValues.is_live === false
                                      ? formValues?.merchant_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchant_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchant_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="********"
                                  id={
                                    formValues.is_live === true
                                      ? "password_live"
                                      : formValues.is_live === false
                                      ? "password_test"
                                      : "password_test"
                                  }
                                  type="password"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.password_live
                                      : formValues.is_live === false
                                      ? formValues?.password_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.password_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.password_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="User Code*"
                                  placeholder="User Code"
                                  id={
                                    formValues.is_live === true
                                      ? "user_code_live"
                                      : formValues.is_live === false
                                      ? "user_code_test"
                                      : "user_code_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.user_code_live
                                      : formValues.is_live === false
                                      ? formValues?.user_code_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.user_code_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.user_code_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Scipiopay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Shop id*"
                                  placeholder="Shop id"
                                  id={
                                    formValues.is_live === true
                                      ? "shop_id_live"
                                      : formValues.is_live === false
                                      ? "shop_id_test"
                                      : "shop_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.shop_id_live
                                      : formValues.is_live === false
                                      ? formValues?.shop_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.shop_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.shop_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Shop Secret Key*"
                                  placeholder="Shop secret key"
                                  id={
                                    formValues.is_live === true
                                      ? "shop_secret_key_live"
                                      : formValues.is_live === false
                                      ? "shop_secret_key_test"
                                      : "shop_secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.shop_secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.shop_secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.shop_secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.shop_secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Aurea Via" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Company Number*"
                                  placeholder="Company number"
                                  id={
                                    formValues.is_live === true
                                      ? "companyNum_live"
                                      : formValues.is_live === false
                                      ? "companyNum_test"
                                      : "companyNum_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.companyNum_live
                                      : formValues.is_live === false
                                      ? formValues?.companyNum_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.companyNum_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.companyNum_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Personal Hash Key*"
                                  placeholder="Personal hash key"
                                  id={
                                    formValues.is_live === true
                                      ? "personalHashkey_live"
                                      : formValues.is_live === false
                                      ? "personalHashkey_test"
                                      : "personalHashkey_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.personalHashkey_live
                                      : formValues.is_live === false
                                      ? formValues?.personalHashkey_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.personalHashkey_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.personalHashkey_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Betapay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Token*"
                                  placeholder="Api Token"
                                  id={
                                    formValues.is_live === true
                                      ? "api_token_live"
                                      : formValues.is_live === false
                                      ? "api_token_test"
                                      : "api_token_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_token_live
                                      : formValues.is_live === false
                                      ? formValues?.api_token_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_token_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_token_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Id*"
                                  placeholder="Merchant id"
                                  id={
                                    formValues.is_live === true
                                      ? "merchant_id_live"
                                      : formValues.is_live === false
                                      ? "merchant_id_test"
                                      : "merchant_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchant_id_live
                                      : formValues.is_live === false
                                      ? formValues?.merchant_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchant_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchant_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Terminal Id*"
                                  placeholder="Terminal id"
                                  id={
                                    formValues.is_live === true
                                      ? "terminal_id_live"
                                      : formValues.is_live === false
                                      ? "terminal_id_test"
                                      : "terminal_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.terminal_id_live
                                      : formValues.is_live === false
                                      ? formValues?.terminal_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.terminal_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.terminal_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Kasha" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Domain*"
                                  placeholder="Merchant domain"
                                  id={
                                    formValues.is_live === true
                                      ? "merchant_domain_live"
                                      : formValues.is_live === false
                                      ? "merchant_domain_test"
                                      : "merchant_domain_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchant_domain_live
                                      : formValues.is_live === false
                                      ? formValues?.merchant_domain_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchant_domain_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchant_domain_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Linx" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Client Id*"
                                  placeholder="Client id"
                                  id={
                                    formValues.is_live === true
                                      ? "client_id_live"
                                      : formValues.is_live === false
                                      ? "client_id_test"
                                      : "client_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.client_id_live
                                      : formValues.is_live === false
                                      ? formValues?.client_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.client_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.client_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Client Secret Test*"
                                  placeholder="Client secret test"
                                  id={
                                    formValues.is_live === true
                                      ? "client_secret_live"
                                      : formValues.is_live === false
                                      ? "client_secret_test"
                                      : "client_secret_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.client_secret_live
                                      : formValues.is_live === false
                                      ? formValues?.client_secret_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.client_secret_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.client_secret_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Supefina" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Id*"
                                  placeholder="Merchant Id"
                                  id={
                                    formValues.is_live === true
                                      ? "merchant_id_live"
                                      : formValues.is_live === false
                                      ? "merchant_id_test"
                                      : "merchant_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.merchant_id_live
                                      : formValues.is_live === false
                                      ? formValues?.merchant_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.merchant_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.merchant_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Platy" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />
                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Username*"
                                  placeholder="Username"
                                  id={
                                    formValues.is_live === true
                                      ? "username_live"
                                      : formValues.is_live === false
                                      ? "username_test"
                                      : "username_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.username_live
                                      : formValues.is_live === false
                                      ? formValues?.username_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.username_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.username_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="Password"
                                  id={
                                    formValues.is_live === true
                                      ? "password_live"
                                      : formValues.is_live === false
                                      ? "password_test"
                                      : "password_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.password_live
                                      : formValues.is_live === false
                                      ? formValues?.password_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.password_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.password_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Platy Api Key*"
                                  placeholder="Platy Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "platy_api_key_live"
                                      : formValues.is_live === false
                                      ? "platy_api_key_test"
                                      : "platy_api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.platy_api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.platy_api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.platy_api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.platy_api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Platy Api Secret Key*"
                                  placeholder="Platy Api Secret Key"
                                  id={
                                    formValues.is_live === true
                                      ? "platy_secret_key_live"
                                      : formValues.is_live === false
                                      ? "platy_secret_key_test"
                                      : "platy_secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.platy_secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.platy_secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.platy_secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.platy_secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}
                            {type === "3xpay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />
                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Secret*"
                                  placeholder="Api Secret "
                                  id={
                                    formValues.is_live === true
                                      ? "api_secret_live"
                                      : formValues.is_live === false
                                      ? "api_secret_test"
                                      : "api_secret_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_secret_live
                                      : formValues.is_live === false
                                      ? formValues?.api_secret_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_secret_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_secret_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}
                            {type === "Platy 2DS" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />
                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Username*"
                                  placeholder="Username"
                                  id={
                                    formValues.is_live === true
                                      ? "username_live"
                                      : formValues.is_live === false
                                      ? "username_test"
                                      : "username_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.username_live
                                      : formValues.is_live === false
                                      ? formValues?.username_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.username_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.username_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="Password"
                                  id={
                                    formValues.is_live === true
                                      ? "password_live"
                                      : formValues.is_live === false
                                      ? "password_test"
                                      : "password_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.password_live
                                      : formValues.is_live === false
                                      ? formValues?.password_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.password_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.password_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Platy Api Key*"
                                  placeholder="Platy Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "platy_api_key_live"
                                      : formValues.is_live === false
                                      ? "platy_api_key_test"
                                      : "platy_api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.platy_api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.platy_api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.platy_api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.platy_api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Platy Api Secret Key*"
                                  placeholder="Platy Api Secret Key"
                                  id={
                                    formValues.is_live === true
                                      ? "platy_secret_key_live"
                                      : formValues.is_live === false
                                      ? "platy_secret_key_test"
                                      : "platy_secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.platy_secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.platy_secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.platy_secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.platy_secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}

                            {type === "Platy 3DS" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />
                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Api Key*"
                                  placeholder="Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "api_key_live"
                                      : formValues.is_live === false
                                      ? "api_key_test"
                                      : "api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Username*"
                                  placeholder="Username"
                                  id={
                                    formValues.is_live === true
                                      ? "username_live"
                                      : formValues.is_live === false
                                      ? "username_test"
                                      : "username_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.username_live
                                      : formValues.is_live === false
                                      ? formValues?.username_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.username_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.username_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="Password"
                                  id={
                                    formValues.is_live === true
                                      ? "password_live"
                                      : formValues.is_live === false
                                      ? "password_test"
                                      : "password_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.password_live
                                      : formValues.is_live === false
                                      ? formValues?.password_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.password_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.password_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Platy Api Key*"
                                  placeholder="Platy Api Key"
                                  id={
                                    formValues.is_live === true
                                      ? "platy_api_key_live"
                                      : formValues.is_live === false
                                      ? "platy_api_key_test"
                                      : "platy_api_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.platy_api_key_live
                                      : formValues.is_live === false
                                      ? formValues?.platy_api_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.platy_api_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.platy_api_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  disabled={
                                    formValues?.is_active === false ? true : false
                                  }
                                  variant="auth"
                                  extra="mb-1"
                                  label="Platy Api Secret Key*"
                                  placeholder="Platy Api Secret Key"
                                  id={
                                    formValues.is_live === true
                                      ? "platy_secret_key_live"
                                      : formValues.is_live === false
                                      ? "platy_secret_key_test"
                                      : "platy_secret_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.platy_secret_key_live
                                      : formValues.is_live === false
                                      ? formValues?.platy_secret_key_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.platy_secret_key_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.platy_secret_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}
                                
                            {type === "Masterpay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Private Key*"
                                  placeholder="Merchant Private Key"
                                  id={
                                    formValues?.is_live === true
                                      ? "merchant_private_key_live"
                                      : formValues?.is_live === false
                                      ? "merchant_private_key_test"
                                      : "merchant_private_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues?.is_live === true
                                      ? formValues?.merchant_private_key_live
                                      : formValues?.is_live === false
                                      ? formValues?.merchant_private_key_test
                                      : ""
                                  }
                                  state={
                                    formValues?.is_live === true
                                      ? formValuesErr?.merchant_private_key_live
                                        ? "error"
                                        : ""
                                      : formValues?.is_live === false
                                      ? formValuesErr?.merchant_private_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}
                            {type === "Neonpay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Merchant Key*"
                                  placeholder="Merchant Key"
                                  id={
                                    formValues?.is_live === true
                                      ? "merchant_key_live"
                                      : formValues?.is_live === false
                                      ? "merchant_key_test"
                                      : "merchant_key_test"
                                  }
                                  type="text"
                                  value={
                                    formValues?.is_live === true
                                      ? formValues?.merchant_key_live
                                      : formValues?.is_live === false
                                      ? formValues?.merchant_key_test
                                      : ""
                                  }
                                  state={
                                    formValues?.is_live === true
                                      ? formValuesErr?.merchant_key_live
                                        ? "error"
                                        : ""
                                      : formValues?.is_live === false
                                      ? formValuesErr?.merchant_key_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Password*"
                                  placeholder="Password"
                                  id={
                                    formValues?.is_live === true
                                      ? "password_live"
                                      : formValues?.is_live === false
                                      ? "password_test"
                                      : "password_test"
                                  }
                                  type="text"
                                  value={
                                    formValues?.is_live === true
                                      ? formValues?.password_live
                                      : formValues?.is_live === false
                                      ? formValues?.password_test
                                      : ""
                                  }
                                  state={
                                    formValues?.is_live === true
                                      ? formValuesErr?.password_live
                                        ? "error"
                                        : ""
                                      : formValues?.is_live === false
                                      ? formValuesErr?.password_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />
                                {/* <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Term Url 3ds*"
                                  placeholder="Term Url 3ds"
                                  id={
                                    formValues?.is_live === true
                                      ? "term_url_3ds_live"
                                      : formValues?.is_live === false
                                      ? "term_url_3ds_test"
                                      : "term_url_3ds_test"
                                  }
                                  type="text"
                                  value={
                                    formValues?.is_live === true
                                      ? formValues?.term_url_3ds_live
                                      : formValues?.is_live === false
                                      ? formValues?.term_url_3ds_test
                                      : ""
                                  }
                                  state={
                                    formValues?.is_live === true
                                      ? formValuesErr?.term_url_3ds_live
                                        ? "error"
                                        : ""
                                      : formValues?.is_live === false
                                      ? formValuesErr?.term_url_3ds_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                /> */}
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                /> 
                              </>
                            )}

                            {type === "Bm2pay" && (
                              <>
                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Endpoint*"
                                  placeholder="https://gateway.com"
                                  id={
                                    formValues.is_live === true
                                      ? "live_endpoint"
                                      : formValues.is_live === false
                                      ? "test_endpoint"
                                      : "test_endpoint"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.live_endpoint
                                      : formValues.is_live === false
                                      ? formValues?.test_endpoint
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.live_endpoint
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.test_endpoint
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                  disabled
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Client Id*"
                                  placeholder="Client id"
                                  id={
                                    formValues.is_live === true
                                      ? "client_id_live"
                                      : formValues.is_live === false
                                      ? "client_id_test"
                                      : "client_id_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.client_id_live
                                      : formValues.is_live === false
                                      ? formValues?.client_id_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.client_id_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.client_id_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Client Secret Test*"
                                  placeholder="Client secret test"
                                  id={
                                    formValues.is_live === true
                                      ? "client_secret_live"
                                      : formValues.is_live === false
                                      ? "client_secret_test"
                                      : "client_secret_test"
                                  }
                                  type="text"
                                  value={
                                    formValues.is_live === true
                                      ? formValues?.client_secret_live
                                      : formValues.is_live === false
                                      ? formValues?.client_secret_test
                                      : ""
                                  }
                                  state={
                                    formValues.is_live === true
                                      ? formValuesErr?.client_secret_live
                                        ? "error"
                                        : ""
                                      : formValues.is_live === false
                                      ? formValuesErr?.client_secret_test
                                        ? "error"
                                        : ""
                                      : ""
                                  }
                                  onChange={handleValueChange}
                                />

                                <InputField
                                  variant="auth"
                                  extra="mb-1"
                                  label="Descriptor"
                                  placeholder="descriptor"
                                  id="descriptor"
                                  type="text"
                                  value={formValues?.descriptor ?? ""}
                                  state={formValuesErr?.descriptor ? "error" : ""}
                                  onChange={handleValueChange}
                                />
                              </>
                            )}
                          </>
                          )}

                          <div className="flex justify-between">
                            <p className="mb-0 pl-2 font-bold text-gray-900">
                              Currency Converter
                            </p>
                          </div>

                          <div className="mt-2 flex gap-8">
                            <div className="flex gap-2">
                              <Radio
                                id="requestPassthrough"
                                value={"requestPassthrough"}
                                checked={currencyConv === "requestPassthrough"}
                                onChange={(e: any) =>
                                  setCurrencyConv(e.target.value)
                                }
                              />
                              <label htmlFor="requestPassthrough">
                                Request Passthrough
                              </label>
                            </div>
                            <div className="flex gap-2">
                              <Radio
                                id="currencyConverter"
                                value={"currencyConverter"}
                                checked={currencyConv === "currencyConverter"}
                                onChange={(e: any) =>
                                  setCurrencyConv(e.target.value)
                                }
                              />
                              <label htmlFor="currencyConverter">
                                Currency Converter
                              </label>
                            </div>
                          </div>
                          {currencyConv == "currencyConverter" ? (
                            <div className="mb-2 mt-4">
                              <label
                                htmlFor="org_id"
                                className={`ml-1.5 text-sm font-bold text-gray-900 dark:text-white `}
                              >
                                Select Currency
                                <span className="important">*</span>
                              </label>

                              <AntSelect
                                showSearch
                                placeholder="Select currency"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "").includes(
                                    input?.toUpperCase()
                                  )
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                className="w-full"
                                options={currencyCodes.map((currencyCode) => {
                                  const currencyDetails =
                                    getCurrencySymbol(currencyCode);
                                  return {
                                    value: currencyCode,
                                    label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                  };
                                })}
                                value={selectedCurrency}
                                onChange={handleCurrencySelect}
                                notFoundContent={"No options found"}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                    <h4>
                      <AccordionButton className="flex justify-between">
                        <span className="text-left font-bold text-navy-900 dark:text-white">
                          Payment Methods
                        </span>
                        <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                      <div className="flex h-full w-full items-center gap-4 overflow-x-auto">
                        {formValues?.payment_methods?.map((payment: any) => {
                          return (
                            <div className=" flex items-center justify-between p-2">
                              <div className="">
                                <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white">
                                  {payment?.name[0]?.toUpperCase() +
                                    payment?.name?.slice(1)}
                                </p>
                                <div className="flex justify-center">
                                  <Checkbox
                                    id={payment.name}
                                    value={payment.is_Active}
                                    className={`${
                                      payment?.is_Active === false &&
                                      payment?.disabled === true
                                        ? "h-5 w-5 dark:bg-gray-700"
                                        : "h-5 w-5"
                                    }`}
                                    color={
                                      payment?.disabled === false ? "gray" : ""
                                    }
                                    disabled={!payment?.disabled}
                                    checked={payment?.is_Active}
                                    onChange={handleCheckChange}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                  {pricingRight && (
                    <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                      <h4>
                        <AccordionButton className="flex justify-between">
                          <span className="text-left font-bold text-navy-900 dark:text-white">
                            Pricing
                            <span
                              id="standard_error_help"
                              className="mx-2 text-xs text-red-600 dark:text-red-400"
                            >
                              <span className="font-medium">
                                {formValuesErr?.client_card_types?.filter(
                                  (f: any) =>
                                    f?.allow_card &&
                                    (f?.percentage_fee ||
                                      f?.fixed_fee ||
                                      f?.currency_code)
                                )?.length > 0 || isValidateAdditionalFeesPricing
                                  ? "Please enter required fields of this section."
                                  : ""}
                              </span>
                            </span>
                          </span>
                          <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                        </AccordionButton>
                      </h4>
                      <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                        <p>
                          <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                            <div className="">
                              <span className="font-bold text-gray-900">
                                Card Type
                              </span>
                              <div className="relative z-0 overflow-x-auto  overflow-y-visible">
                                <table className="bg-green z-0 w-full overflow-y-visible text-left text-sm  text-gray-500 dark:text-gray-400 rtl:text-right">
                                  <tbody>
                                    {formValues?.cardTypes?.length > 0
                                      ? formValues?.cardTypes?.map(
                                          (card: any, index: number) => {
                                            return (
                                              <>
                                                <tr className="bg-midnight  z-0 max-h-[50px] overflow-x-auto overflow-y-visible bg-white dark:border-gray-700 dark:bg-gray-800">
                                                  <td className="w-[25%] px-2  py-4">
                                                    <div className="flex">
                                                      <Checkbox
                                                        id="allow_card"
                                                        value={
                                                          !formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.allow_card
                                                        }
                                                        checked={
                                                          formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )?.length > 0 &&
                                                          formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.allow_card
                                                        }
                                                        onChange={(e: any) => {
                                                          handlePriceValueChange(
                                                            e,
                                                            index,
                                                            card,
                                                            ""
                                                          );
                                                        }}
                                                      />
                                                      <span className="mx-2 text-gray-900">
                                                        {" "}
                                                        {card}
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td className="py-2 ps-1">
                                                    <div className="flex">
                                                      <div className="">
                                                        <div className="relative z-0 min-w-[100px] flex-auto">
                                                          <input
                                                            type="number"
                                                            id="percentage_fee"
                                                            className={`bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300
                                                            ${
                                                              formValuesErr?.client_card_types?.filter(
                                                                (f: any) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.percentage_fee
                                                                ? " border-red-600"
                                                                : ""
                                                            }
                                                            px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500`}
                                                            placeholder=" Fee "
                                                            value={
                                                              formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]
                                                                ?.percentage_fee ??
                                                              ""
                                                            }
                                                            disabled={
                                                              !formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.allow_card
                                                            }
                                                            onChange={(e) => {
                                                              handlePriceValueChange(
                                                                e,
                                                                index,
                                                                card,
                                                                ""
                                                              );
                                                            }}
                                                          />
                                                          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                            <span className="h-6">
                                                              %
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <span
                                                          id="standard_error_help"
                                                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                                                        >
                                                          <span className="font-medium">
                                                            {
                                                              formValuesErr?.client_card_types?.filter(
                                                                (f: any) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.percentage_fee
                                                            }
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div className="mx-2 flex items-center">
                                                        <span>+</span>
                                                      </div>
                                                      <div>
                                                        <div className="relative z-0  min-w-[100px] flex-auto">
                                                          <input
                                                            type="number"
                                                            id="fixed_fee"
                                                            className={`bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500
                                                            ${
                                                              formValuesErr?.client_card_types?.filter(
                                                                (f: any) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.fixed_fee
                                                                ? " border-red-600"
                                                                : ""
                                                            }`}
                                                            placeholder=" Fixed fee "
                                                            value={
                                                              formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.fixed_fee ??
                                                              ""
                                                            }
                                                            disabled={
                                                              !formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.allow_card
                                                            }
                                                            onChange={(e) => {
                                                              handlePriceValueChange(
                                                                e,
                                                                index,
                                                                card,
                                                                ""
                                                              );
                                                            }}
                                                          />
                                                          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                            <span className="h-6">
                                                              {
                                                                formValues?.client_card_types?.filter(
                                                                  (
                                                                    f: any,
                                                                    i: number
                                                                  ) =>
                                                                    f?.card_name ==
                                                                    card
                                                                )[0]
                                                                  ?.currency_code
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <span
                                                          id="standard_error_help"
                                                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                                                        >
                                                          <span className="font-medium">
                                                            {
                                                              formValuesErr?.client_card_types?.filter(
                                                                (f: any) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.fixed_fee
                                                            }
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className=" z-0 px-2 py-4">
                                                    <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                                      <AntSelect
                                                        showSearch
                                                        placeholder="Select currency"
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                          input,
                                                          option
                                                        ) =>
                                                          (
                                                            option?.label ?? ""
                                                          ).includes(
                                                            input?.toUpperCase()
                                                          )
                                                        }
                                                        filterSort={(
                                                          optionA,
                                                          optionB
                                                        ) =>
                                                          (optionA?.label ?? "")
                                                            .toLowerCase()
                                                            .localeCompare(
                                                              (
                                                                optionB?.label ??
                                                                ""
                                                              ).toLowerCase()
                                                            )
                                                        }
                                                        className={`w-full 
                                                        ${
                                                          formValuesErr?.client_card_types?.filter(
                                                            (f: any) =>
                                                              f?.card_name == card
                                                          )[0]?.currency_code
                                                            ? "border-red-600"
                                                            : ""
                                                        }

                                                        `}
                                                        disabled={
                                                          !formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.allow_card
                                                        }
                                                        options={currencyCodes.map(
                                                          (currencyCode) => {
                                                            const currencyDetails =
                                                              getCurrencySymbol(
                                                                currencyCode
                                                              );
                                                            return {
                                                              value: currencyCode,
                                                              label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                            };
                                                          }
                                                        )}
                                                        onChange={(e: any) => {
                                                          handlePriceValueChange(
                                                            e,
                                                            index,
                                                            card,
                                                            "cardType_currency_code"
                                                          );
                                                        }}
                                                        value={
                                                          formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.currency_code
                                                        }
                                                        notFoundContent={
                                                          "No options found"
                                                        }
                                                      />
                                                      <span
                                                        id="standard_error_help"
                                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                                      >
                                                        <span className="font-medium">
                                                          {
                                                            formValuesErr?.client_card_types?.filter(
                                                              (f: any) =>
                                                                f?.card_name ==
                                                                card
                                                            )[0]?.currency_code
                                                          }
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )
                                      : ""}
                                  </tbody>
                                </table>
                              </div>

                              <span className="font-bold text-gray-900">
                                Additional Fees
                              </span>
                              <div className="add_clas relative z-0 z-0 overflow-x-auto overflow-y-visible">
                                <table className="bg-green z-0 w-full overflow-y-visible text-left text-sm  text-gray-500 dark:text-gray-400 rtl:text-right">
                                  <tbody>
                                    <tr className="bg-midnight  z-0 max-h-[50px] overflow-x-auto overflow-y-visible bg-white dark:border-gray-700 dark:bg-gray-800">
                                      <td className="w-[25%] px-2  py-4 text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_chargeback_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.chargeback_fees
                                                ?.allow_chargeback_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.chargeback_fees
                                                ?.allow_chargeback_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "chargeback_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2">
                                            Chargeback Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.chargeback_fees
                                                  ?.allow_chargeback_fees
                                              }
                                              value={
                                                formValues?.additional_fees
                                                  ?.chargeback_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "chargeback_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.chargeback_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.chargeback_fees?.fixed_fee ??
                                                ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.chargeback_fees
                                                  ?.allow_chargeback_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "chargeback_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.chargeback_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.chargeback_fees?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="z-0 px-2 py-4 ">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            className="w-full"
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.chargeback_fees
                                                ?.allow_chargeback_fees
                                            }
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            onChange={(e) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "chargeback_currency_code"
                                              );
                                            }}
                                            value={
                                              formValues?.additional_fees
                                                ?.chargeback_fees?.currency_code
                                            }
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.chargeback_fees?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_dispute_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.dispute_fees?.allow_dispute_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.dispute_fees?.allow_dispute_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "dispute_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">
                                            Dispute Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.dispute_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.dispute_fees
                                                  ?.allow_dispute_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "dispute_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>

                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.dispute_fees?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.dispute_fees?.fixed_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.dispute_fees
                                                  ?.allow_dispute_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "dispute_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.dispute_fees?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.dispute_fees?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-2 py-4">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.dispute_fees?.allow_dispute_fees
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            value={
                                              formValues?.additional_fees
                                                ?.dispute_fees?.currency_code
                                            }
                                            onChange={(e) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "dispute_currency_code"
                                              );
                                            }}
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.dispute_fees?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_declined_tnx_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.declined_tnx_fees
                                                ?.allow_declined_tnx_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.declined_tnx_fees
                                                ?.allow_declined_tnx_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "declined_tnx_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">
                                            Declined Transaction Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.declined_tnx_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.declined_tnx_fees
                                                  ?.allow_declined_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "declined_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.declined_tnx_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.declined_tnx_fees
                                                  ?.fixed_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.declined_tnx_fees
                                                  ?.allow_declined_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "declined_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.declined_tnx_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.declined_tnx_fees?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-2 py-4">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.declined_tnx_fees
                                                ?.allow_declined_tnx_fees
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            value={
                                              formValues?.additional_fees
                                                ?.declined_tnx_fees?.currency_code
                                            }
                                            onChange={(e) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "decliened_tnx_currency_code"
                                              );
                                            }}
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.declined_tnx_fees
                                                  ?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_refunded_tnx_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.refunded_tnx_fees
                                                ?.allow_refunded_tnx_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.refunded_tnx_fees
                                                ?.allow_refunded_tnx_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "refunded_tnx_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">
                                            Refunded Transaction Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.refunded_tnx_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.refunded_tnx_fees
                                                  ?.allow_refunded_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "refunded_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.refunded_tnx_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.refunded_tnx_fees
                                                  ?.fixed_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.refunded_tnx_fees
                                                  ?.allow_refunded_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "refunded_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.refunded_tnx_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.refunded_tnx_fees?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-2 py-4">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.refunded_tnx_fees
                                                ?.allow_refunded_tnx_fees
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            value={
                                              formValues?.additional_fees
                                                ?.refunded_tnx_fees?.currency_code
                                            }
                                            onChange={(e) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "refunded_tnx_currency_code"
                                              );
                                            }}
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.refunded_tnx_fees
                                                  ?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_reserve_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.reserve_fees?.allow_reserve_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.reserve_fees?.allow_reserve_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChange(
                                                e,
                                                "reserve_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">Reserve</span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.reserve_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.reserve_fees
                                                  ?.allow_reserve_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "reserve_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.reserve_fees?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="number_of_days"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Number of days "
                                              value={
                                                formValues?.additional_fees
                                                  ?.reserve_fees
                                                  ?.number_of_days ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.reserve_fees
                                                  ?.allow_reserve_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChange(
                                                  e,
                                                  "reserve_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {formValues?.additional_fees
                                                  ?.reserve_fees
                                                  ?.allow_reserve_fees
                                                  ? "Days"
                                                  : ""}
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.reserve_fees?.number_of_days
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    {formValues?.fess_conditions?.apm &&
                                      false && (
                                        <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                          <td className="w-[25%] px-2 py-4  text-gray-900">
                                            APM fees
                                          </td>
                                          <td className="py-2 ps-1">
                                            <div className="flex ">
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="percentage_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.apm_fees
                                                      ?.percentage_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "apm_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>

                                              <div className="mx-2 flex items-center">
                                                <span>+</span>
                                              </div>
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="fixed_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fixed fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.apm_fees?.fixed_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "apm_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">
                                                    {
                                                      formValues
                                                        ?.gateway_method_fees
                                                        ?.apm_fees?.currency_code
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                              <AntSelect
                                                showSearch
                                                placeholder="Select currency"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (option?.label ?? "").includes(
                                                    input?.toUpperCase()
                                                  )
                                                }
                                                filterSort={(optionA, optionB) =>
                                                  (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                      (
                                                        optionB?.label ?? ""
                                                      ).toLowerCase()
                                                    )
                                                }
                                                className="w-full"
                                                options={currencyCodes.map(
                                                  (currencyCode) => {
                                                    const currencyDetails =
                                                      getCurrencySymbol(
                                                        currencyCode
                                                      );
                                                    return {
                                                      value: currencyCode,
                                                      label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                    };
                                                  }
                                                )}
                                                value={
                                                  formValues?.gateway_method_fees
                                                    ?.apm_fees?.currency_code
                                                }
                                                onChange={(e) => {
                                                  handleGatewayMethodfeesValueChange(
                                                    e,
                                                    "apm_currency_code"
                                                  );
                                                }}
                                                notFoundContent={
                                                  "No options found"
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}

                                    {formValues?.fess_conditions?.payout &&
                                      false && (
                                        <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                          <td className="w-[25%] px-2 py-4  text-gray-900">
                                            Payout fees
                                          </td>
                                          <td className="py-2 ps-1">
                                            <div className="flex ">
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="percentage_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.payout_fees
                                                      ?.percentage_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "payout_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>

                                              <div className="mx-2 flex items-center">
                                                <span>+</span>
                                              </div>
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="fixed_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fixed fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.payout_fees?.fixed_fee ??
                                                    ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "payout_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">
                                                    {
                                                      formValues
                                                        ?.gateway_method_fees
                                                        ?.payout_fees
                                                        ?.currency_code
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                              <AntSelect
                                                showSearch
                                                placeholder="Select currency"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (option?.label ?? "").includes(
                                                    input?.toUpperCase()
                                                  )
                                                }
                                                filterSort={(optionA, optionB) =>
                                                  (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                      (
                                                        optionB?.label ?? ""
                                                      ).toLowerCase()
                                                    )
                                                }
                                                className="w-full"
                                                options={currencyCodes.map(
                                                  (currencyCode) => {
                                                    const currencyDetails =
                                                      getCurrencySymbol(
                                                        currencyCode
                                                      );
                                                    return {
                                                      value: currencyCode,
                                                      label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                    };
                                                  }
                                                )}
                                                value={
                                                  formValues?.gateway_method_fees
                                                    ?.payout_fees?.currency_code
                                                }
                                                onChange={(e) => {
                                                  handleGatewayMethodfeesValueChange(
                                                    e,
                                                    "payout_currency_code"
                                                  );
                                                }}
                                                notFoundContent={
                                                  "No options found"
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}

                                    {formValues?.fess_conditions?.payin &&
                                      false && (
                                        <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                          <td className="w-[25%] px-2 py-4  text-gray-900">
                                            Payin fees
                                          </td>
                                          <td className="py-2 ps-1">
                                            <div className="flex ">
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="percentage_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.payin_fees
                                                      ?.percentage_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "payin_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>

                                              <div className="mx-2 flex items-center">
                                                <span>+</span>
                                              </div>
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="fixed_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fixed fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.payin_fees?.fixed_fee ??
                                                    ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "payin_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">
                                                    {
                                                      formValues
                                                        ?.gateway_method_fees
                                                        ?.payin_fees
                                                        ?.currency_code
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                              <AntSelect
                                                showSearch
                                                placeholder="Select currency"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (option?.label ?? "").includes(
                                                    input?.toUpperCase()
                                                  )
                                                }
                                                filterSort={(optionA, optionB) =>
                                                  (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                      (
                                                        optionB?.label ?? ""
                                                      ).toLowerCase()
                                                    )
                                                }
                                                className="w-full"
                                                options={currencyCodes.map(
                                                  (currencyCode) => {
                                                    const currencyDetails =
                                                      getCurrencySymbol(
                                                        currencyCode
                                                      );
                                                    return {
                                                      value: currencyCode,
                                                      label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                    };
                                                  }
                                                )}
                                                value={
                                                  formValues?.gateway_method_fees
                                                    ?.payin_fees?.currency_code
                                                }
                                                onChange={(e) => {
                                                  handleGatewayMethodfeesValueChange(
                                                    e,
                                                    "payin_currency_code"
                                                  );
                                                }}
                                                notFoundContent={
                                                  "No options found"
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </p>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                  {/* Cost */}

                  {pricingRight && (
                    <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                      <h4>
                        <AccordionButton className="flex justify-between">
                          <span className="text-left font-bold text-navy-900 dark:text-white">
                            Cost{" "}
                            <span
                              id="standard_error_help"
                              className="mx-2 text-xs text-red-600 dark:text-red-400"
                            >
                              <span className="font-medium">
                                {formValuesErr?.client_card_types?.filter(
                                  (f: any) =>
                                    f?.allow_card &&
                                    (f?.cost_percentage_fee ||
                                      f?.cost_fixed_fee ||
                                      f?.cost_currency_code)
                                )?.length > 0 || isValidateAdditionalFeesCost
                                  ? "Please enter required fields of this section."
                                  : ""}
                              </span>
                            </span>
                          </span>
                          <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                        </AccordionButton>
                      </h4>
                      <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                        <p>
                          <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                            <div className="">
                              <span className="font-bold text-gray-900">
                                Card Type
                              </span>
                              <div className="relative z-0 overflow-x-auto  overflow-y-visible">
                                <table className="bg-green z-0 w-full overflow-y-visible text-left text-sm  text-gray-500 dark:text-gray-400 rtl:text-right">
                                  <tbody>
                                    {formValues?.cardTypes?.length > 0
                                      ? formValues?.cardTypes?.map(
                                          (card: any, index: number) => {
                                            return (
                                              <>
                                                <tr className="bg-midnight  z-0 max-h-[50px] overflow-x-auto overflow-y-visible bg-white dark:border-gray-700 dark:bg-gray-800">
                                                  <td className="w-[25%] px-2  py-4">
                                                    <div className="flex">
                                                      <Checkbox
                                                        id="allow_card"
                                                        value={
                                                          !formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.allow_card
                                                        }
                                                        disabled
                                                        title="Slection in based on Pricing."
                                                        checked={
                                                          formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )?.length > 0 &&
                                                          formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.allow_card
                                                        }
                                                        onChange={(e: any) => {
                                                          handlePriceValueChange(
                                                            e,
                                                            index,
                                                            card,
                                                            ""
                                                          );
                                                        }}
                                                      />
                                                      <span className="mx-2 text-gray-900">
                                                        {" "}
                                                        {card}
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td className="py-2 ps-1">
                                                    <div className="flex">
                                                      <div>
                                                        <div className="relative z-0 min-w-[100px] flex-auto">
                                                          <input
                                                            type="number"
                                                            id="cost_percentage_fee"
                                                            className={`bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500 
                                                          
                                                          ${
                                                            formValuesErr?.client_card_types?.filter(
                                                              (f: any) =>
                                                                f?.card_name ==
                                                                card
                                                            )[0]
                                                              ?.cost_percentage_fee
                                                              ? "border-red-600"
                                                              : ""
                                                          }
                                                          
                                                          `}
                                                            placeholder=" Fee "
                                                            value={
                                                              formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]
                                                                ?.cost_percentage_fee ??
                                                              ""
                                                            }
                                                            disabled={
                                                              !formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.allow_card
                                                            }
                                                            onChange={(e) => {
                                                              handlePriceValueChange(
                                                                e,
                                                                index,
                                                                card,
                                                                ""
                                                              );
                                                            }}
                                                          />
                                                          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                            <span className="h-6">
                                                              %
                                                            </span>
                                                          </div>
                                                        </div>

                                                        <span
                                                          id="standard_error_help"
                                                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                                                        >
                                                          <span className="font-medium">
                                                            {
                                                              formValuesErr?.client_card_types?.filter(
                                                                (f: any) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]
                                                                ?.cost_percentage_fee
                                                            }
                                                          </span>
                                                        </span>
                                                      </div>

                                                      <div className="mx-2 flex items-center">
                                                        <span>+</span>
                                                      </div>
                                                      <div>
                                                        <div className="relative z-0  min-w-[100px] flex-auto">
                                                          <input
                                                            type="number"
                                                            id="cost_fixed_fee"
                                                            className={`bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500
                                                          ${
                                                            formValuesErr?.client_card_types?.filter(
                                                              (f: any) =>
                                                                f?.card_name ==
                                                                card
                                                            )[0]?.cost_fixed_fee
                                                              ? "border-red-600"
                                                              : ""
                                                          }
                                                          `}
                                                            placeholder=" Fixed fee "
                                                            value={
                                                              formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]
                                                                ?.cost_fixed_fee ??
                                                              ""
                                                            }
                                                            disabled={
                                                              !formValues?.client_card_types?.filter(
                                                                (
                                                                  f: any,
                                                                  i: number
                                                                ) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.allow_card
                                                            }
                                                            onChange={(e) => {
                                                              handlePriceValueChange(
                                                                e,
                                                                index,
                                                                card,
                                                                ""
                                                              );
                                                            }}
                                                          />
                                                          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                            <span className="h-6">
                                                              {
                                                                formValues?.client_card_types?.filter(
                                                                  (
                                                                    f: any,
                                                                    i: number
                                                                  ) =>
                                                                    f?.card_name ==
                                                                    card
                                                                )[0]
                                                                  ?.cost_currency_code
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <span
                                                          id="standard_error_help"
                                                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                                                        >
                                                          <span className="font-medium">
                                                            {
                                                              formValuesErr?.client_card_types?.filter(
                                                                (f: any) =>
                                                                  f?.card_name ==
                                                                  card
                                                              )[0]?.cost_fixed_fee
                                                            }
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className=" z-0 px-2 py-4">
                                                    <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                                      <AntSelect
                                                        showSearch
                                                        placeholder="Select currency"
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                          input,
                                                          option
                                                        ) =>
                                                          (
                                                            option?.label ?? ""
                                                          ).includes(
                                                            input?.toUpperCase()
                                                          )
                                                        }
                                                        filterSort={(
                                                          optionA,
                                                          optionB
                                                        ) =>
                                                          (optionA?.label ?? "")
                                                            .toLowerCase()
                                                            .localeCompare(
                                                              (
                                                                optionB?.label ??
                                                                ""
                                                              ).toLowerCase()
                                                            )
                                                        }
                                                        className={`w-full
                                                        ${
                                                          formValuesErr?.client_card_types?.filter(
                                                            (f: any) =>
                                                              f?.card_name == card
                                                          )[0]?.cost_currency_code
                                                            ? "border-red-600"
                                                            : ""
                                                        }
                                                        `}
                                                        disabled={
                                                          !formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.allow_card
                                                        }
                                                        options={currencyCodes.map(
                                                          (currencyCode) => {
                                                            const currencyDetails =
                                                              getCurrencySymbol(
                                                                currencyCode
                                                              );
                                                            return {
                                                              value: currencyCode,
                                                              label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                            };
                                                          }
                                                        )}
                                                        onChange={(e: any) => {
                                                          handlePriceValueChange(
                                                            e,
                                                            index,
                                                            card,
                                                            "cost_cardType_currency_code"
                                                          );
                                                        }}
                                                        value={
                                                          formValues?.client_card_types?.filter(
                                                            (f: any, i: number) =>
                                                              f?.card_name == card
                                                          )[0]?.cost_currency_code
                                                        }
                                                        notFoundContent={
                                                          "No options found"
                                                        }
                                                      />
                                                      <span
                                                        id="standard_error_help"
                                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                                      >
                                                        <span className="font-medium">
                                                          {
                                                            formValuesErr?.client_card_types?.filter(
                                                              (f: any) =>
                                                                f?.card_name ==
                                                                card
                                                            )[0]
                                                              ?.cost_currency_code
                                                          }
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )
                                      : ""}
                                  </tbody>
                                </table>
                              </div>
                              <span className="font-bold text-gray-900">
                                Additional Fees
                              </span>
                              <div className="add_clas relative z-0 z-0 overflow-x-auto overflow-y-visible">
                                <table className="bg-green z-0 w-full overflow-y-visible text-left text-sm  text-gray-500 dark:text-gray-400 rtl:text-right">
                                  <tbody>
                                    <tr className="bg-midnight  z-0 max-h-[50px] overflow-x-auto overflow-y-visible bg-white dark:border-gray-700 dark:bg-gray-800">
                                      <td className="w-[25%] px-2  py-4 text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_chargeback_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.cost_chargeback_fees
                                                ?.allow_chargeback_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.cost_chargeback_fees
                                                ?.allow_chargeback_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_chargeback_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2">
                                            Chargeback Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_chargeback_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_chargeback_fees
                                                  ?.allow_chargeback_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_chargeback_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_chargeback_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_chargeback_fees
                                                  ?.allow_chargeback_fees
                                              }
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_chargeback_fees
                                                  ?.fixed_fee ?? ""
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_chargeback_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.cost_chargeback_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_chargeback_fees
                                                    ?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="z-0 px-2 py-4 ">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.cost_chargeback_fees
                                                ?.allow_chargeback_fees
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            onChange={(e) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_chargeback_currency_code"
                                              );
                                            }}
                                            value={
                                              formValues?.additional_fees
                                                ?.cost_chargeback_fees
                                                ?.currency_code
                                            }
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.cost_chargeback_fees
                                                  ?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_dispute_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.cost_dispute_fees
                                                ?.allow_dispute_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.cost_dispute_fees
                                                ?.allow_dispute_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_dispute_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2">
                                            Dispute Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_dispute_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_dispute_fees
                                                  ?.allow_dispute_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_dispute_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_dispute_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_dispute_fees
                                                  ?.fixed_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_dispute_fees
                                                  ?.allow_dispute_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_dispute_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.cost_dispute_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_dispute_fees?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-2 py-4">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.cost_dispute_fees
                                                ?.allow_dispute_fees
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            value={
                                              formValues?.additional_fees
                                                ?.cost_dispute_fees?.currency_code
                                            }
                                            onChange={(e) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_dispute_currency_code"
                                              );
                                            }}
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.cost_dispute_fees
                                                  ?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_declined_tnx_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.cost_declined_tnx_fees
                                                ?.allow_declined_tnx_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.cost_declined_tnx_fees
                                                ?.allow_declined_tnx_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_declined_tnx_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">
                                            Declined Transaction Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_declined_tnx_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_declined_tnx_fees
                                                  ?.allow_declined_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_declined_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_declined_tnx_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_declined_tnx_fees
                                                  ?.fixed_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_declined_tnx_fees
                                                  ?.allow_declined_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_declined_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.cost_declined_tnx_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_declined_tnx_fees
                                                    ?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-2 py-4">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.cost_declined_tnx_fees
                                                ?.allow_declined_tnx_fees
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            value={
                                              formValues?.additional_fees
                                                ?.cost_declined_tnx_fees
                                                ?.currency_code
                                            }
                                            onChange={(e) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_decliened_tnx_currency_code"
                                              );
                                            }}
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.cost_declined_tnx_fees
                                                  ?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_refunded_tnx_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.cost_refunded_tnx_fees
                                                ?.allow_refunded_tnx_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.cost_refunded_tnx_fees
                                                ?.allow_refunded_tnx_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_refunded_tnx_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">
                                            Refunded Transaction Fee
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_refunded_tnx_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_refunded_tnx_fees
                                                  ?.allow_refunded_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_refunded_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_refunded_tnx_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="fixed_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fixed fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_refunded_tnx_fees
                                                  ?.fixed_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_refunded_tnx_fees
                                                  ?.allow_refunded_tnx_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_refunded_tnx_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {
                                                  formValues?.additional_fees
                                                    ?.cost_refunded_tnx_fees
                                                    ?.currency_code
                                                }
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_refunded_tnx_fees
                                                    ?.fixed_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-2 py-4">
                                        <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                          <AntSelect
                                            showSearch
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").includes(
                                                input?.toUpperCase()
                                              )
                                            }
                                            disabled={
                                              !formValues?.additional_fees
                                                ?.cost_refunded_tnx_fees
                                                ?.allow_refunded_tnx_fees
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            className="w-full"
                                            options={currencyCodes.map(
                                              (currencyCode) => {
                                                const currencyDetails =
                                                  getCurrencySymbol(currencyCode);
                                                return {
                                                  value: currencyCode,
                                                  label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                };
                                              }
                                            )}
                                            value={
                                              formValues?.additional_fees
                                                ?.cost_refunded_tnx_fees
                                                ?.currency_code
                                            }
                                            onChange={(e) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_refunded_tnx_currency_code"
                                              );
                                            }}
                                            notFoundContent={"No options found"}
                                          />
                                          <span
                                            id="standard_error_help"
                                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                                          >
                                            <span className="font-medium">
                                              {
                                                formValuesErr?.additional_fees
                                                  ?.cost_refunded_tnx_fees
                                                  ?.currency_code
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                      <td className="w-[25%] px-2 py-4  text-gray-900">
                                        <div className="flex">
                                          <Checkbox
                                            id="allow_reserve_fees"
                                            value={
                                              !formValues?.additional_fees
                                                ?.cost_reserve_fees
                                                ?.allow_reserve_fees
                                            }
                                            checked={
                                              formValues?.additional_fees
                                                ?.cost_reserve_fees
                                                ?.allow_reserve_fees
                                            }
                                            onChange={(e: any) => {
                                              handleAdditionalValueChangeOfCost(
                                                e,
                                                "cost_reserve_fees"
                                              );
                                            }}
                                          />
                                          <span className="mx-2 ">Reserve</span>
                                        </div>
                                      </td>
                                      <td className="py-2 ps-1">
                                        <div className="flex ">
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="percentage_fee"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Fee "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_reserve_fees
                                                  ?.percentage_fee ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_reserve_fees
                                                  ?.allow_reserve_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_reserve_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">%</span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_reserve_fees
                                                    ?.percentage_fee
                                                }
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mx-2 flex items-center">
                                            <span>+</span>
                                          </div>
                                          <div className="relative z-0 min-w-[100px] flex-auto">
                                            <input
                                              type="number"
                                              id="number_of_days"
                                              className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                              placeholder=" Number of days "
                                              value={
                                                formValues?.additional_fees
                                                  ?.cost_reserve_fees
                                                  ?.number_of_days ?? ""
                                              }
                                              disabled={
                                                !formValues?.additional_fees
                                                  ?.cost_reserve_fees
                                                  ?.allow_reserve_fees
                                              }
                                              onChange={(e) => {
                                                handleAdditionalValueChangeOfCost(
                                                  e,
                                                  "cost_reserve_fees"
                                                );
                                              }}
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                              <span className="h-6">
                                                {formValues?.additional_fees
                                                  ?.cost_reserve_fees
                                                  ?.number_of_days
                                                  ? "Days"
                                                  : ""}
                                              </span>
                                            </div>
                                            <span
                                              id="standard_error_help"
                                              className="mt-2 text-xs text-red-600 dark:text-red-400"
                                            >
                                              <span className="font-medium">
                                                {
                                                  formValuesErr?.additional_fees
                                                    ?.cost_reserve_fees
                                                    ?.number_of_days
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    {formValues?.fess_conditions?.apm &&
                                      false && (
                                        <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                          <td className="w-[25%] px-2 py-4  text-gray-900">
                                            APM fees
                                          </td>
                                          <td className="py-2 ps-1">
                                            <div className="flex ">
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="percentage_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.cost_apm_fees
                                                      ?.percentage_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "cost_apm_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>

                                              <div className="mx-2 flex items-center">
                                                <span>+</span>
                                              </div>
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="fixed_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fixed fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.cost_apm_fees
                                                      ?.fixed_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "cost_apm_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">
                                                    {
                                                      formValues
                                                        ?.gateway_method_fees
                                                        ?.cost_apm_fees
                                                        ?.currency_code
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                              <AntSelect
                                                showSearch
                                                placeholder="Select currency"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (option?.label ?? "").includes(
                                                    input?.toUpperCase()
                                                  )
                                                }
                                                filterSort={(optionA, optionB) =>
                                                  (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                      (
                                                        optionB?.label ?? ""
                                                      ).toLowerCase()
                                                    )
                                                }
                                                className="w-full"
                                                options={currencyCodes.map(
                                                  (currencyCode) => {
                                                    const currencyDetails =
                                                      getCurrencySymbol(
                                                        currencyCode
                                                      );
                                                    return {
                                                      value: currencyCode,
                                                      label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                    };
                                                  }
                                                )}
                                                value={
                                                  formValues?.gateway_method_fees
                                                    ?.cost_apm_fees?.currency_code
                                                }
                                                onChange={(e) => {
                                                  handleGatewayMethodfeesValueChange(
                                                    e,
                                                    "cost_apm_currency_code"
                                                  );
                                                }}
                                                notFoundContent={
                                                  "No options found"
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}

                                    {formValues?.fess_conditions?.payout &&
                                      false && (
                                        <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                          <td className="w-[25%] px-2 py-4  text-gray-900">
                                            Payout fees
                                          </td>
                                          <td className="py-2 ps-1">
                                            <div className="flex ">
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="percentage_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.cost_payout_fees
                                                      ?.percentage_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "cost_payout_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>

                                              <div className="mx-2 flex items-center">
                                                <span>+</span>
                                              </div>
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="fixed_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fixed fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.cost_payout_fees
                                                      ?.fixed_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "cost_payout_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">
                                                    {
                                                      formValues
                                                        ?.gateway_method_fees
                                                        ?.cost_payout_fees
                                                        ?.currency_code
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                              <AntSelect
                                                showSearch
                                                placeholder="Select currency"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (option?.label ?? "").includes(
                                                    input?.toUpperCase()
                                                  )
                                                }
                                                filterSort={(optionA, optionB) =>
                                                  (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                      (
                                                        optionB?.label ?? ""
                                                      ).toLowerCase()
                                                    )
                                                }
                                                className="w-full"
                                                options={currencyCodes.map(
                                                  (currencyCode) => {
                                                    const currencyDetails =
                                                      getCurrencySymbol(
                                                        currencyCode
                                                      );
                                                    return {
                                                      value: currencyCode,
                                                      label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                    };
                                                  }
                                                )}
                                                value={
                                                  formValues?.gateway_method_fees
                                                    ?.cost_payout_fees
                                                    ?.currency_code
                                                }
                                                onChange={(e) => {
                                                  handleGatewayMethodfeesValueChange(
                                                    e,
                                                    "cost_payout_currency_code"
                                                  );
                                                }}
                                                notFoundContent={
                                                  "No options found"
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}

                                    {formValues?.fess_conditions?.payin &&
                                      false && (
                                        <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                          <td className="w-[25%] px-2 py-4  text-gray-900">
                                            Payin fees
                                          </td>
                                          <td className="py-2 ps-1">
                                            <div className="flex ">
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="percentage_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.cost_payin_fees
                                                      ?.percentage_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "cost_payin_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>

                                              <div className="mx-2 flex items-center">
                                                <span>+</span>
                                              </div>
                                              <div className="relative z-0 min-w-[100px] flex-auto">
                                                <input
                                                  type="number"
                                                  id="fixed_fee"
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fixed fee "
                                                  value={
                                                    formValues
                                                      ?.gateway_method_fees
                                                      ?.cost_payin_fees
                                                      ?.fixed_fee ?? ""
                                                  }
                                                  onChange={(e) => {
                                                    handleGatewayMethodfeesValueChange(
                                                      e,
                                                      "cost_payin_fees"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">
                                                    {
                                                      formValues
                                                        ?.gateway_method_fees
                                                        ?.cost_payin_fees
                                                        ?.currency_code
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                              <AntSelect
                                                showSearch
                                                placeholder="Select currency"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (option?.label ?? "").includes(
                                                    input?.toUpperCase()
                                                  )
                                                }
                                                filterSort={(optionA, optionB) =>
                                                  (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                      (
                                                        optionB?.label ?? ""
                                                      ).toLowerCase()
                                                    )
                                                }
                                                className="w-full"
                                                options={currencyCodes.map(
                                                  (currencyCode) => {
                                                    const currencyDetails =
                                                      getCurrencySymbol(
                                                        currencyCode
                                                      );
                                                    return {
                                                      value: currencyCode,
                                                      label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                                    };
                                                  }
                                                )}
                                                value={
                                                  formValues?.gateway_method_fees
                                                    ?.cost_payin_fees
                                                    ?.currency_code
                                                }
                                                onChange={(e) => {
                                                  handleGatewayMethodfeesValueChange(
                                                    e,
                                                    "cost_payin_currency_code"
                                                  );
                                                }}
                                                notFoundContent={
                                                  "No options found"
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </p>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                  <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                    <h4>
                      <AccordionButton className="flex justify-between">
                        <span className="text-left font-bold text-navy-900 dark:text-white">
                          Whitelist{" "}
                        </span>
                        <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                      <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                        <div className="relative overflow-x-auto overflow-y-visible">
                          <div className="mt-4 flex justify-end">
                            <label className="relative mb-3 inline-flex cursor-pointer items-center">
                              <input
                                type="checkbox"
                                className="peer sr-only"
                                checked={formValues?.whitelist_enabled}
                                id={"whitelist_enabled"}
                                onChange={(e) => {
                                  const id = e.target.id;
                                  let value = e.target.value;
                                  setFormValues((preVal: any) => {
                                    return {
                                      ...preVal,
                                      [id]: !formValues?.whitelist_enabled,
                                    };
                                  });
                                }}
                              />

                              <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                              <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                                {formValues?.whitelist_enabled
                                  ? "Whitelist"
                                  : "Whitelist"}
                              </span>
                            </label>
                          </div>

                          <WhiteListClients
                            gatewayTypes={gatewayTypes}
                            type={type}
                            client_id={id}
                          />
                        </div>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                    <h4>
                      <AccordionButton className="flex justify-between">
                        <span className="text-left font-bold text-navy-900 dark:text-white">
                          Advanced AVS{" "}
                        </span>
                        <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                      <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                        <div className="relative overflow-x-auto overflow-y-visible">
                          <table className="w-full overflow-y-visible text-left text-sm text-gray-500 dark:text-gray-400  rtl:text-right">
                            <tbody>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="validate_address"
                                      value={
                                        !formValues?.advanced_avs?.validate_address
                                      }
                                      checked={
                                        formValues?.advanced_avs?.validate_address
                                      }
                                      onChange={(e: any) => {
                                        handleAdvancedAVSChange(
                                          e,
                                          "validate_address"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Validate Address
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="validate_phone_number"
                                      value={
                                        !formValues?.advanced_avs?.validate_phone_number
                                      }
                                      checked={
                                        formValues?.advanced_avs?.validate_phone_number
                                      }
                                      onChange={(e: any) => {
                                        handleAdvancedAVSChange(
                                          e,
                                          "validate_phone_number"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Validate Phone Number
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="validate_email"
                                      value={
                                        !formValues?.advanced_avs?.validate_email
                                      }
                                      checked={
                                        formValues?.advanced_avs?.validate_email
                                      }
                                      onChange={(e: any) => {
                                        handleAdvancedAVSChange(
                                          e,
                                          "validate_email"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Validate Email
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="validate_ip_address"
                                      value={
                                        !formValues?.advanced_avs?.validate_ip_address
                                      }
                                      checked={
                                        formValues?.advanced_avs?.validate_ip_address
                                      }
                                      onChange={(e: any) => {
                                        handleAdvancedAVSChange(
                                          e,
                                          "validate_ip_address"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Validate IP Address
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>                

                  <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                    <h4>
                      <AccordionButton className="flex justify-between">
                        <span className="text-left font-bold text-navy-900 dark:text-white">
                          Velocity{" "}
                          <span
                            id="standard_error_help"
                            className="mx-2 text-xs text-red-600 dark:text-red-400"
                          >
                            <span className="font-medium">
                              {isValidateVelocity
                                ? "Please enter required fields of this section."
                                : ""}
                            </span>
                          </span>
                        </span>
                        <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                      <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                        <div className="relative overflow-x-auto overflow-y-visible">
                          <table className="w-full overflow-y-visible text-left text-sm text-gray-500 dark:text-gray-400  rtl:text-right">
                            <tbody>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_transaction_limit"
                                      value={
                                        !formValues?.velocity?.transaction_limit
                                          ?.allow_transaction_limit
                                      }
                                      checked={
                                        formValues?.velocity?.transaction_limit
                                          ?.allow_transaction_limit
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_transaction_limit",
                                          "transaction_limit"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Transaction Limit
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_no"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Limit No. "
                                        value={
                                          formValues?.velocity?.transaction_limit
                                            ?.limit_no ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity?.transaction_limit
                                            ?.allow_transaction_limit
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_no",
                                            "transaction_limit"
                                          );
                                        }}
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                        <span className="h-6">
                                          {
                                            formValues?.velocity
                                              ?.transaction_limit?.period
                                          }
                                        </span>
                                      </div>
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.transaction_limit?.limit_no
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-2 py-4">
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                    <AntSelect
                                      showSearch
                                      placeholder="Select Period"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(
                                          input?.toUpperCase()
                                        )
                                      }
                                      disabled={
                                        !formValues?.velocity?.transaction_limit
                                          ?.allow_transaction_limit
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="w-full"
                                      options={[
                                        { label: "Day", value: "Day" },
                                        { label: "Week", value: "Week" },
                                        { label: "Month", value: "Month" },
                                      ]}
                                      value={
                                        formValues?.velocity?.transaction_limit
                                          ?.period || undefined
                                      }
                                      onChange={(e) => {
                                        handleVelocityValueChange(
                                          e,
                                          "period",
                                          "transaction_limit"
                                        );
                                      }}
                                      notFoundContent={"No options found"}
                                    />
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.transaction_limit?.period
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_blocked_countries"
                                      value={
                                        !formValues?.velocity?.blocked_countries
                                          ?.allow_blocked_countries
                                      }
                                      checked={
                                        formValues?.velocity?.blocked_countries
                                          ?.allow_blocked_countries
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_blocked_countries",
                                          "blocked_countries"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Blocked Countries
                                    </span>
                                  </div>
                                </td>
                                <td className="px-2 py-4" colSpan={2}>
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[300px] min-w-[150px] overflow-visible">
                                    <div className="flex-auto justify-center">
                                      <MultiSelect
                                        options={CountryOptions}
                                        value={
                                          formValues?.velocity?.blocked_countries
                                            ?.country_code || []
                                        }
                                        disabled={
                                          !formValues?.velocity?.blocked_countries
                                            ?.allow_blocked_countries
                                        }
                                        onChange={(e: any) => {
                                          handleVelocityValueChange(
                                            e,
                                            "country_code",
                                            "blocked_countries"
                                          );
                                        }}
                                        labelledBy={"Organization(s)"}
                                        isCreatable={false}
                                        className="!bg-slate-100 my-2"
                                      />
                                    </div>
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.blocked_countries?.country_code
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_blocked_countries_bin"
                                      value={
                                        !formValues?.velocity
                                          ?.blocked_countries_bin
                                          ?.allow_blocked_countries_bin
                                      }
                                      checked={
                                        formValues?.velocity
                                          ?.blocked_countries_bin
                                          ?.allow_blocked_countries_bin
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_blocked_countries_bin",
                                          "blocked_countries_bin"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Blocked Countries BIN
                                    </span>
                                  </div>
                                </td>
                                <td className="px-2 py-4" colSpan={2}>
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[300px] min-w-[150px] overflow-visible">
                                    <div className="flex-auto justify-center">
                                      <MultiSelect
                                        options={binCountryOptions}
                                        value={
                                          formValues?.velocity
                                            ?.blocked_countries_bin
                                            ?.country_code || []
                                        }
                                        disabled={
                                          !formValues?.velocity
                                            ?.blocked_countries_bin
                                            ?.allow_blocked_countries_bin
                                        }
                                        onChange={(e: any) => {
                                          handleVelocityValueChange(
                                            e,
                                            "country_code",
                                            "blocked_countries_bin"
                                          );
                                        }}
                                        labelledBy={"Organization(s)"}
                                        isCreatable={false}
                                        className="!bg-slate-100 my-2"
                                      />
                                    </div>
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.blocked_countries_bin?.country_code
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_max_transaction_limit"
                                      value={
                                        !formValues?.velocity
                                          ?.max_transaction_limit
                                          ?.allow_max_transaction_limit
                                      }
                                      checked={
                                        formValues?.velocity
                                          ?.max_transaction_limit
                                          ?.allow_max_transaction_limit
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_max_transaction_limit",
                                          "max_transaction_limit"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Max Amount Per Transaction
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_amount"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Amount "
                                        value={
                                          formValues?.velocity
                                            ?.max_transaction_limit
                                            ?.limit_amount ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity
                                            ?.max_transaction_limit
                                            ?.allow_max_transaction_limit
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_amount",
                                            "max_transaction_limit"
                                          );
                                        }}
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                        <span className="h-6">
                                          {
                                            formValues?.velocity
                                              ?.max_transaction_limit?.currency
                                          }
                                        </span>
                                      </div>
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.max_transaction_limit
                                              ?.limit_amount
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-2 py-4">
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                    <AntSelect
                                      showSearch
                                      placeholder="Select Currency"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(
                                          input?.toUpperCase()
                                        )
                                      }
                                      disabled={
                                        !formValues?.velocity
                                          ?.max_transaction_limit
                                          ?.allow_max_transaction_limit
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="w-full"
                                      options={currencyCodes.map(
                                        (currencyCode) => {
                                          const currencyDetails =
                                            getCurrencySymbol(currencyCode);
                                          return {
                                            value: currencyCode,
                                            label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                          };
                                        }
                                      )}
                                      value={
                                        formValues?.velocity
                                          ?.max_transaction_limit?.currency ||
                                        undefined
                                      }
                                      onChange={(e) => {
                                        handleVelocityValueChange(
                                          e,
                                          "currency",
                                          "max_transaction_limit"
                                        );
                                      }}
                                      notFoundContent={"No options found"}
                                    />
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.max_transaction_limit?.currency
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_min_transaction_limit"
                                      value={
                                        !formValues?.velocity
                                          ?.min_transaction_limit
                                          ?.allow_min_transaction_limit
                                      }
                                      checked={
                                        formValues?.velocity
                                          ?.min_transaction_limit
                                          ?.allow_min_transaction_limit
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_min_transaction_limit",
                                          "min_transaction_limit"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Min Amount Per Transaction
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_amount"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Amount "
                                        value={
                                          formValues?.velocity
                                            ?.min_transaction_limit
                                            ?.limit_amount ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity
                                            ?.min_transaction_limit
                                            ?.allow_min_transaction_limit
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_amount",
                                            "min_transaction_limit"
                                          );
                                        }}
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                        <span className="h-6">
                                          {
                                            formValues?.velocity
                                              ?.min_transaction_limit?.currency
                                          }
                                        </span>
                                      </div>
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.min_transaction_limit
                                              ?.limit_amount
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-2 py-4">
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                    <AntSelect
                                      showSearch
                                      placeholder="Select Currency"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(
                                          input?.toUpperCase()
                                        )
                                      }
                                      disabled={
                                        !formValues?.velocity
                                          ?.min_transaction_limit
                                          ?.allow_min_transaction_limit
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="w-full"
                                      options={currencyCodes.map(
                                        (currencyCode) => {
                                          const currencyDetails =
                                            getCurrencySymbol(currencyCode);
                                          return {
                                            value: currencyCode,
                                            label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                          };
                                        }
                                      )}
                                      value={
                                        formValues?.velocity
                                          ?.min_transaction_limit?.currency ||
                                        undefined
                                      }
                                      onChange={(e) => {
                                        handleVelocityValueChange(
                                          e,
                                          "currency",
                                          "min_transaction_limit"
                                        );
                                      }}
                                      notFoundContent={"No options found"}
                                    />
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.min_transaction_limit?.currency
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_max_transaction_period"
                                      value={
                                        !formValues?.velocity
                                          ?.max_transaction_period
                                          ?.allow_max_transaction_period
                                      }
                                      checked={
                                        formValues?.velocity
                                          ?.max_transaction_period
                                          ?.allow_max_transaction_period
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_max_transaction_period",
                                          "max_transaction_period"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Max Transaction Amount
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_amount"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Amount "
                                        value={
                                          formValues?.velocity
                                            ?.max_transaction_period
                                            ?.limit_amount ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity
                                            ?.max_transaction_period
                                            ?.allow_max_transaction_period
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_amount",
                                            "max_transaction_period"
                                          );
                                        }}
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                        <span className="h-6">
                                          {
                                            formValues?.velocity
                                              ?.max_transaction_period?.currency
                                          }
                                        </span>
                                      </div>
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.max_transaction_period
                                              ?.limit_amount
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-2 py-4">
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                    <AntSelect
                                      showSearch
                                      placeholder="Select Currency"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(
                                          input?.toUpperCase()
                                        )
                                      }
                                      disabled={
                                        !formValues?.velocity
                                          ?.max_transaction_period
                                          ?.allow_max_transaction_period
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="w-full"
                                      options={currencyCodes.map(
                                        (currencyCode) => {
                                          const currencyDetails =
                                            getCurrencySymbol(currencyCode);
                                          return {
                                            value: currencyCode,
                                            label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                                          };
                                        }
                                      )}
                                      value={
                                        formValues?.velocity
                                          ?.max_transaction_period?.currency ||
                                        undefined
                                      }
                                      onChange={(e) => {
                                        handleVelocityValueChange(
                                          e,
                                          "currency",
                                          "max_transaction_period"
                                        );
                                      }}
                                      notFoundContent={"No options found"}
                                    />
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.max_transaction_period?.currency
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                                <td className="px-2 py-4">
                                  <div className=" bg-green z-0 z-0 max-h-[50px] w-[170px] min-w-[150px] max-w-[200px] overflow-visible">
                                    <AntSelect
                                      showSearch
                                      placeholder="Select Period"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(
                                          input?.toUpperCase()
                                        )
                                      }
                                      disabled={
                                        !formValues?.velocity
                                          ?.max_transaction_period
                                          ?.allow_max_transaction_period
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="w-full"
                                      options={[
                                        { label: "Day", value: "Day" },
                                        { label: "Week", value: "Week" },
                                        { label: "Month", value: "Month" },
                                      ]}
                                      value={
                                        formValues?.velocity
                                          ?.max_transaction_period?.period ||
                                        undefined
                                      }
                                      onChange={(e) => {
                                        handleVelocityValueChange(
                                          e,
                                          "period",
                                          "max_transaction_period"
                                        );
                                      }}
                                      notFoundContent={"No options found"}
                                    />
                                    <span
                                      id="standard_error_help"
                                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      <span className="font-medium">
                                        {
                                          formValuesErr?.velocity
                                            ?.max_transaction_period?.period
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_retry_time"
                                      value={
                                        !formValues?.velocity?.retry_time
                                          ?.allow_retry_time
                                      }
                                      checked={
                                        formValues?.velocity?.retry_time
                                          ?.allow_retry_time
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_retry_time",
                                          "retry_time"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">Retry Time</span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_minutes"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Minutes "
                                        value={
                                          formValues?.velocity?.retry_time
                                            ?.limit_minutes ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity?.retry_time
                                            ?.allow_retry_time
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_minutes",
                                            "retry_time"
                                          );
                                        }}
                                      />

                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity?.retry_time
                                              ?.limit_minutes
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_max_attempts_day"
                                      value={
                                        !formValues?.velocity?.max_attempts_day
                                          ?.allow_max_attempts_day
                                      }
                                      checked={
                                        formValues?.velocity?.max_attempts_day
                                          ?.allow_max_attempts_day
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_max_attempts_day",
                                          "max_attempts_day"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Max Attempts Per Card(Day)
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_no"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Limit No. "
                                        value={
                                          formValues?.velocity?.max_attempts_day
                                            ?.limit_no ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity?.max_attempts_day
                                            ?.allow_max_attempts_day
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_no",
                                            "max_attempts_day"
                                          );
                                        }}
                                      />
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.max_attempts_day?.limit_no
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_max_attempts_week"
                                      value={
                                        !formValues?.velocity?.max_attempts_week
                                          ?.allow_max_attempts_week
                                      }
                                      checked={
                                        formValues?.velocity?.max_attempts_week
                                          ?.allow_max_attempts_week
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_max_attempts_week",
                                          "max_attempts_week"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Max Attempts Per Card(Week)
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_no"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Limit No. "
                                        value={
                                          formValues?.velocity?.max_attempts_week
                                            ?.limit_no ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity?.max_attempts_week
                                            ?.allow_max_attempts_week
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_no",
                                            "max_attempts_week"
                                          );
                                        }}
                                      />

                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.max_attempts_week?.limit_no
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_max_attempts_month"
                                      value={
                                        !formValues?.velocity?.max_attempts_month
                                          ?.allow_max_attempts_month
                                      }
                                      checked={
                                        formValues?.velocity?.max_attempts_month
                                          ?.allow_max_attempts_month
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_max_attempts_month",
                                          "max_attempts_month"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Max Attempts Per Card(Month)
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_no"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Limit No. "
                                        value={
                                          formValues?.velocity?.max_attempts_month
                                            ?.limit_no ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity
                                            ?.max_attempts_month
                                            ?.allow_max_attempts_month
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_no",
                                            "max_attempts_month"
                                          );
                                        }}
                                      />
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.max_attempts_month?.limit_no
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr className="bg-midnight max-h-[50px z-0 overflow-y-visible bg-white dark:bg-gray-800">
                                <td className="w-[25%] px-2 py-4  text-gray-900">
                                  <div className="flex">
                                    <Checkbox
                                      id="allow_max_attempts_total"
                                      value={
                                        !formValues?.velocity?.max_attempts_total
                                          ?.allow_max_attempts_total
                                      }
                                      checked={
                                        formValues?.velocity?.max_attempts_total
                                          ?.allow_max_attempts_total
                                      }
                                      onChange={(e: any) => {
                                        handleVelocityValueChange(
                                          e,
                                          "allow_max_attempts_total",
                                          "max_attempts_total"
                                        );
                                      }}
                                    />
                                    <span className="mx-2 ">
                                      Max Attempts Per Card(Total)
                                    </span>
                                  </div>
                                </td>
                                <td className="py-2 ps-1">
                                  <div className="flex ">
                                    <div className="relative z-0 min-w-[100px] flex-auto">
                                      <input
                                        type="number"
                                        id="limit_no"
                                        className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                        placeholder=" Limit No. "
                                        value={
                                          formValues?.velocity?.max_attempts_total
                                            ?.limit_no ?? ""
                                        }
                                        disabled={
                                          !formValues?.velocity
                                            ?.max_attempts_total
                                            ?.allow_max_attempts_total
                                        }
                                        onChange={(e) => {
                                          handleVelocityValueChange(
                                            e,
                                            "limit_no",
                                            "max_attempts_total"
                                          );
                                        }}
                                      />
                                      <span
                                        id="standard_error_help"
                                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                                      >
                                        <span className="font-medium">
                                          {
                                            formValuesErr?.velocity
                                              ?.max_attempts_total?.limit_no
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>

                  {settelmentRight && (
                    <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
                      <h4>
                        <AccordionButton className="flex justify-between">
                          <span className="text-left font-bold text-navy-900 dark:text-white">
                            Settlement
                          </span>
                          <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                        </AccordionButton>
                      </h4>
                      <AccordionPanel className="text-medium mt-2 text-left !text-navy-900 dark:!text-white">
                        <div className="mt-5 max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none sm:max-h-[calc(100vh-200px)]">
                          {false && (
                            <div className="relative overflow-x-auto overflow-y-visible">
                              <table className="w-full overflow-y-visible text-left text-sm text-gray-500 dark:text-gray-400  rtl:text-right">
                                <tbody>
                                  <tr className="  z-0 max-h-[50px]  overflow-y-visible">
                                    <td className="px-2 py-3  font-bold text-gray-900">
                                      Payout Fees
                                    </td>
                                    <td className=" px-2 py-3">
                                      <div className="flex px-4 ">
                                        <div className="relative z-0   min-w-[100px]">
                                          <input
                                            type="number"
                                            id="percentage"
                                            className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                            placeholder=" Fee "
                                            value={
                                              formValues?.payout_fees
                                                ?.percentage ?? ""
                                            }
                                            onChange={(e) => {
                                              handlePayoutValueChange(
                                                e,
                                                "payout_fees"
                                              );
                                            }}
                                          />
                                          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                            <span className="h-6">%</span>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 py-3"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}

                          {
                            <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
                              <thead className="">
                                <tr>
                                  <td
                                    scope="col"
                                    className="px-2 py-3  font-bold text-gray-900"
                                  >
                                    Wallets
                                  </td>
                                  <th
                                    scope="col"
                                    className=" py-3 text-start "
                                  ></th>
                                  <th scope="col" className="px-2 py-3 "></th>
                                </tr>
                              </thead>
                              <tbody>
                                {formValues?.wallet_ids?.length == 0 &&
                                  formValues?.ewallet_New_addressess?.length ==
                                    0 && (
                                    <tr>
                                      <td
                                        scope="row"
                                        className="whitespace-nowrap px-2 py-4 font-medium text-gray-900 dark:text-white"
                                      >
                                        <button
                                          className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                                          title="Add new wallet"
                                          onClick={() => {
                                            handleNewWallet("add_New_wallet", "");
                                          }}
                                        >
                                          Add wallet details
                                        </button>
                                      </td>
                                    </tr>
                                  )}

                                {formValues?.wallet_ids?.map(
                                  (e_data: any, wIndex: number, wAA: any) => {
                                    return (
                                      <>
                                        <tr className="bg-white dark:bg-gray-800">
                                          <td
                                            scope="row"
                                            className="whitespace-nowrap px-2 py-4 font-medium text-gray-900 dark:text-white"
                                          >
                                            <p className="flex items-center justify-start">
                                              <input
                                                type={`${
                                                  e_data?.is_verified
                                                    ? "text"
                                                    : "text"
                                                }`}
                                                id="wallet_address"
                                                className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-start text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                disabled={!e_data?.is_verified}
                                                placeholder={`${
                                                  e_data?.is_verified
                                                    ? "Enter wallet address"
                                                    : "***********************"
                                                }`}
                                                value={
                                                  e_data?.wallet_address ?? ""
                                                }
                                                onChange={(e) => {
                                                  handleEwalletValueChange(
                                                    e,
                                                    e_data,
                                                    "e_wallet_address"
                                                  );
                                                }}
                                              />

                                              {!e_data?.is_verified && (
                                                <span className="mx-3 h-6">
                                                  <Button>
                                                    <IoEyeOutline
                                                      className="text-xl "
                                                      onClick={() => {
                                                        getProfile()
                                                          .then((data) => {
                                                            let is_auth_2fa_activate =
                                                              data[0]?.auth_2fa;

                                                            setIsOpenWalletAddress(
                                                              true
                                                            );

                                                            setWallertVerifyIput(
                                                              (prev: any) => {
                                                                return {
                                                                  ...prev,
                                                                  wallet_id:
                                                                    e_data?.wallet_id,
                                                                  is_auth_2fa_activate,
                                                                };
                                                              }
                                                            );
                                                          })
                                                          .catch((error) => {
                                                            toast.error(
                                                              "something went wrong! please try after some time."
                                                            );
                                                          })
                                                          .finally(() => {});
                                                      }}
                                                    />
                                                  </Button>
                                                </span>
                                              )}
                                              {/* </div> */}
                                            </p>
                                          </td>
                                          <td className="py-0">
                                            <div className="flex justify-center px-2">
                                              <div className="relative z-0  min-w-[100px]">
                                                <input
                                                  type="number"
                                                  id="percentage"
                                                  disabled={!e_data?.is_verified}
                                                  className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                  placeholder=" Fee "
                                                  value={e_data?.percentage ?? ""}
                                                  onChange={(e) => {
                                                    handleEwalletValueChange(
                                                      e,
                                                      e_data,
                                                      "e_wallet_percentage"
                                                    );
                                                  }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                  <span className="h-6">%</span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-2 py-4">
                                            <div className="flex items-start">
                                              <Button>
                                                <CiCircleMinus
                                                  className="mx-3 text-2xl font-bold hover:text-red-700"
                                                  onClick={() => {
                                                    handleDiscardExistingWallets(
                                                      e_data
                                                    );
                                                  }}
                                                />
                                              </Button>{" "}
                                              {wAA?.length == wIndex + 1 &&
                                                formValues?.ewallet_New_addressess
                                                  ?.length == 0 && (
                                                  <Button>
                                                    <CiCirclePlus
                                                      onClick={() => {
                                                        handleNewWallet(
                                                          "add_New_wallet",
                                                          ""
                                                        );
                                                      }}
                                                      className="text-2xl font-bold hover:text-green-500"
                                                    />
                                                  </Button>
                                                )}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}

                                {formValues?.ewallet_New_addressess?.length > 0
                                  ? // addNewWallet?.length > 0 ?
                                    formValues?.ewallet_New_addressess?.map(
                                      (
                                        wallet_details: any,
                                        NewWalletIndex: number,
                                        newWalletArr: any
                                      ) => {
                                        return (
                                          <>
                                            <tr className="bg-white dark:bg-gray-800">
                                              <td
                                                scope="row"
                                                className="whitespace-nowrap px-2 py-4 font-medium text-gray-900 dark:text-white"
                                              >
                                                <p className="flex items-center justify-start">
                                                  <input
                                                    type="text"
                                                    id="wallet_address"
                                                    // className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-start text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                    placeholder="Enter wallet address"
                                                    value={
                                                      wallet_details?.wallet_address ??
                                                      ""
                                                    }
                                                    onChange={(e) => {
                                                      handleNewWalletValueChange(
                                                        e,
                                                        "",
                                                        "e_wallet_address",
                                                        NewWalletIndex
                                                      );
                                                    }}
                                                  />

                                                  <span className="mx-5 h-6"></span>
                                                </p>
                                              </td>
                                              <td className="py-0">
                                                <div className="flex justify-center px-2">
                                                  <div className="relative z-0  min-w-[100px]">
                                                    <input
                                                      type="number"
                                                      id="percentage"
                                                      className="bg-transparent peer block w-full appearance-none border-0 border-b-2 border-gray-300 px-0 py-2.5 text-center text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
                                                      placeholder=" Fee "
                                                      value={
                                                        wallet_details?.percentage ??
                                                        ""
                                                      }
                                                      onChange={(e) => {
                                                        handleNewWalletValueChange(
                                                          e,
                                                          "",
                                                          "e_wallet_percentage",
                                                          NewWalletIndex
                                                        );
                                                      }}
                                                    />
                                                    <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center p-3 pb-5 pe-3.5">
                                                      <span className="h-6">
                                                        %
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="px-2 py-4">
                                                <div className="flex items-start">
                                                  <Button>
                                                    <CiCircleMinus
                                                      className="mx-3 text-2xl font-bold hover:text-red-700"
                                                      onClick={() => {
                                                        handleNewWallet(
                                                          "remove_New_wallet",
                                                          NewWalletIndex
                                                        );
                                                      }}
                                                    />
                                                  </Button>{" "}
                                                  {newWalletArr?.length ==
                                                    NewWalletIndex + 1 && (
                                                    <Button>
                                                      <CiCirclePlus
                                                        onClick={() => {
                                                          handleNewWallet(
                                                            "add_New_wallet",
                                                            ""
                                                          );
                                                        }}
                                                        className="text-2xl font-bold hover:text-green-500"
                                                      />
                                                    </Button>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tbody>
                            </table>
                          }
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                </Accordion>
              </ModalBody>
              <ModalFooter className="mt-[10px]">
                <div className="mt-5 flex gap-2 p-[10px]">
                  <button
                    onClick={handleClose}
                    className="linear rounded-xl bg-indigo-600 px-3 py-2 text-white font-medium text-navy-700 outline-none transition duration-200 hover:bg-indigo-500 active:bg-indigo-500 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="linear rounded-xl bg-indigo-600 px-3 py-2 text-white font-medium text-indigo-500 outline-none transition duration-200  dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {isLoading ? (
                      <DivLoader className="h-6 w-6 border-indigo-500" />
                    ) : (
                      "Save"
                    )}
                  </button>
                  {addedgateways.filter((data) => data?.name === type).length >
                    0 &&
                    addedgateways.filter((data) => data?.name === type)[0]
                      ?.inputs && (
                      <DeleteClientGatewayModal
                        data={
                          addedgateways.filter((data) => data?.name === type)[0]
                        }
                        fetchClients={fetchClients}
                      />
                    )}
                </div>
              </ModalFooter>
            </Card2>
          {/* </ModalBody> */}
        {/* </ModalContent> */}
      </Modal>

      <Modal isOpen={isOpenWalletAddress} onClose={onCloseWalletAddress}>
        <ModalOverlay className="z-20   bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto   !w-max min-w-[350px] !max-w-[85%] shadow md:top-[12vh] ">
          <ModalBody className="">
            <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[450px] flex flex-col !z-[1004] ">
              {!wallertVerifyIput?.is_auth_2fa_activate ? (
                <>
                  <h1 className=" text-2xl font-bold">{"2FA Verification"}</h1>
                  <p className="opacity-50">
                    To see the wallet address please verify 2FA code.
                  </p>
                  <br />

                  <p className="">
                    Your account does not meet the necessary requirements in
                    order to see wallet address, please visit{" "}
                    <Link to="/admin/profile">profile settings</Link> and
                    activate 2FA code.
                  </p>
                  <div className="mt-3 flex  justify-end gap-2">
                    <button
                      onClick={onCloseWalletAddress}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    >
                      Close
                    </button>{" "}
                  </div>
                </>
              ) : (
                <>
                  <h1 className=" text-2xl font-bold">{"2FA Verification"}</h1>
                  <p className="opacity-50">
                    To see the wallet address please verify 2FA code.
                  </p>

                  <div className="w-72 bg-white">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full"
                      value={wallertVerifyIput?.password}
                      label="Password *"
                      placeholder="Enter password"
                      id="password"
                      type={`${
                        wallertVerifyIput?.isShow ? "text" : "password"
                      }`}
                      onChange={(e) => {
                        const name = e.target.id;
                        const value = e.target?.value;
                        setWallertVerifyIput((prev: any) => {
                          return {
                            ...prev,
                            [name]: value,
                          };
                        });
                      }}
                      passwordShow={wallertVerifyIput?.isShow}
                      setPasswordShow={() =>
                        setWallertVerifyIput((prev: any) => {
                          return {
                            ...prev,
                            isShow: !prev?.isShow,
                          };
                        })
                      }
                    />
                  </div>
                  <div className="w-72">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full"
                      value={wallertVerifyIput?.passCode}
                      label="2FA Code *"
                      placeholder="Enter 2FA 6 digit code"
                      id="passCode"
                      type="number"
                      onChange={(e) => {
                        const name = e.target.id;
                        const value = e.target?.value?.trim();
                        if (value?.length <= 6) {
                          setWallertVerifyIput((prev: any) => {
                            return {
                              ...prev,
                              [name]: value,
                            };
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mt-3 flex  justify-end gap-2">
                    <button
                      onClick={onCloseWalletAddress}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    >
                      Close
                    </button>{" "}
                    <button
                      className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                      onClick={() => {
                        handleVerifyWallet();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default CreateClientGatewaysModal;
