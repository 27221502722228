import { getCustomers } from "api/graphQL_customers";
import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import CustomerTable from "./components/CustomerList";
import { ClientContext } from "ClientProvider";
import { useLocation } from "react-router-dom";

export interface ClientRowObj {
    customer_id: string;
    client_id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    email: string;
    phone: string;
    city: string;
    address: string;
    state: string;
    country: string;
    source_client: string;
    created_at: string;
    updated_at: string;
}

export type AllGatewayObj = {
  name: string;
  id: string;
};

const Organizations = () => {
  const [clients, setClients] = useState<ClientRowObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allGateways, setAllGateways] = useState<AllGatewayObj[]>([]);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const location = useLocation();

  const fetchClients = async () => {
    let finalSearchText = ""
    if(location.state) {
      if (location.state?.search) {
        finalSearchText = location.state.search
        window.history.replaceState({}, document.title);
      }
    } else {
        finalSearchText = searchTexts;
    }
    const params = { page: page, per_page: pageSize, searchTexts: finalSearchText };
    if (pageSize === null || pageSize === undefined) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    
    getCustomers(params)
    .then((data:any) => {
     
      setClients(data?.[0]);
      setTotalPage(data?.[1].total_pages);
      setTotalItems(data?.[1].total_items);
      setCurrentPage(data?.[1].current_page);
    
    })
      .catch((err) => {
       
        setClients([]);
        setIsLoading(false);
       
      })
      .finally(() => {
        setIsLoading(false);
      });

   
  };

  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";

    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };
 
  useEffect(() => {
    fetchClients();
  }, [page, pageSize, searchTexts]);

  return (
    <>
   
     
      <div className="mt-5">
        <CustomerTable
          tableData={clients}
          fetchClients={fetchClients}
          isLoading={isLoading}
          roleData={singleRoleData}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          allGateways={allGateways}
          setPageSize={setPageSize}
          onValueChange={serchboxValueChange}
        />
      </div>
    </>
  );
};

export default Organizations;
