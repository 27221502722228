import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import CreateRoleMethod from "./components/CreateRoleMethod";
import RoleMethodTable from "./components/RoleMethodTable";
// import { getAllUsersData } from "api/users";
import { getAllUsersData } from "api/graphQL_users";
// import { getRolePermission, getSingleRole } from "api/role";
import { getRolePermission } from "api/graphQL_role";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api//graphQL_role";
import { getId } from "utils/auth";
import { ClientContext } from "ClientProvider";
export type PaymentMethodRowObj = {
  id: string;
  name: string;
  logo: string;
  payments: boolean;
  authorization: boolean;
  payout: boolean;
  is_active: boolean;
  users:[]
};
const Role = () => {
  const [roleData, setRoleData] = useState<any>([]);
  const [userData, setUserData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const fetchRole = () => {
    setIsLoading(true);
    if (pageSize === null || pageSize === undefined) {
      setIsLoading(false);
      return;
    } 
    getRolePermission(page, pageSize , searchTexts)
      .then((data) => {
        setRoleData(data?.[0] ?? []);
        setTotalPage(data?.[1]?.total_pages);
        setTotalItems(data?.[1]?.total_items);
        setCurrentPage(data?.[1]?.current_page);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching role"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchSingleRole = () => {
    // setIsLoading(true);
        console.log("role role graphql",getId())
    getSingleRole(getId())
      .then((data) => {
        setSingleRoleData(data ?? []);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching role"
        // );
      })
      .finally(() => {});
  };
  useEffect(() => {
    fetchRole();
  }, [page, pageSize]);
  useEffect(() => {
    fetchSingleRole();
  }, []);

  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";

    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };

  useEffect(() => {
    fetchRole();
  }, [searchTexts]);
  return (
    <>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <CreateRoleMethod
          fetchRole={fetchRole}
          singleRoleData={singleRoleData}
        />
      </div>
      <div className="mt-5">
        <RoleMethodTable
          pageSize={pageSize}
          setPageSize={setPageSize}
          tableData={roleData}
          fetchRole={fetchRole}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          singleRoleData={singleRoleData}
          onValueChange={serchboxValueChange}
        />
      </div>
    </>
  );
};

export default Role;
