import Card from "components/card";
import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud, FiXCircle } from "react-icons/fi";
import { MdColorize } from "react-icons/md";
import toast from "react-hot-toast";
import { CirclePicker, SketchPicker } from "react-color";
import {getTextColor} from "utils/color"
import {getSettings, updateSettings} from "api/settings"


const ColorPicker = ({
    label,
    color,
    onChange,
}: {
    label: string;
    color: string;
    onChange: (color: string) => void;
}) => {
    const [showPicker, setShowPicker] = useState(false);
    
    const handleColorChange = (updatedColor: { hex: string }) => {
        onChange(updatedColor.hex);
        setShowPicker(false); 
    };

    return (
        <div className="flex flex-col relative">
            <p className="mb-3 text-sm font-medium text-gray-700 dark:text-gray-300">{label}</p>
            <div className="flex items-center space-x-3">
                {/* <CirclePicker
                    color={color}
                    onChangeComplete={handleColorChange}
                /> */}
                <div
                    className="w-[150px] h-9 flex items-center justify-center rounded-lg cursor-pointer border border-gray-400 shadow-md transition-all hover:scale-105"
                    style={{ backgroundColor: color , color: getTextColor(color) }}
                    onClick={() => setShowPicker((prev) => !prev)}
                >
                    Select Color
                </div>
                {showPicker && (
                    <div className="absolute z-10">
                        <div className="fixed inset-0" onClick={() => setShowPicker(false)}></div>
                        <SketchPicker color={color} onChange={handleColorChange} />
                    </div>
                )}
            </div>
        </div>
    );
};


const FileDropzone = ({ label, onFileSelect , initialPreview  }: { label: string; onFileSelect: (file: string | null) => void; initialPreview: any }) => {
    const [preview, setPreview] = useState<string | null>(initialPreview);

    useEffect(() => {
        setPreview(initialPreview);
    }, [initialPreview]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {
            const allowedTypes = ["image/png", "image/svg","image/svg+xml"];
            if (allowedTypes.includes(file.type)) {
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    const fileUrl = reader.result as string;
                    setPreview(fileUrl);
                    onFileSelect(fileUrl);
                };
            } else {
                toast.error("Only PNG and SVG files are allowed.");
                setPreview(null);
                onFileSelect(null); 
            }
        }
    }, [onFileSelect]);

    const removeImage = () => {
        setPreview(null);
        onFileSelect(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { "image/*": [] },
    });

    return (
        <div
            {...getRootProps()}
            className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-400/30 
            rounded-xl cursor-pointer bg-white/70 dark:bg-gray-800/70 backdrop-blur-lg hover:border-blue-500 
            hover:shadow-xl transition-all duration-300 w-full h-36 shadow-lg dark:shadow-gray-900/40 relative"
        >
            <input {...getInputProps()} />
            {preview ? (
                <div className="relative">
                    <img src={preview} alt="Preview" className="w-[150px] h-[150px] rounded-xl object-contain  border border-gray-200" />
                    <button
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering file input
                            removeImage();
                        }}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-all"
                    >
                        <FiXCircle className="text-sm" />
                    </button>
                </div>
            ) : (
                <div className="flex flex-col items-center">
                    <FiUploadCloud className="text-blue-500 text-4xl mb-3 transition-transform duration-300 hover:scale-110" />
                    <p className="text-gray-600 text-sm dark:text-gray-300 font-medium">{label}</p>
                </div>
            )}
        </div>
    );
};

const Settings = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loginIcon, setLoginIcon] = useState<string | null>(null);
    const [portalIcon, setPortalIcon] = useState<string | null>(null);
    const [favicon, setFavicon] = useState<string | null>(null);
    const [activeColor, setActiveColor] = useState<string>("");
    const [hoverColor, setHoverColor] = useState<string>("");
    const [fromColor, setFromColor] = useState<string>("");
    const [toColor, setToColor] = useState<string>("");

    const fetchSettings = () => {
        getSettings(true)
        .then((data) => {
            setLoginIcon(data.login_icon)
            setPortalIcon(data.portal_icon)
            setFavicon(data.favicon)
            setActiveColor(data.button_active_color ?? "");
            setHoverColor(data.button_hover_color ?? "");
            setFromColor(data.gradient_from_color ?? "");
            setToColor(data.gradient_to_color ?? "");
          })
        .catch((err) => {
          
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchSettings();
      }, []);

    const handleSubmit = async () => {
        if (!loginIcon || !portalIcon || !favicon || !activeColor || !hoverColor) {
            toast.error("Please updated all required fields.");
            return;
        }

        setIsLoading(true);

        try {
            const payload = {
                "login_icon": loginIcon,
                "portal_icon": portalIcon,
                "favicon": favicon,
                "button_active_color": activeColor,
                "button_hover_color": hoverColor,
                "gradient_from_color": fromColor,
                "gradient_to_color": toColor
            }

            updateSettings(payload)
            .then((data) => {
                const styles = `
                    :root {
                    --indigo-500: ${hoverColor} !important;
                    --indigo-600: ${activeColor} !important;
                    --indigo-700: ${hoverColor} !important;
                    --indigo-800: ${hoverColor} !important;

                    --brand-500: ${activeColor} !important;
                    --brand-600: ${hoverColor} !important;
                    --brand-700: ${hoverColor} !important;
                    }
                `;

                let styleTag = document.getElementById("dynamic-theme");
                styleTag = document.createElement("style");
                styleTag.id = "dynamic-theme";
                document.head.appendChild(styleTag);
                styleTag.innerHTML = styles;

                const link : any = document.querySelector("link[rel~='icon']") || document.createElement("link");
                link.rel = "icon";
                link.href = favicon;
                document.getElementsByTagName("head")[0].appendChild(link);

                const imgElement = document.querySelector("img#portalIcon") as HTMLImageElement;
                if (imgElement) {
                    imgElement.src = portalIcon;
                }

                toast.success(data.message);
                fetchSettings();
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
        } catch (error) {
            toast.error("An error occurred while uploading.");
        }

        setIsLoading(false);
    };

    return (
        <div className="mt-2 pt-5">
            <Card extra="w-full h-full sm:overflow-auto px-6 py-8 mb-6 bg-white shadow-xl rounded-2xl">
                <div className="bg-gradient-to-r from-blue-200 to-blue-100 dark:from-blue-900 dark:to-blue-700 text-blue-800 dark:text-white p-4 rounded-xl mb-2 shadow-md">
                    <p className="text-lg font-semibold tracking-wide">Logos & Colors</p>
                </div>
                <div className="relative">                   
                    <div className="p-8 bg-white">
                        <div className="grid grid-cols-3 gap-8">
                            <div className="flex flex-col ">
                                <p className="mb-3 text-sm font-medium text-gray-700 dark:text-gray-300">Login Icon *</p>
                                <FileDropzone label="Upload Login Icon" onFileSelect={setLoginIcon} initialPreview={loginIcon}/>
                            </div>
                            <div className="flex flex-col ">
                                <p className="mb-3 text-sm font-medium text-gray-700 dark:text-gray-300">Portal Icon *</p>
                                <FileDropzone label="Upload Portal Icon" onFileSelect={setPortalIcon} initialPreview={portalIcon}/>
                            </div>
                            <div className="flex flex-col ">
                                <p className="mb-3 text-sm font-medium text-gray-700 dark:text-gray-300">Favicon *</p>
                                <FileDropzone label="Upload Favicon" onFileSelect={setFavicon} initialPreview={favicon}/>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="grid p-8 grid-cols-2 gap-6  ">
                    <div className="relative p-6 border-2 border-dashed border-gray-400/30 bg-white shadow-xl rounded-md">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4">Button Colors</h3>
                        <div className="grid grid-cols-2 gap-6">
                            <div className="flex flex-col">
                                <ColorPicker label="Active Color *" color={activeColor} onChange={setActiveColor} />
                            </div>
                            <div className="flex flex-col">
                                <ColorPicker label="Hover Color *" color={hoverColor} onChange={setHoverColor} />
                            </div>
                        </div>
                        {activeColor && hoverColor && (
                            <div className="mt-6">
                                <button
                                    className="w-full py-1 rounded-lg shadow-md text-lg font-semibold transition-all"
                                    style={{ 
                                        backgroundColor: activeColor, 
                                        color: getTextColor(activeColor)  
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = hoverColor;
                                        e.currentTarget.style.color = getTextColor(hoverColor);
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = activeColor;
                                        e.currentTarget.style.color = getTextColor(activeColor);
                                    }}
                                >
                                    Preview Button
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="relative p-6 border-2 border-dashed border-gray-400/30 bg-white shadow-xl rounded-md">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4">Gradient Colors</h3>
                        <div className="grid grid-cols-2 gap-6">
                            <div className="flex flex-col">
                                <ColorPicker label="From Color *" color={fromColor} onChange={setFromColor} />
                            </div>
                            <div className="flex flex-col">
                                <ColorPicker label="To Color *" color={toColor} onChange={setToColor} />
                            </div>
                        </div>
                        {fromColor && toColor && (
                            <div className="mt-6">
                                <div
                                    className="w-full py-4 rounded-lg shadow-md text-lg font-semibold text-center transition-all"
                                    style={{
                                        background: `linear-gradient(130deg, ${fromColor} 0%, ${toColor} 98%)`,
                                    }}
                                >
                                </div>
                            </div>
                        )}
                    </div>
                </div> 
                <div className="relative ">                   
                    <div className="flex justify-end">
                        <button
                            onClick={handleSubmit}
                            className="mt-6 px-6 py-3  bg-indigo-600 text-white rounded-xl shadow-md hover:bg-indigo-500 transition-all"
                            disabled={isLoading}
                        >
                            {isLoading ? "Updating..." : "Submit"}
                        </button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default Settings;
